import moment from "moment";
import {Status} from "../../const/status";
import React, {useEffect, useState} from "react";
import List from "@material-ui/core/List";
import {ContractorOption, FilterOptions, StartEndDatePicker} from "../Filters";
import {useAuth} from "../../api/auth";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ListItemIcon} from "@material-ui/core";
import BookmarkIcon from '@material-ui/icons/Bookmark';

const initialContractors = [];
const now = new moment();
const nowDates = {start: now, end: now}
const disabledDates = {start: undefined, end: undefined}

export default function MapFilters({onChange, userColors}) {
    const {user} = useAuth();

    const initialStatuses = Object.keys(Status).map(k => Status[k])
    const initialCompanies = ['ATT', 'TW', 'WOW', 'CLW'];

    const serviceCodeOptions = [
        {value: "U8", display: "U8 - Bore"},
        {value: "Z5", display: "Z5 - Pre-bury drop"},
        {value: "URGENT", display: "Urgent - WOW and CLW only"},
    ]

    const customerTypeOptions = [
        {value: false, display: "Residential"},
        {value: true, display: "Commercial"},
    ]

    const wireTypeOptions = [
        {value: "fiber", display: "Fiber"},
        {value: "copper", display: "Copper"},
    ]

    const dropBuryLengthOptions = [
        {value: "0-150 FT", display: "9A 0-150 FT"},
        {value: "151-250 FT", display: "9B 151-250 FT"},
        {value: "251-400 FT", display: "9C 251-400 FT"},
    ]

    const primaryReasonOptions = [
        {value: "BORE", display: "BORE"},
        {value: "DROP BURY REQD", display: "DROP BURY REQD"},
        {value: "PRIORITY BORE", display: "PRIORITY BORE"},
    ]

    const [statuses, setStatuses] = useState(initialStatuses);
    const [companies, setCompanies] = useState(user.company ? [user.company] : initialCompanies);
    const [entered, setEntered] = useState(disabledDates);
    const [due, setDue] = useState(disabledDates);
    const [created, setCreated] = useState(nowDates);
    const [completed, setCompleted] = useState(disabledDates);
    const [completedContractors, setCompletedContractors] = useState(initialContractors);
    const [assignedContractors, setAssignedContractors] = useState(initialContractors);
    const [serviceCodes, setServiceCodes] = useState([])
    const [customerType, setCustomerType] = useState(customerTypeOptions.map(o => o.value))
    const [wireTypes, setWireTypes] = useState([])
    const [dropBuryLength, setDropBuryLength] = useState([])
    const [primaryReason, setPrimaryReason] = useState([])

    useEffect(() => {
        onChange([
            {name: "company", type: "option", values: companies},
            {name: "status.transition.to_state", type: "option", values: statuses},
            {name: "entered", type: "date", ...entered},
            {name: "due", type: "date", ...due},
            {name: "created_at", type: "date", ...created},
            {name: "completed_at", type: "date", ...completed},
            {name: "completed_user", type: "option", values: completedContractors},
            {name: "assigned_user", type: "option", values: assignedContractors},
            {name: "service_codes", type: "contains", values: serviceCodes},
            {name: "is_commercial", type: "option", values: customerType},
            {name: "wire_type", type: "option", values: wireTypes},
            {name: "drop_bury_length", type: "option", values: dropBuryLength},
            {name: "primary_reason", type: "option", values: primaryReason},
        ]);
    }, [onChange, statuses, companies, created, due, entered, completed, completedContractors, assignedContractors, serviceCodes, customerType, wireTypes, dropBuryLength, primaryReason]);

    // TODO: We can dynamically populate a list of available values from the server.
    // TODO: This can include min and max dates, status and company options, etc.
    return (
        <List>
            <ListItem button dense onClick={() => {
                setStatuses(["Created", "Assigned", "On Hold", "Hold released", "Skipped"])
                setEntered(disabledDates)
                setDue(disabledDates)
                setCreated(disabledDates)
                setCompleted(disabledDates)
                setAssignedContractors(initialContractors)
                setCompletedContractors(initialContractors)
                setCompanies(companies => companies.filter(c => c !== 'ATT'))
            }}>
                <ListItemIcon>
                    <BookmarkIcon />
                </ListItemIcon>
                <ListItemText secondary="Only open statuses, no date filters">
                    All open work
                </ListItemText>
            </ListItem>
            <ListItem button dense onClick={() => {
                setStatuses(["Complete"])
                setEntered(disabledDates)
                setDue(disabledDates)
                setCreated(disabledDates)
                setCompleted({start: moment(now).startOf('isoweek'), end: moment(now).endOf('isoweek')})
                setAssignedContractors(initialContractors)
                setCompletedContractors(initialContractors)
                setCompanies(companies => companies.filter(c => c !== 'ATT'))
            }}>
                <ListItemIcon>
                    <BookmarkIcon />
                </ListItemIcon>
                <ListItemText secondary="Only completed status, completed date this week">
                    Completed this week
                </ListItemText>
            </ListItem>
            <FilterOptions
                title="Current status"
                options={initialStatuses}
                selected={statuses}
                setSelected={setStatuses}
            />
            <FilterOptions
                title="Company"
                options={initialCompanies}
                selected={companies}
                setSelected={setCompanies}
            />
            <FilterOptions
                title="Service codes"
                options={serviceCodeOptions}
                selected={serviceCodes}
                setSelected={setServiceCodes}
            />
            <FilterOptions
                title="Customer type"
                options={customerTypeOptions}
                selected={customerType}
                setSelected={setCustomerType}
            />
            <FilterOptions
                title="Wire type"
                options={wireTypeOptions}
                selected={wireTypes}
                setSelected={setWireTypes}
            />
            <FilterOptions
                title="Drop bury length"
                options={dropBuryLengthOptions}
                selected={dropBuryLength}
                setSelected={setDropBuryLength}
            />
            <FilterOptions
                title="Primary reason"
                options={primaryReasonOptions}
                selected={primaryReason}
                setSelected={setPrimaryReason}
            />
            <StartEndDatePicker
                title="Created date"
                dates={created}
                setDates={setCreated}
                defaultEnabled
            />
            <StartEndDatePicker
                title="Due date"
                dates={due}
                setDates={setDue}
            />
            <StartEndDatePicker
                title="Entered date"
                dates={entered}
                setDates={setEntered}
            />
            <StartEndDatePicker
                title="Completed date"
                dates={completed}
                setDates={setCompleted}
            />
            <ContractorOption
                title="Completed by"
                selected={completedContractors}
                setSelected={setCompletedContractors}
                userColors={userColors}
            />
            <ContractorOption
                title="Assigned user"
                selected={assignedContractors}
                setSelected={setAssignedContractors}
                userColors={userColors}
            />
        </List>
    )
}

export function translateMapFilters(filters) {
    const and = filters.map(filter => {
        switch (filter.type) {
            case "option":
                // TODO: We may want to handle this case better - when there are no options selected
                if (filter.values.length === 0) return null
                return {
                    "$or": filter.values.map(value => ({
                        [filter.name]: value
                    }))
                };
            case "contains":
                // TODO: We may want to handle this case better - when there are no options selected
                if (filter.values.length === 0) return null
                return {
                    "$or": filter.values.map(value => ({
                        [filter.name]: {"$like": `%${value}%`}
                    }))
                };
            case "date":
                if (!filter.start && !filter.end) {
                    return null;
                }
                const dates = {};
                if (filter.start) {
                    dates["$gte"] = filter.start.startOf('day').format();
                }
                if (filter.end) {
                    dates["$lte"] = filter.end.endOf('day').format();
                }
                return {[filter.name]: dates};
            default:
                throw new Error(`Filter type ${filter.type} not supported`)
        }
    }).filter(it => it)

    return {"$and": and};
}
