import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Login from "./Login";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

export default function SignIn() {
    return (
        <Container maxWidth="sm">
            <Box mt={8}>
                <Paper elevation={4}>
                    <Grid container spacing={8} alignItems="center" justify="center" direction="column">
                        <Grid item xs={12}>
                            <Typography variant="h5">Please login before accessing this page</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Login/>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>

    )
}