import React from "react";
import {CardMedia, Container} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    video: {
        [theme.breakpoints.up('xl')]: {
            height: 380
        },
        [theme.breakpoints.down('lg')]: {
            height: 280
        },
        [theme.breakpoints.down('md')]: {
            height: 280
        },
        [theme.breakpoints.down('xs')]: {
            height: 200
        }
    },
}));

export default function Training() {
    const classes = useStyles()
    return (
        <Container maxWidth={false} style={{paddingTop: 16}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card raised={true}>
                        <CardMedia
                            className={classes.video}
                            component="iframe"
                            title="Invisible dog fence"
                            src="https://www.youtube.com/embed/HKAzpspbvfQ?modestbranding=1&rel=0"
                            frameBorder="0"
                            allowFullScreen
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                        <CardHeader title="Invisible dog fence"/>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
