import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Link as RouterLink} from 'react-router-dom';
import List from '@material-ui/core/List';
import {Assignment, Map, MonetizationOn, People, TableChart, List as ListIcon} from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import MailIcon from '@material-ui/icons/Mail';
import WarningIcon from '@material-ui/icons/Warning';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/AddBox';
import MovieIcon from '@material-ui/icons/Movie';
import SettingsIcon from '@material-ui/icons/Settings';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import TableChartIcon from '@material-ui/icons/TableChart';
import UserACL, {Role} from "./UserACL";

const useStyles = makeStyles(theme => ({
    list: {
        width: 240,
    },
}));

function ListItemLink(props) {
    const {icon, primary, to} = props;

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <li>
            <ListItem button component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary}/>
            </ListItem>
        </li>
    );
}

export default function Drawer({toggleDrawer}) {
    const classes = useStyles();

    return (
        <div className={classes.list}
             role="presentation"
             onClick={toggleDrawer}
             onKeyDown={toggleDrawer}
        >
            <List>
                <ListItemLink to="/" primary="Home" icon={<HomeIcon/>}/>
                <ListItemLink to="/training" primary="Training" icon={<MovieIcon/>}/>
            </List>
            <Divider/>
            <UserACL role={Role.QualityControl}>
                <List>
                    <ListItemLink to="/table" primary="Work Table" icon={<TableChart/>}/>
                    <ListItemLink to="/map" primary="Work Map" icon={<Map/>}/>
                    {/*<ListItemLink to="/list" primary="Work List" icon={<ListIcon/>}/>*/}
                    <UserACL role={Role.Manager}>
                        <ListItemLink to="/att_zoho" primary="ATT Zoho" icon={<TableChartIcon/>}/>
                        <ListItemLink to="/construction" primary="Pending Construction" icon={<MailIcon/>}/>
                        <ListItemLink to="/toFix" primary="Work To Fix" icon={<WarningIcon/>}/>
                        <ListItemLink to="/create" primary="Create Work Order" icon={<AddIcon/>}/>
                    </UserACL>
                </List>
                <Divider/>
                <List>
                    {/*<ListItemLink to="/payroll" primary="Payroll" icon={<MonetizationOn/>}/>*/}
                    <ListItemLink to="/reports" primary="Reports" icon={<Assignment/>}/>
                    <UserACL role={Role.Manager}>
                        <ListItemLink to="/users" primary="Users" icon={<People/>}/>
                    </UserACL>
                    <UserACL role={Role.Admin}>
                        <ListItemLink to="/routing_settings" primary="Routing Settings" icon={<SettingsIcon/>}/>
                        <ListItemLink to="/features" primary="Features" icon={<ToggleOnIcon/>}/>
                        <ListItemLink to="/email_recipients" primary="Email Recipients" icon={<MailIcon/>}/>
                    </UserACL>
                </List>
            </UserACL>
        </div>
    )
}