import {Dialog, TextField, useMediaQuery} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "@fortawesome/fontawesome-free/css/all.css";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import AddAttachments from "./AddAttachments";
import ErrorSnackbar from "./ErrorSnackbar";
import {useQCWork} from "../api/work";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {AttachFile} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
    errorList: {
        paddingInlineStart: 0,
    },
    errorListNested: {
        paddingInlineStart: 24,
    },
}));

const initialQC = {
    pass: false,
    measured_depth: 0,
    resolution: "",
}

export default function QualityDialog({onClose, work: {id}}) {
    const classes = useStyles();
    const [userComment, setUserComment] = React.useState('');
    const [attached, setAttached] = React.useState([]);
    const [{work: updatedWork, loading, error}, submitReq] = useQCWork();

    const [qc, setQC] = React.useState(initialQC)

    function onChange(newFields) {
        setQC(qc => ({...qc, ...newFields}));
    }

    // Called when the complete button is clicked
    async function onSubmit() {
        // console.log(toBill, userComment, attached)
        submitReq({
            workId: id,
            comment: {
                message: userComment,
                attachments: await Promise.all(attached.map(async a => ({
                    filename: a.name,
                    data: await a.toBase64(),
                }))),
            },
            pass: qc.pass,
            measured_depth: qc.measured_depth,
            resolution: qc.resolution,
        });
    }

    // Once the API responds, call onClose with the newly returned work
    React.useEffect(() => {
        updatedWork && onClose(updatedWork)
    }, [updatedWork, onClose]);

    return (
        <Dialog
            fullScreen={useMediaQuery(useTheme().breakpoints.down('sm'))}
            open={true}
            onClose={() => onClose()}
            fullWidth={true}
            maxWidth="sm"
            disableBackdropClick
        >
            {error && <ErrorSnackbar message={error}/>}
            <DialogTitle>Quality Control</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add a description and photos/videos of the work then enter QC information.
                </DialogContentText>
                <List>
                    <div style={{position: 'relative'}}>
                        {loading && <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>}
                        <ListItem disableGutters key="add_comment">
                            <TextField
                                variant="outlined"
                                multiline
                                fullWidth
                                value={userComment}
                                onChange={ev => setUserComment(ev.target.value)}
                                label="Add your comment"
                                style={{paddingRight: 8}}
                            />
                            <ListItemSecondaryAction>
                                <label htmlFor="complete-file-upload">
                                    <IconButton edge="end" component="span">
                                        <AttachFile/>
                                    </IconButton>
                                </label>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <AddAttachments
                            id="complete-file-upload"
                            accept="image/*" //;capture=camera
                            attached={attached}
                            setAttached={setAttached}
                        />

                        {attached.length > 0 && (
                            <Divider/>
                        )}

                        <FormControlLabel
                            style={{marginTop: 16}}
                            control={
                                <Switch
                                    checked={qc.pass}
                                    onChange={() => onChange({pass: !qc.pass})}
                                    color="primary"
                                />
                            }
                            label="QC Pass"
                        />

                        <TextField
                            type="number"
                            label="Measured depth (inches)"
                            fullWidth
                            margin="normal"
                            value={qc.measured_depth}
                            onChange={ev => onChange({measured_depth: parseInt(ev.target.value) || 0})}
                            required
                        />

                        <TextField
                            label="Resolution"
                            fullWidth
                            margin="normal"
                            multiple
                            value={qc.resolution}
                            onChange={ev => onChange({resolution: ev.target.value})}
                        />

                    </div>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={onSubmit}
                    // disabled={!userComment || attached.length === 0 || toBill.length === 0 || userErrors.length > 0}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
