import MapItems from "./MapItems";
import {useEffect, useState} from "react";

export default function MapWorkAreas(google, works, hideIneligible) {
    const [showWorkArea, setShowWorkArea] = useState(false)

    useEffect(() => {
        if (!google) return

        const listener = google.maps.event.addListener(google.map, 'zoom_changed', () => {
            setShowWorkArea(google.map.getZoom() >= 13)
        })

        return () => {
            listener.remove()
        }
    }, [google])

    function onCreate({oups}) {
        // Destructure work_area from the first OUPS element defaulting to empty
        const [{work_area} = {}] = oups || []

        return new google.maps.Polygon({
            map: google.map,
            paths: google.maps.geometry.encoding.decodePath(work_area),
            strokeColor: '#0000FF',
            strokeOpacity: 0.75,
            strokeWeight: 1,
            fillColor: '#0000FF',
            fillOpacity: 0.20
        })
    }

    function onRemove(work, workArea) {
        workArea.setMap(null)
    }

    function canShow(work) {
        if (hideIneligible && work.skip_reason) {
            return false
        }
        const [{work_area} = {}] = work.oups || []
        if (!work_area) {
            return false
        }
        return showWorkArea
    }

    const [mapItems, computeMapItems] = MapItems(google, works, onCreate, onRemove, canShow)

    // Recompute the map items when showWorkArea changes
    useEffect(computeMapItems, [showWorkArea, hideIneligible])

    return mapItems
}

MapWorkAreas.whyDidYouRender = true
