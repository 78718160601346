import React from "react";
import {useCancelWork, useHoldWork, useReleaseWork, useSingleWork} from "../api/work";
import Button from "@material-ui/core/Button";
import CommentAttachmentDialog from "./CommentAttachmentDialog";
import AssignDialog from "./AssignDialog";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import OUPSLookupDialog from "./oups/OUPSLookupDialog";
import OUPSSubmitDialog from "./oups/OUPSSubmitDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ErrorSnackbar from "./ErrorSnackbar";
import UrgentDialog from "./UrgentDialog";

const useStyles = makeStyles(theme => ({
    loadingWrapper: {
        position: 'relative',
        // minHeight: 80
    },
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

const defaultButtonProps = {};

export default function WorkMenu({id, onWorkChanged, buttonProps = defaultButtonProps}) {
    const classes = useStyles();

    const [{work, loading, error}, setWorkId] = useSingleWork();

    const [menuAnchor, setMenuAnchor] = React.useState();

    const [lookupOpen, setLookupOpen] = React.useState(false);
    const [oupsOpen, setOupsOpen] = React.useState(false);
    const [assignOpen, setAssignOpen] = React.useState(false);
    const [cancelOpen, setCancelOpen] = React.useState(false);
    const [holdOpen, setHoldOpen] = React.useState(false);
    const [releaseOpen, setReleaseOpen] = React.useState(false);
    const [urgentOpen, setUrgentOpen] = React.useState(false);

    function openDialog(setOpenFunc) {
        setMenuAnchor(undefined)
        setOpenFunc(true)
    }

    function closeDialog(updatedWork, setOpenFunc) {
        setOpenFunc(false)
        updatedWork && onWorkChanged && onWorkChanged(updatedWork)
    }

    const onMenuClose = () => setMenuAnchor(undefined);
    const onMenuClick = ev => {
        setWorkId(id);
        setMenuAnchor(ev.currentTarget);
    }

    const assign = () => openDialog(setAssignOpen);
    const cancel = () => openDialog(setCancelOpen);
    const hold = () => openDialog(setHoldOpen);
    const release = () => openDialog(setReleaseOpen);
    const addressLookup = () => openDialog(setLookupOpen);
    const addToOUPS = () => openDialog(setOupsOpen);
    const urgent = () => openDialog(setUrgentOpen);

    const onAssignClose = newWork => closeDialog(newWork, setAssignOpen);
    const onCancelClose = newWork => closeDialog(newWork, setCancelOpen);
    const onHoldClose = newWork => closeDialog(newWork, setHoldOpen);
    const onReleaseClose = newWork => closeDialog(newWork, setReleaseOpen);
    const onSubmitClosed = newWork => closeDialog(newWork, setOupsOpen);
    const onUrgentClosed = newWork => closeDialog(newWork, setUrgentOpen);

    const onLookupClosed = (updatedWork, submitOUPS) => {
        // TODO: Probably don't want to call `onWorkChange` here
        closeDialog(updatedWork, setLookupOpen)
        submitOUPS && setOupsOpen(true)
    }

    return (
        <>
            {error && <ErrorSnackbar message={error}/>}
            {cancelOpen && <CommentAttachmentDialog
                description="Add a comment and optional photos/videos of why the work was canceled"
                apiFunc={useCancelWork}
                workId={id}
                submitText="Cancel"
                title="Cancel work"
                onClose={onCancelClose}
            />}
            {holdOpen && <CommentAttachmentDialog
                description="Add a comment and optional photos/videos of why the work was put on hold"
                apiFunc={useHoldWork}
                workId={id}
                submitText="Hold"
                title="Hold work"
                onClose={onHoldClose}
            />}
            {releaseOpen && <CommentAttachmentDialog
                description="Add a comment and optional photos/videos of why the work was released from hold"
                apiFunc={useReleaseWork}
                workId={id}
                submitText="Release"
                title="Release held work"
                onClose={onReleaseClose}
            />}
            {assignOpen && <AssignDialog
                work={work}
                onClose={onAssignClose}
            />}
            {lookupOpen && <OUPSLookupDialog
                allowOUPSSubmit
                work={work}
                onClose={onLookupClosed}
            />}
            {oupsOpen && <OUPSSubmitDialog
                work={work}
                onClose={onSubmitClosed}
            />}
            {urgentOpen && <UrgentDialog
                work={work}
                onClose={onUrgentClosed}
            />}
            <Button variant="outlined" color="primary" onClick={onMenuClick} {...buttonProps}>
                Menu
            </Button>
            <Menu
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={onMenuClose}
            >
                <div className={classes.loadingWrapper}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>
                    )}
                    <MenuItem onClick={addToOUPS}>
                        <ListItemText primary="Add to OUPS"/>
                    </MenuItem>
                    <MenuItem onClick={addressLookup}>
                        <ListItemText primary="OUPS address lookup"/>
                    </MenuItem>
                    <MenuItem onClick={assign}>
                        <ListItemText primary="Assign"/>
                    </MenuItem>
                    {work?.status?.transition?.to_state === "On hold" ?
                        <MenuItem onClick={release}>
                            <ListItemText primary="Release hold"/>
                        </MenuItem>
                        :
                        <MenuItem onClick={hold}>
                            <ListItemText primary="Hold"/>
                        </MenuItem>
                    }
                    <MenuItem onClick={cancel}>
                        <ListItemText primary="Cancel"/>
                    </MenuItem>
                    <MenuItem onClick={urgent}>
                        <ListItemText primary="Set urgent"/>
                    </MenuItem>
                </div>
            </Menu>
        </>
    )
}