import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React from "react";

export default function AddressComponents({components, setComponents, lookup}) {
    return (
        <>
            <TextField
                autoFocus
                value={components.number}
                onChange={event => {
                    const number = event.target.value;
                    setComponents(components => ({...components, number}))
                }}
                margin="normal"
                label="Number"
                style={{marginRight: 16, width: 80}}
            />
            <TextField
                value={components.street}
                onChange={event => {
                    const street = event.target.value;
                    setComponents(components => ({...components, street}))
                }}
                margin="normal"
                label="Street"
                style={{marginRight: 16, width: 144}}
            />
            <TextField
                value={components.city}
                onChange={event => {
                    const city = event.target.value;
                    setComponents(components => ({...components, city}))
                }}
                margin="normal"
                label="City/Township"
                style={{marginRight: 16}}
            />
            <TextField
                value={components.lot}
                onChange={event => {
                    const lot = event.target.value;
                    setComponents(components => ({...components, lot}))
                }}
                margin="normal"
                label="Apt/Lot/Unit"
                style={{marginRight: 16, width: 144}}
            />
            <Button
                onClick={lookup}
                color="primary"
                style={{marginTop: 24}}>
                Lookup
            </Button>
        </>
    )
}