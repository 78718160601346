import {useEffect, useState} from "react";
import useLoading from "./useLoading";

export const useEmailRecipients = () => {
    const [{data: emailRecipients, loading, error}, setEmailRecipientsFunc] = useLoading({}, true);

    useEffect(() => {
        setEmailRecipientsFunc(() => axios => {
            return axios
                .get(`/email_recipients/`)
                .then(res => res.data)
        });
    }, [setEmailRecipientsFunc]);

    return {emailRecipients, loading, error}
};

export const useAddRecipient = () => {
    const [{data: updated, loading, error}, submitFunc] = useLoading(undefined, false);
    const [recipient, setRecipient] = useState(null);

    useEffect(() => {
        if (!recipient) {
            return
        }

        submitFunc(() => axios => {
            return axios
                .post(`/email_recipients/add`, recipient)
                .then(res => res.data)
        });

    }, [recipient, submitFunc]);

    return [{updated, loading, error}, setRecipient]
}

export const useRemoveRecipient = () => {
    const [{data: updated, loading, error}, submitFunc] = useLoading(undefined, false);
    const [recipient, setRecipient] = useState(null);

    useEffect(() => {
        if (!recipient) {
            return
        }

        submitFunc(() => axios => {
            return axios
                .post(`/email_recipients/remove`, recipient)
                .then(res => res.data)
        });

    }, [recipient, submitFunc]);

    return [{updated, loading, error}, setRecipient]
}
