import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {useActivateFeature, useDeactivateFeature, useFeatures} from "../api/features";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorSnackbar from "../components/ErrorSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

export default function Features() {
    const classes = useStyles();

    const {features, loading, error} = useFeatures()

    const [rawFeatures, setRawFeatures] = useState({})

    const [combinedFeatures, setCombinedFeatures] = useState([])

    useEffect(() => {
        if (!rawFeatures?.available) return

        setCombinedFeatures(
            rawFeatures.available.map(feature => {
                let displayName = feature.name.replaceAll('_', ' ')
                displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1)

                const activeCompanies = rawFeatures.active
                    .filter(active => active.name === feature.name)
                    .map(active => active.company)

                return {...feature, displayName, active: activeCompanies}
            })
        )
    }, [rawFeatures, setCombinedFeatures])

    const [{updated: activated, loading: activateLoading, error: activateError}, activate] = useActivateFeature()
    const [{updated: deactivated, loading: deactivateLoading, error: deactivateError}, deactivate] = useDeactivateFeature()

    useEffect(() => {
        features?.available && setRawFeatures(features)
    }, [features, setRawFeatures])

    useEffect(() => {
        activated?.available && setRawFeatures(activated)
    }, [activated, setRawFeatures])

    useEffect(() => {
        deactivated?.available && setRawFeatures(deactivated)
    }, [deactivated, setRawFeatures])

    function handleToggle(feature, company) {
        if (feature.active.indexOf(company) !== -1) {
            deactivate({name: feature.name, company})
        } else {
            activate({name: feature.name, company})
        }
    }

    if (loading) {
        return <h2>Loading...</h2>
    }

    if (error) {
        const {response: {data: {message} = {}} = {}} = error;
        return <h1>An error has occurred: {message || String(error)}</h1>
    }

    return (
        <Container maxWidth="sm" style={{paddingTop: 16}}>
            {(activateLoading || deactivateLoading) && (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={64}/>
                </div>
            )}
            {activateError && <ErrorSnackbar message={activateError}/>}
            {deactivateError && <ErrorSnackbar message={deactivateError}/>}
            <Typography variant="h3">Features</Typography>
            {combinedFeatures.map(feature => (
                <FeatureCard
                    key={feature.name}
                    feature={feature}
                    onCompanyToggle={company => handleToggle(feature, company)}
                />
            ))}
        </Container>
    )
}

function FeatureCard({feature, onCompanyToggle}) {
    const {displayName, description, companies, active} = feature

    return (
        <Card raised={true} style={{marginTop: 16}}>
            <CardHeader title={displayName} subheader={description}/>
            <CardContent style={{paddingTop: 0}}>
                <List>
                    {companies.map(company => (
                        <FeatureCompany
                            key={company}
                            company={company}
                            active={active.indexOf(company) !== -1}
                            onClick={() => onCompanyToggle(company)}
                        />
                    ))}
                </List>
            </CardContent>
        </Card>
    )
}

function FeatureCompany({company, active, onClick}) {
    return <ListItem dense button onClick={onClick}>
        <ListItemIcon>
            <Checkbox
                edge="start"
                tabIndex={-1}
                checked={active}
                disableRipple
            />
        </ListItemIcon>
        <ListItemText primary={company}/>
    </ListItem>
}
