import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import React from "react";
import BaseStep from "./Base";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Icon from "@material-ui/core/Icon";
import getFontAwesomeIconFromMIME from "../../util/fontAwesomeIcon";
import ListItemText from "@material-ui/core/ListItemText";
import humanFileSize from "../../util/humanFileSize";

const useStyles = makeStyles(theme => ({
    completedUser: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default class Complete extends BaseStep {
    constructor() {
        super("Work completed");
    }

    Completed({complete_comment}) {
        return !!complete_comment;
    }

    Contents({work: {complete_comment}, onCompleteClick}) {
        const classes = useStyles({});

        const labelProps = {};

        if (complete_comment) {
            const {created_at, user: {first_name, last_name, avatar}} = complete_comment;

            labelProps.optional = <Typography variant="caption">
                {moment(created_at).format('lll')} by {first_name} {last_name}
            </Typography>;

            labelProps.StepIconComponent = () => <Avatar
                alt={`${first_name} ${last_name}`}
                src={avatar}
                className={classes.completedUser}/>;
        }

        let content = <Button variant="contained" color="primary" onClick={onCompleteClick}>Complete work</Button>;

        if (complete_comment) {
            const {message, attachments} = complete_comment;

            content = <>
                <Typography>{message}</Typography>
                {attachments.map(({id, data_url, content_type, size, filename}) => (
                    <ListItem
                        key={`attachment_${id}`}
                        dense
                        button
                        className={classes.nested}
                        component="a"
                        href={data_url}
                        target="_blank"
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={content_type.indexOf("image/") === 0 ? data_url : null}
                                variant="square"
                                className={classes.fileIcon}
                            >
                                <Icon
                                    className={`${classes.icon} fa ${getFontAwesomeIconFromMIME(content_type)}`}/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={filename}
                            secondary={humanFileSize(size, true)}
                        />
                    </ListItem>
                ))}
            </>
        }

        return [
            <StepLabel key="label" {...labelProps}>{this.Name}</StepLabel>,
            <StepContent key="content">
                {content}
            </StepContent>,
        ]
    }
}