import React from 'react';
import './App.css';
import Typography from '@material-ui/core/Typography';
import {Toolbar} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {BrowserRouter as Router, Route} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from "./components/Drawer";
import WorkTable from "./views/WorkTable";
import Map from "./views/Map";
import Home from "./views/Home";
import Reports from "./views/Reports";
import Users from "./views/Users";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
// import {useAddToHomescreenPrompt} from "./components/AddToHome";
import Avatar from "@material-ui/core/Avatar";
import Login from "./components/Login";
import Logout from "./components/Logout";
import SignIn from "./components/SignIn";
import {AuthenticationProvider, useAuth} from "./api/auth";
import ErrorSnackbar from "./components/ErrorSnackbar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import {ProgressProvider, useProgress} from "./components/progress";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Construction from "./views/Construction";
import ConstructionList from "./views/ConstructionList";
import ToFix from "./views/ToFix";
import UserLoading from "./components/UserLoading";
import CreateWork from "./views/CreateWork";
import Training from "./views/Training";
import RoutingSettings from "./views/RoutingSettings";
import ATTZoho from "./views/ATTZoho";
import Features from "./views/Features";
import EmailRecipients from "./views/EmailRecipients";
import WorkDetails from "./views/WorkDetails";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    // box: {
    //     display: 'inline-block',
    //     width: "100%",
    // },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        // flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    padding: {
        paddingLeft: 12,
    },
    rightCenter: {
        // Vertically center items in the toolbar
        display: "flex",
        alignItems: "center",
    },
    toolbarAvatar: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    toolbarUser: {
        paddingLeft: 12,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    toolbarLogout: {
        paddingLeft: 12,
    }
}));

function HideOnScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({target: window ? window() : undefined});

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function PrivateRoute({component, ...rest}) {
    const {user, loading} = useAuth();
    if (loading) {
        component = UserLoading
    } else if (!user) {
        component = SignIn
    }
    return <Route {...rest} component={component}/>
}

function UserInfo() {
    const classes = useStyles({});
    const {user, error} = useAuth();
    const {first_name, last_name, avatar, role} = user || {};

    return (
        <>
            {error && <ErrorSnackbar message={error}/>}
            {user ? (
                <div className={classes.rightCenter}>
                    <span className={classes.toolbarAvatar}>
                        <Avatar alt={`${first_name} ${last_name}`} src={avatar}/>
                    </span>

                    <span className={classes.toolbarUser}>
                        <Typography variant="body2">{first_name} {last_name}</Typography>
                        <Typography variant="caption">{role}</Typography>
                    </span>

                    <span className={classes.toolbarLogout}>
                        <Logout/>
                    </span>
                </div>
            ) : (
                <Login/>
            )}
        </>
    )
}

function Progress() {
    const {loading} = useProgress();

    return loading ? <LinearProgress color="secondary"/> : null;
}

function App(props) {
    const classes = useStyles();

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const toggleDrawer = () => setDrawerOpen(!drawerOpen);

    // const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    // const [promptVisible, setPromptVisible] = useState(false);

    // useEffect(() => {
    //     prompt && setPromptVisible(true);
    // }, [prompt]);

    return (
        <Router className={classes.root}>
            <AuthenticationProvider>
                <ProgressProvider>
                    <HideOnScroll {...props}>
                        <AppBar position="sticky">
                            <Toolbar variant="dense">
                                <IconButton edge="start" className={classes.menuButton} color="inherit"
                                            onClick={toggleDrawer}>
                                    <MenuIcon/>
                                </IconButton>
                                <Typography variant="h6" className={classes.title} noWrap>
                                    Roush Service Company Inc.
                                </Typography>
                                <div className={classes.grow}/>
                                <UserInfo/>
                            </Toolbar>
                            <Progress/>
                        </AppBar>
                    </HideOnScroll>
                    <SwipeableDrawer open={drawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer}>
                        <Drawer toggleDrawer={toggleDrawer}/>
                    </SwipeableDrawer>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <PrivateRoute path="/" exact={true} component={Home}/>
                        <PrivateRoute path="/table" component={WorkTable}/>
                        <PrivateRoute path="/map" component={Map}/>
                        {/*<PrivateRoute path="/list" component={WorkList}/>*/}
                        {/*<PrivateRoute path="/payroll" exact={true} component={Payroll}/>*/}
                        <PrivateRoute path="/reports" component={Reports}/>
                        {/*<PrivateRoute path="/reports/:reportId" component={Report}/>*/}
                        <PrivateRoute path="/users" exact={true} component={Users}/>
                        <PrivateRoute path="/work/:workId" component={WorkDetails}/>
                        <PrivateRoute path="/construction" exact component={ConstructionList}/>
                        <PrivateRoute path="/construction/:constructionId" component={Construction}/>
                        <PrivateRoute path="/toFix" component={ToFix}/>
                        <PrivateRoute path="/create" component={CreateWork}/>
                        <PrivateRoute path="/training" component={Training}/>
                        <PrivateRoute path="/att_zoho" component={ATTZoho}/>
                        <PrivateRoute path="/routing_settings" component={RoutingSettings}/>
                        <PrivateRoute path="/features" component={Features}/>
                        <PrivateRoute path="/email_recipients" component={EmailRecipients}/>
                    </MuiPickersUtilsProvider>
                    {/*{promptVisible && (*/}
                    {/*    <div onClick={() => setPromptVisible(false)}>*/}
                    {/*        <button onClick={() => setPromptVisible(false)}>Close</button>*/}
                    {/*        This site can be added as a PWA to your home screen.*/}
                    {/*        <button onClick={promptToInstall}>Add to home screen</button>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </ProgressProvider>
            </AuthenticationProvider>
        </Router>
    );
}

export default App;
