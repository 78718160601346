import {useAsyncDebounce, useExpanded, useFilters, usePagination, useSortBy, useTable} from 'react-table'
import React, {useMemo} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
import {CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useFlexLayout} from "react-table/src/plugin-hooks/useFlexLayout";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
    },
    table: {},
    tableHead: {
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 2
    },
    loadingRow: {
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'rgba(255,255,255,0.6)',
    },
    loadingIndicator: {
        position: "absolute",
        top: 48,
    },
    tableContainer: {
        height: 0,
        flexGrow: 1
    },
    pagination: {
        zIndex: 2
    }
}));

const filterInputProps = {
    size: 1
}

// Define a default UI for filtering
function DefaultColumnFilter({column: {filterValue, setFilter}}) {
    return (
        <TextField
            // variant="outlined"
            value={filterValue || ""}
            onChange={e => setFilter(e.target.value || undefined)}
            label="Filter"
            margin="dense"
            fullWidth
            inputProps={filterInputProps} // Allow the text field to shrink as much as it needs
        />
    )
}

export function DateRangeFilter({column: {filterValue, setFilter}}) {
    return (
        <>
            <DatePicker
                showTodayButton
                margin="dense"
                label="Start date"
                format="ll"
                value={filterValue?.start || moment("2000-01-01")}
                onChange={d => setFilter({...filterValue, start: d.startOf('day')})}
            />
            <DatePicker
                showTodayButton
                label="End date"
                format="ll"
                value={filterValue?.end || moment("2099-01-01")}
                onChange={d => setFilter({...filterValue, end: d.endOf('day')})}
            />
        </>
    )
}

export function MultiSelectFilterFactory(valuesAndNames) {
    let options = valuesAndNames
    if (Array.isArray(options)) {
        options = options.reduce((accum, curr) => {
            accum[curr] = curr
            return accum
        }, {})
    }

    const allValues = Object.keys(options);

    // TODO: Add select all / deselect all / select only options
    return function MultiSelectFilter({column: {id, filterValue, setFilter}}) {
        const values = filterValue || allValues;

        return <FormControl style={{marginTop: 4}} fullWidth marign="dense">
            <InputLabel id={`select-${id}`}>Filter</InputLabel>
            <Select
                labelId={`select-${id}`}
                value={values}
                multiple
                onChange={e => setFilter(e.target.value || undefined)}
                renderValue={(selected) => selected.map(s => options[s]).join(', ')}
            >
                {allValues.map(value => (
                    <MenuItem value={value} key={value}>
                        <Checkbox checked={values.indexOf(value) > -1}/>
                        <ListItemText primary={options[value]}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    }
}

// Create a default prop getter
const defaultPropGetter = () => ({});
const renderEmptySubComponent = () => null

const paginationOptions = [5, 10, 25, 100];

const Row = React.memo(function Row({row, isExpanded, getRowProps, getColumnProps, getCellProps, visibleColumns, renderRowSubComponent}) {
    return <>
        <TableRow {...row.getRowProps(getRowProps(row))}>
            {row.cells.map(cell => {
                return (
                    <TableCell
                        {...cell.getCellProps([
                            {
                                className: cell.column.className,
                                style: (cell.column.getCellStyle && cell.column.getCellStyle(cell)) || cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                        ])}
                    >
                        {cell.render('Cell')}
                    </TableCell>
                )
            })}
        </TableRow>
        {isExpanded && <TableRow>
            <TableCell colSpan={visibleColumns.length}>
                {renderRowSubComponent({row})}
            </TableCell>
        </TableRow>}
    </>
})

export default function Table({
                                  columns,
                                  data,
                                  totalResults,
                                  pageCount,
                                  loading,
                                  error,
                                  fetchData,
                                  renderRowSubComponent = renderEmptySubComponent,
                                  getHeaderProps = defaultPropGetter,
                                  getColumnProps = defaultPropGetter,
                                  getRowProps = defaultPropGetter,
                                  getCellProps = defaultPropGetter
                              }) {

    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const history = useHistory();
    const initialState = useMemo(() => {
        const urlState = new URLSearchParams(history.location.search).get("state")
        if (urlState) {
            try {
                return JSON.parse(urlState)
            } catch (e) {
                console.error("Error parsing search params", e, history.location.search)
            }
        }
        return {
            pageSize: 100,
            sortBy: [{id: "created_at", desc: true}]
        }
    }, []);

    const instance = useTable({
            columns,
            data,
            defaultColumn,
            pageCount,
            initialState,
            autoResetPage: true,
            autoResetExpanded: true,
            manualSortBy: true,
            manualPagination: true,
            manualFilters: true,
            pageOptions: paginationOptions,
        },
        useFilters,
        useSortBy,
        useFlexLayout,
        useExpanded,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        visibleColumns,
        // flatColumns,
        // getToggleHideAllColumnsProps,
        state,
        state: {pageIndex, pageSize, sortBy, filters},
    } = instance;

    // Debounce our onFetchData call for 100ms
    const fetchDataDebounced = useAsyncDebounce(fetchData, 100)

    // https://github.com/tannerlinsley/react-table/blob/master/docs/faq.md#how-can-i-use-the-table-state-to-fetch-new-data
    React.useEffect(() => {
        fetchDataDebounced({filters, sortBy, pageSize, pageIndex})
    }, [fetchDataDebounced, filters, sortBy, pageSize, pageIndex]);

    // Used for updating the state in the query params
    React.useMemo(() => {
        const {expanded, ...stateWithoutExpanded} = state;
        // Don't persist expanded to the URL params as it causes the same index row to be expanded on page change
        const stateJson = JSON.stringify(stateWithoutExpanded);
        const search = new URLSearchParams(history.location.search);
        if (search.get("state") !== stateJson) {
            search.set("state", stateJson);
            history.push({
                ...history.location,
                search: search.toString(),
            });
        }
    }, [history, state])

    const onChangePage = (_, newPage) => gotoPage(newPage)
    const onChangeRowsPerPage = e => setPageSize(Number(e.target.value))

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TableContainer component={Paper} elevation={4} className={classes.tableContainer}>
                <MaUTable stickyHeader {...getTableProps()}>
                    <TableHead className={classes.tableHead}>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell
                                        component="th"
                                        // Return an array of prop objects and react-table will merge them appropriately
                                        {...column.getHeaderProps([
                                            {
                                                className: column.className,
                                                style: column.style,
                                            },
                                            getColumnProps(column),
                                            getHeaderProps(column),
                                        ])}
                                        sortDirection={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : false}
                                    >
                                        {column.canSort ?
                                            <TableSortLabel
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                {...column.getSortByToggleProps()}
                                            >
                                                {column.render('Header')}
                                            </TableSortLabel>
                                            :
                                            column.render('Header')
                                        }
                                        <div>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {loading && (
                            <TableRow className={classes.loadingRow}>
                                <TableCell colSpan="100000">
                                    <CircularProgress size={64} className={classes.loadingIndicator}/>
                                </TableCell>
                            </TableRow>
                        )}
                        {!!error ?
                            <TableRow>
                                <TableCell colSpan="100000">
                                    <Typography color="error" variant="h5">Loading error: {error}</Typography>
                                </TableCell>
                            </TableRow>
                            :
                            page.length === 0 && !loading ?
                                <TableRow>
                                    <TableCell colSpan="100000">
                                        <Typography>No results found for the specified filters</Typography>
                                    </TableCell>
                                </TableRow>
                                :
                                page.map((row, i) => {
                                    prepareRow(row);
                                    return <Row
                                        row={row}
                                        key={i}
                                        isExpanded={row.isExpanded}
                                        getRowProps={getRowProps}
                                        getColumnProps={getColumnProps}
                                        getCellProps={getCellProps}
                                        visibleColumns={visibleColumns}
                                        renderRowSubComponent={renderRowSubComponent}
                                    />
                                })
                        }
                    </TableBody>
                </MaUTable>
            </TableContainer>
            <TablePagination
                className={classes.pagination}
                rowsPerPageOptions={paginationOptions}
                component="div"
                count={totalResults}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={selectProps}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
            />
        </div>
    )
}

const selectProps = {
    inputProps: {'aria-label': 'rows per page'},
    native: true,
}
