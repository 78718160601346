import React, {useEffect} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {usePermitWork} from "../api/work";
import Comment from "../components/Comment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddAttachments from "../components/AddAttachments";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackbar from "../components/ErrorSnackbar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
    selectPermitState: {
        marginRight: theme.spacing(1),
        minWidth: 200
    }
}));

export default function Permits({work: {id: workId, permits}, onAdd}) {
    const currentIdx = permitStates.indexOf((permits[0] || {}).state)
    const nextState = (currentIdx >= permitStates.length - 1) ? undefined : permitStates[currentIdx + 1];
    const [state, setState] = React.useState(nextState)
    const [userComment, setUserComment] = React.useState('');
    const [userAttachments, setUserAttachments] = React.useState([])
    const classes = useStyles();

    const [{work: newWork, loading, error}, apiSubmitPermit] = usePermitWork();

    const submitPermit = async event => {
        const newPermit = {
            workId,
            state,
            comment: {
                message: userComment,
                attachments: await Promise.all(userAttachments.map(async a => ({
                    filename: a.name,
                    data: await a.toBase64(),
                })))
            }
        };

        apiSubmitPermit(newPermit);
    };

    useEffect(() => {
        if (newWork) {
            onAdd(newWork)
            resetInput();
        }
    }, [newWork, setUserComment, setUserAttachments]);

    const resetInput = event => {
        setUserComment('');
        setUserAttachments([]);
    };

    return (
        <>
            <List disablePadding>
                {state !== undefined && (
                    <div style={{position: 'relative'}}>
                        {loading && (
                            <div className={classes.loadingContainer}>
                                <CircularProgress size={64}/>
                            </div>
                        )}
                        {error && <ErrorSnackbar message={error}/>}
                        <ListItem disableGutters>
                            <FormControl variant="outlined" className={classes.selectPermitState}>
                                <InputLabel id="permit-status-label">New permit status</InputLabel>
                                <Select
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                    labelId="permit-status-label"
                                    label="New permit status"
                                >
                                    {permitStates.map(value => (
                                        <MenuItem value={value} key={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                variant="outlined"
                                multiline
                                fullWidth
                                value={userComment}
                                onChange={ev => setUserComment(ev.target.value)}
                                label="Optional comment"
                            />
                        </ListItem>
                        <AddAttachments
                            id="permit-contained-button-file"
                            label="Optionally attach files"
                            attached={userAttachments}
                            setAttached={setUserAttachments}
                        />
                        <ListItem disableGutters>
                            <div style={{flexGrow: 1}}/>
                            <Button onClick={resetInput}>Discard</Button>
                            <Button onClick={submitPermit} color="primary">Submit</Button>
                        </ListItem>
                    </div>
                )}
                {permits.map((permit, i) =>
                    <Comment
                        divider={i > 0}
                        disableGutters
                        key={permit.comment.id}
                        comment={{
                            ...permit.comment,
                            message: <>
                                <Typography variant="subtitle2">{permit.state}</Typography>
                                {permit.comment.message}
                            </>
                        }}
                        isExpanded={true}
                    />
                )}
            </List>
        </>
    )
}

const permitStates = [
    "Drawing sent",
    "Drawing approved",
    "Payment sent",
    "Permit received",
    "Inspection complete",
    "Deposit returned"
]