import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Box, Link, Tab, Tabs, Typography} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PersonIcon from '@material-ui/icons/Person';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MapIcon from '@material-ui/icons/Map';
import CommentIcon from '@material-ui/icons/Comment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import formatPhoneNumber from "../util/formatPhoneNumber";
import makeGoogleMapsURL from "../util/makeGoogleMapsURL";
import Button from "@material-ui/core/Button";
import OUPSLookupDialog from "../components/oups/OUPSLookupDialog";
import ScheduleIcon from '@material-ui/icons/Schedule';
import InfoIcon from '@material-ui/icons/Info';
import RouterIcon from '@material-ui/icons/Router';
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import ErrorIcon from '@material-ui/icons/Error';
import {Phone} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import UserChip from "../components/UserChip";
import CommentAttachmentDialog from "../components/CommentAttachmentDialog";
import {useCancelWork, useHoldWork, useReleaseWork} from "../api/work";
import AssignDialog from "../components/AssignDialog";
import OUPSSubmitDialog from "../components/oups/OUPSSubmitDialog";
import UrgentDialog from "../components/UrgentDialog";
import Divider from "@material-ui/core/Divider";
import TimelineIcon from "@material-ui/icons/Timeline";
import {Status} from "../const/status";
import {Link as RouterLink} from "react-router-dom";
import RepeatIcon from '@material-ui/icons/Repeat';
import {baseAPIUrl} from "../Config";

const useStyles = makeStyles(theme => ({
    textRightMargin: {
        marginRight: theme.spacing(1),
    },
}))

function DetailsTab({work}) {
    const {
        job_number,
        node,
        wire_type,
        drop_bury_length,
        primary_locator,
        primary_reason,
        technician_comments,
        last_received
    } = work

    return (
        <List dense disablePadding>
            {job_number && <ListItem key="job_number" disableGutters>
                <ListItemIcon>
                    <InfoIcon/>
                </ListItemIcon>
                <ListItemText>
                    Job number {job_number}
                </ListItemText>
            </ListItem>}
            {node && <ListItem key="node" disableGutters>
                <ListItemIcon>
                    <InfoIcon/>
                </ListItemIcon>
                <ListItemText>
                    Node {node}
                </ListItemText>
            </ListItem>}
            {wire_type && <ListItem key="wire_type" disableGutters>
                <ListItemIcon>
                    <RouterIcon/>
                </ListItemIcon>
                <ListItemText>
                    Wire type {wire_type}
                </ListItemText>
            </ListItem>}
            {drop_bury_length && <ListItem key="drop_bury_length" disableGutters>
                <ListItemIcon>
                    <TimelineIcon/>
                </ListItemIcon>
                <ListItemText>
                    Drop bury length {drop_bury_length}
                </ListItemText>
            </ListItem>}
            {primary_reason && <ListItem key="primary_reason" disableGutters>
                <ListItemIcon>
                    <CommentIcon/>
                </ListItemIcon>
                <ListItemText>
                    Primary reason {primary_reason}
                </ListItemText>
            </ListItem>}
            {primary_locator && primary_locator !== "null" && <ListItem key="primary_locator" disableGutters>
                <ListItemIcon>
                    <InfoIcon/>
                </ListItemIcon>
                <ListItemText>
                    Primary locator {primary_locator}
                </ListItemText>
            </ListItem>}
            {technician_comments.map(({id, message}) => (
                <ListItem key={`comment_${id}`} disableGutters>
                    <ListItemIcon>
                        <CommentIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        {message}
                    </ListItemText>
                </ListItem>
            ))}
            {last_received && <ListItem key="last_received" disableGutters>
                <ListItemIcon>
                    <ScheduleIcon/>
                </ListItemIcon>
                <ListItemText>
                    Last received {moment(last_received).format("lll")}
                </ListItemText>
            </ListItem>}
        </List>
    )
}

function AccountTab({work}) {
    const {
        customer: {account, name, home_phone},

        // Spectrum only
        subaccount_number,
    } = work

    return (
        <List dense disablePadding>
            <ListItem key="customer_name" disableGutters>
                <ListItemIcon>
                    <PersonIcon/>
                </ListItemIcon>
                <ListItemText>
                    {name}
                </ListItemText>
            </ListItem>
            {home_phone && <ListItem key="customer_phone" disableGutters>
                <ListItemIcon>
                    <Phone/>
                </ListItemIcon>
                <ListItemText>
                    <Link href={`tel:${home_phone}`}>{formatPhoneNumber(home_phone)}</Link>
                </ListItemText>
            </ListItem>}
            {account && <ListItem key="account" disableGutters>
                <ListItemIcon>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText>
                    Customer account number {account}
                </ListItemText>
            </ListItem>}
            {subaccount_number && <ListItem key="subaccount_number" disableGutters>
                <ListItemIcon>
                    <InfoIcon/>
                </ListItemIcon>
                <ListItemText>
                    Subaccount number {subaccount_number}
                </ListItemText>
            </ListItem>}
        </List>
    )
}

function LocationTab({work}) {
    const {address, geocodeError} = work

    if (!address) {
        return <Typography color="error">
            Unable to geolocate the raw address. May need to OUPS manually.<br/>
            {geocodeError && <b>{geocodeError}</b>}
        </Typography>
    }

    const {lot, county, township} = address;

    return (
        <List dense disablePadding>
            {lot && <ListItem disableGutters>
                <ListItemIcon>
                    <ApartmentIcon/>
                </ListItemIcon>
                <ListItemText>
                    Lot {lot}
                </ListItemText>
            </ListItem>}
            {county && <ListItem disableGutters>
                <ListItemIcon>
                    <MapIcon/>
                </ListItemIcon>
                <ListItemText>
                    {county} county
                </ListItemText>
            </ListItem>}
            {township && <ListItem disableGutters>
                <ListItemIcon>
                    <MapIcon/>
                </ListItemIcon>
                <ListItemText>
                    {township} township
                </ListItemText>
            </ListItem>}
        </List>
    )
}

function OUPSTab({work}) {
    const {oups} = work
    
    if (oups.length === 0) {
        return <Typography>No OUPS tickets have been filed</Typography>
    }

    let oupsToShow = oups.filter(o => {
        const {work_date, start_by_date} = o
        
        if (!work_date || !start_by_date) return false

        return moment().isBetween(work_date, start_by_date)
    })
    
    if (oupsToShow.length === 0) {
        oupsToShow = [oups[0]]
    }

    return <List dense disablePadding>
        {oupsToShow.map((o, i) => <>
            <ListItem disableGutters>
                <ListItemIcon>
                    <ScheduleIcon/>
                </ListItemIcon>
                <ListItemText>
                    {o.type}ed at {moment(o.created_at).format('lll')}
                </ListItemText>
            </ListItem>
            {o.error && <ListItem disableGutters>
                <ListItemIcon>
                    <ErrorIcon/>
                </ListItemIcon>
                <ListItemText>
                    Error: {o.error}
                </ListItemText>
            </ListItem>}
            <ListItem disableGutters>
                <ListItemIcon>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText>
                    Ticket: {o.ticket}
                </ListItemText>
            </ListItem>
            <ListItem disableGutters>
                <ListItemIcon>
                    <ScheduleIcon/>
                </ListItemIcon>
                <ListItemText>
                    Work date: {moment(o.work_date).format('ddd ll')}
                </ListItemText>
            </ListItem>
            <ListItem disableGutters>
                <ListItemIcon>
                    <ScheduleIcon/>
                </ListItemIcon>
                <ListItemText>
                    Start by date: {moment(o.start_by_date).format('ddd ll')}
                </ListItemText>
            </ListItem>
            {i !== oupsToShow.length - 1 && <Box pt={2} pb={2}><Divider variant="fullWidth"/></Box>}
        </>)}
    </List>
}

function DuplicatesTab({work}) {
    const duplicates = work.duplicate_completions.map(w => {
        const complete = w.transitions.find(t => t.to_state === Status.Complete)
        return {work: w, complete}
    })

    const hasChargeback = work.transitions.some(t => t.to_state === Status.Complete && !!t.complete.investigated)

    return <List disablePadding>
        {hasChargeback && <ListItem disableGutters>
            <Link href={`${baseAPIUrl}/chargeback/${work.id}`} style={{display:"block"}} target="_blank">
                Download chargeback spreadsheet
            </Link>
        </ListItem>}
        {duplicates.map((d, i) => <>
            <ListItem disableGutters key={i} button component={RouterLink} to={`/work/${d.work.id}`} target="_blank">
                <ListItemIcon>
                    <RepeatIcon />
                </ListItemIcon>
                <ListItemText secondary={<>Work order {d.work.order_num}</>}>
                    Completed {moment(d.complete.created_at).format('ddd lll')} by {d.complete.user.first_name} {d.complete.user.last_name}
                </ListItemText>
            </ListItem>
        </>)}
    </List>
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

export default function WorkInfo({work, setWork, ...other}) {
    const classes = useStyles({});

    const {
        company,
        order_num,
        raw_address,
        address,
        service_codes,
        is_commercial,
        urgent,
        due,
        transitions,
        duplicate_completions,
    } = work;

    const hasDuplicates = !!duplicate_completions && duplicate_completions.length > 0

    const {to_state, created_at, user: transitionUser} = transitions.find(t => {
        return t.to_state !== "Recreated"
    })

    const isComplete = to_state === "Complete" || to_state === "QC complete"

    const [oupsLookupOpen, setOupsLookupOpen] = React.useState(false);

    function oupsLookupClosed(updatedWork) {
        setOupsLookupOpen(false)
        updatedWork && setWork(updatedWork)
    }

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box p={2} display="flex" flexDirection="column" {...other}>
            {oupsLookupOpen && <OUPSLookupDialog work={work} onClose={oupsLookupClosed}/>}
            <Box>
                <Typography variant="h5">
                    {company} #{order_num}
                </Typography>

                <Typography variant="h6">
                    <b>{to_state}</b> - {moment(created_at).format("ddd lll")}
                </Typography>

                <UserChip user={transitionUser}/>

                <Box pt={2}>
                    <Grid container>
                        {service_codes && <>
                            <Grid item xs={4} sm={3}>
                                <Typography><b>Service codes</b></Typography>
                            </Grid>
                            <Grid item xs={8} sm={9}>
                                <Typography color={urgent ? "error" : undefined}>{service_codes}</Typography>
                            </Grid>
                        </>}

                        {due && !isComplete && <>
                            <Grid item xs={4} sm={3}>
                                <Typography><b>Due</b></Typography>
                            </Grid>
                            <Grid item xs={8} sm={9}>
                                <Typography>{moment(due).format("ddd ll")}</Typography>
                            </Grid>
                        </>}

                        <>
                            <Grid item xs={4} sm={3}>
                                <Typography><b>Type</b></Typography>
                            </Grid>
                            <Grid item xs={8} sm={9}>
                                <Typography>{is_commercial ? "Commercial" : "Residential"}</Typography>
                            </Grid>
                        </>

                        <>
                            <Grid item xs={4} sm={3}>
                                <Typography><b>Address</b></Typography>
                            </Grid>
                            <Grid item xs={8} sm={9}>
                                <Typography>
                                    {address ? <Link href={makeGoogleMapsURL(address)} target="_blank">
                                        {address.formatted}
                                    </Link> : raw_address}
                                </Typography>
                            </Grid>
                        </>
                    </Grid>
                </Box>
            </Box>

            <Box pt={2}>
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
                    <Tab label="Details" style={{minWidth: 0}}/>
                    <Tab label="Account" style={{minWidth: 0}}/>
                    <Tab label="Location" style={{minWidth: 0}}/>
                    <Tab label="OUPS" style={{minWidth: 0}}/>
                    {hasDuplicates && <Tab label="Duplicates" style={{minWidth: 0}}/> }
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                <DetailsTab work={work}/>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <AccountTab work={work}/>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                <LocationTab work={work}/>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                <OUPSTab work={work}/>
            </TabPanel>

            {hasDuplicates && (
                <TabPanel value={tabValue} index={4}>
                    <DuplicatesTab work={work}/>
                </TabPanel>
            )}

            <div style={{flexGrow: 1}}/>

            <Box>
                <WorkActions work={work} setWork={setWork} />
            </Box>
        </Box>
    );
}

function WorkActions({work, setWork}) {
    const [lookupOpen, setLookupOpen] = React.useState(false);
    const [oupsOpen, setOupsOpen] = React.useState(false);
    const [assignOpen, setAssignOpen] = React.useState(false);
    const [cancelOpen, setCancelOpen] = React.useState(false);
    const [holdOpen, setHoldOpen] = React.useState(false);
    const [releaseOpen, setReleaseOpen] = React.useState(false);
    const [urgentOpen, setUrgentOpen] = React.useState(false);

    function closeDialog(updatedWork, setOpenFunc) {
        setOpenFunc(false)
        updatedWork && setWork && setWork(updatedWork)
    }

    const assign = () =>  setAssignOpen(true);
    const cancel = () => setCancelOpen(true);
    const hold = () => setHoldOpen(true);
    const release = () => setReleaseOpen(true);
    const addressLookup = () => setLookupOpen(true);
    const urgent = () => setUrgentOpen(true);

    const onAssignClose = newWork => closeDialog(newWork, setAssignOpen);
    const onCancelClose = newWork => closeDialog(newWork, setCancelOpen);
    const onHoldClose = newWork => closeDialog(newWork, setHoldOpen);
    const onReleaseClose = newWork => closeDialog(newWork, setReleaseOpen);
    const onSubmitClosed = newWork => closeDialog(newWork, setOupsOpen);
    const onUrgentClosed = newWork => closeDialog(newWork, setUrgentOpen);

    const onLookupClosed = (updatedWork, submitOUPS) => {
        closeDialog(updatedWork, setLookupOpen)
        submitOUPS && setOupsOpen(true)
    }

    return <>
        {cancelOpen && <CommentAttachmentDialog
            description="Add a comment and optional photos/videos of why the work was canceled"
            apiFunc={useCancelWork}
            workId={work.id}
            submitText="Cancel"
            title="Cancel work"
            onClose={onCancelClose}
        />}
        {holdOpen && <CommentAttachmentDialog
            description="Add a comment and optional photos/videos of why the work was put on hold"
            apiFunc={useHoldWork}
            workId={work.id}
            submitText="Hold"
            title="Hold work"
            onClose={onHoldClose}
        />}
        {releaseOpen && <CommentAttachmentDialog
            description="Add a comment and optional photos/videos of why the work was released from hold"
            apiFunc={useReleaseWork}
            workId={work.id}
            submitText="Release"
            title="Release held work"
            onClose={onReleaseClose}
        />}
        {assignOpen && <AssignDialog
            work={work}
            onClose={onAssignClose}
        />}
        {lookupOpen && <OUPSLookupDialog
            allowOUPSSubmit
            work={work}
            onClose={onLookupClosed}
        />}
        {oupsOpen && <OUPSSubmitDialog
            work={work}
            onClose={onSubmitClosed}
        />}
        {urgentOpen && <UrgentDialog
            work={work}
            onClose={onUrgentClosed}
        />}

        <Button color="primary" style={{marginRight: 12}} onClick={addressLookup}>
            Add to OUPS
        </Button>

        <Button color="primary" style={{marginRight: 12}} onClick={assign}>
            Assign
        </Button>

        {work?.status?.transition?.to_state === "On hold" ?
            <Button color="primary" style={{marginRight: 12}} onClick={release}>
                Release Hold
            </Button>
            :
            <Button color="primary" style={{marginRight: 12}} onClick={hold}>
                Hold
            </Button>
        }

        <Button color="primary" style={{marginRight: 12}} onClick={cancel}>
            Cancel
        </Button>

        <Button color="primary" onClick={urgent}>
            Urgent
        </Button>
    </>
}