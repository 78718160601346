import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";
import {googleClientId} from "./Config";
import { GoogleOAuthProvider } from '@react-oauth/google';

// if (process.env.NODE_ENV === 'development') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render');
//     whyDidYouRender(React, {
//         trackAllPureComponents: true,
//     });
// }

ReactDOM.render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <GoogleOAuthProvider clientId={googleClientId}>
            <App />
        </GoogleOAuthProvider>
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

let lastEtag, timeoutId;

function checkForUpdate() {
    fetch('/').then(resp => {
        const etag = resp.headers.get("etag")
        if (lastEtag === undefined) {
            lastEtag = etag
        } else if (lastEtag !== etag) {
            window.location.reload()
        }
        timeoutId = setTimeout(checkForUpdate, 60000)
    })
}

document.addEventListener("visibilitychange", function () {
    if (document.visibilityState === "hidden") {
        clearTimeout(timeoutId)
        timeoutId = undefined
    } else if (document.visibilityState === "visible" && !timeoutId) {
        checkForUpdate()
    }
})

checkForUpdate()
