import {Dialog, TextField, useMediaQuery} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "@fortawesome/fontawesome-free/css/all.css";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import AddAttachments from "./AddAttachments";
import ErrorSnackbar from "./ErrorSnackbar";
import {useCompleteWork} from "../api/work";
import CompleteBilling, {types} from "./CompleteBilling";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {AttachFile} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {useTheme} from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
    errorList: {
        paddingInlineStart: 0,
    },
    errorListNested: {
        paddingInlineStart: 24,
    },
}));

function BillingErrors({toBill, errors, setErrors, company}) {
    const classes = useStyles();

    React.useEffect(() => {
        if (toBill.length === 0) {
            setErrors({})
            return
        }

        const errors = toBill.reduce((accum, billed) => {
            const errors = [];
            const {name, unit, quantity, requiresAny, requiresAll, incompatibleWith, maxQuantity, validate} = billed

            if (incompatibleWith) {
                if (incompatibleWith === "*" && toBill.length > 1) {
                    errors.push(`Can't be billed with other items`)
                } else if (Array.isArray(incompatibleWith)) {
                    toBill.forEach(({name: otherName}) => {
                        if (incompatibleWith.includes(otherName)) {
                            errors.push(`Can't be billed with ${otherName}`)
                        }
                    })
                }
            }

            const any = requiresAny?.[company]
            if (Array.isArray(any)) {
                if (!toBill.some(({name: otherName}) => any.includes(otherName))) {
                    errors.push(`Requires${any.length > 1 ? ' one of' : ''} ${any.join(", ")}`)
                }
            }

            const all = requiresAll?.[company]
            if (Array.isArray(all)) {
                all.filter(name => !toBill.some(({name: otherName}) => otherName === name)).forEach(missing => {
                    errors.push(`Requires ${missing}`)
                })
            }

            if (unit && !quantity) {
                errors.push(`Quantity of ${unit} must be greater than zero`)
            }
            if (quantity && maxQuantity && quantity > maxQuantity) {
                errors.push(`Cannot have more than ${maxQuantity} ${unit}`)
            }

            if (validate) {
                const error = validate(billed, toBill);
                if (error) {
                    errors.push(error)
                }
            }

            if (errors.length > 0) {
                accum[name] = errors;
            }
            return accum
        }, {})

        setErrors(errors)
    }, [toBill, setErrors])

    if (Object.keys(errors).length === 0) {
        return null
    }

    return (
        <Alert elevation={6} variant="filled" severity="error">
            <AlertTitle>Billing errors</AlertTitle>
            <ul className={classes.errorList}>
                {Object.keys(errors).map(name =>
                    <li key={name}>
                        {name}
                        <ul className={classes.errorListNested}>
                            {errors[name].map((error, i) =>
                                <li key={i}>{error}</li>
                            )}
                        </ul>
                    </li>
                )}
            </ul>
        </Alert>
    )
}

export default function CompleteDialog({onClose, work: {id, is_commercial, company, duplicate_completions}}) {
    const classes = useStyles();
    const [userComment, setUserComment] = React.useState('');
    const [attached, setAttached] = React.useState([]);
    const [{work: updatedWork, loading, error}, submitReq] = useCompleteWork();
    const [toBill, setToBill] = React.useState([]);
    const [userErrors, setUserErrors] = React.useState({});

    const [investigate, setInvestigate] = React.useState(false);
    const [investigateComment, setInvestigateComment] = React.useState('');
    const [investigatePreviousDropDepth, setInvestigatePreviousDropDepth] = React.useState(undefined);
    const [investigatePedPoleLockboxAttached, setInvestigatePedPoleLockboxAttached] = React.useState([]);
    const [investigateGroundBlockAttached, setInvestigateGroundBlockAttached] = React.useState([]);
    const [investigateDropBuryPathAttached, setInvestigateDropBuryPathAttached] = React.useState([]);
    const [investigateOriginalDropDamageAttached, setInvestigateOriginalDropDamageAttached] = React.useState([]);

    // Investigation required when there are duplicate completions and this isn't a trip charge
    const investigationRequired = !!duplicate_completions &&
        duplicate_completions.length > 0 &&
        !toBill.some(it => it.name === types.tripCharge)

    // Called when the complete button is clicked
    async function onComplete() {
        // console.log(toBill, userComment, attached)
        const attachments = await Promise.all([
            ...attached.map(async a => ({
                filename: a.name,
                data: await a.toBase64(),
            })),
            ...investigatePedPoleLockboxAttached.map(async a => ({
                filename: a.name,
                caption: "Ped/Pole/Lockbox",
                data: await a.toBase64(),
            })),
            ...investigateGroundBlockAttached.map(async a => ({
                filename: a.name,
                caption: "Ground block",
                data: await a.toBase64(),
            })),
            ...investigateDropBuryPathAttached.map(async a => ({
                filename: a.name,
                caption: "Drop bury path",
                data: await a.toBase64(),
            })),
            ...investigateOriginalDropDamageAttached.map(async a => ({
                filename: a.name,
                caption: "Original drop damage",
                data: await a.toBase64(),
            })),
        ]);

        let message = userComment
        if (!!investigateComment) {
            message += "\n\n" + investigateComment
        }

        submitReq({
            workId: id,
            comment: {
                message: message,
                attachments: attachments
            },
            billed: toBill.map(({id, quantity}) => ({
                billable_item_id: id,
                quantity,
            })),
            investigated: investigationRequired,
            previous_drop_depth: investigatePreviousDropDepth,
        });
    }

    // Once the API responds, call onClose with the newly returned work
    React.useEffect(() => {
        updatedWork && onClose(updatedWork)
    }, [updatedWork, onClose]);

    const canSubmit = userComment.length > 0 &&
        attached.length > 0 &&
        toBill.length > 0 &&
        Object.keys(userErrors).length === 0;

    const canSubmitInvestigate = investigateComment.length > 0 &&
        !!investigatePreviousDropDepth &&
        investigatePreviousDropDepth > 0 &&
        investigatePedPoleLockboxAttached.length > 0 &&
        investigateGroundBlockAttached.length > 0 &&
        investigateDropBuryPathAttached.length > 0 &&
        investigateOriginalDropDamageAttached.length > 0

    async function onSubmit() {
        if (investigationRequired) {
            setInvestigate(true)
        } else {
            await onComplete()
        }
    }

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

    if (investigate) {
        return (<Dialog
            fullScreen={fullScreen}
            open={true}
            onClose={() => onClose()}
            maxWidth="sm"
            disableBackdropClick
            fullWidth
        >
            {error && <ErrorSnackbar message={error}/>}
            <DialogTitle>Investigate damage</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Investigate why the previous drop isn't working.
                    Explain the reason for the damage or why the work order was repeated.
                </DialogContentText>
                <List>
                    <div style={{position: 'relative'}}>
                        {loading && <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>}
                        <ListItem disableGutters key="add_comment">
                            <TextField
                                variant="outlined"
                                multiline
                                fullWidth
                                value={investigateComment}
                                onChange={ev => setInvestigateComment(ev.target.value)}
                                label="Explain damage or reason for repeat"
                            />
                        </ListItem>

                        <ListItem disableGutters key="previous_drop_depth">
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Previous drop depth (inches)"
                                fullWidth
                                margin="none"
                                value={investigatePreviousDropDepth || ''}
                                onChange={ev => setInvestigatePreviousDropDepth(parseInt(ev.target.value) || undefined)}
                                required
                            />
                        </ListItem>

                        <ListItem disableGutters key="attach_instructions">
                            <ListItemText>Attach photos of the following:</ListItemText>
                        </ListItem>

                        <AddAttachments
                            label="Ped/Pole/Lockbox"
                            id="ped-pole-lockbox-file-upload"
                            accept="image/*"
                            disableGutters
                            attached={investigatePedPoleLockboxAttached}
                            setAttached={setInvestigatePedPoleLockboxAttached}
                        />

                        <AddAttachments
                            label="Ground block"
                            id="ground-block-file-upload"
                            accept="image/*"
                            disableGutters
                            attached={investigateGroundBlockAttached}
                            setAttached={setInvestigateGroundBlockAttached}
                        />

                        <AddAttachments
                            label="Drop bury path"
                            id="drop-bury-path-file-upload"
                            accept="image/*"
                            disableGutters
                            attached={investigateDropBuryPathAttached}
                            setAttached={setInvestigateDropBuryPathAttached}
                        />

                        <AddAttachments
                            label="Original drop damage"
                            id="original-drop-damage-file-upload"
                            accept="image/*"
                            disableGutters
                            attached={investigateOriginalDropDamageAttached}
                            setAttached={setInvestigateOriginalDropDamageAttached}
                        />

                        {(company === 'TW' && canSubmitInvestigate) && (
                            <BillingCode toBill={toBill} isCommercial={is_commercial}/>
                        )}

                    </div>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setInvestigate(false)}>
                    Back
                </Button>
                <Button
                    color="primary"
                    onClick={onComplete}
                    disabled={!canSubmitInvestigate}
                >
                    Complete
                </Button>
            </DialogActions>
        </Dialog>
        )
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            onClose={() => onClose()}
            maxWidth="sm"
            disableBackdropClick
            fullWidth
        >
            {error && <ErrorSnackbar message={error}/>}
            <DialogTitle>Complete work</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add a description and photos/videos of the completed work then enter billing
                    information. Make sure to enter the cable type used as billable work.
                </DialogContentText>
                <List>
                    <div style={{position: 'relative'}}>
                        {loading && <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>}
                        <ListItem disableGutters key="add_comment">
                            <TextField
                                variant="outlined"
                                multiline
                                fullWidth
                                value={userComment}
                                onChange={ev => setUserComment(ev.target.value)}
                                label="Add your comment"
                                style={{paddingRight: 8}}
                            />
                            <ListItemSecondaryAction>
                                <label htmlFor="complete-file-upload">
                                    <IconButton edge="end" component="span">
                                        <AttachFile/>
                                    </IconButton>
                                </label>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <AddAttachments
                            id="complete-file-upload"
                            accept="image/*" //;capture=camera
                            attached={attached}
                            setAttached={setAttached}
                        />

                        {attached.length > 0 && (
                            <Divider/>
                        )}

                        <CompleteBilling toBill={toBill} setToBill={setToBill} company={company}/>

                        <BillingErrors toBill={toBill} errors={userErrors} setErrors={setUserErrors} company={company}/>

                        {(company === 'TW' && canSubmit && !investigationRequired) && (
                            <BillingCode toBill={toBill} isCommercial={is_commercial}/>
                        )}

                    </div>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={onSubmit}
                    disabled={!canSubmit}
                >
                    {investigationRequired && "Next" || "Complete"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function BillingCode({toBill, isCommercial}) {
    const code = billingCode(toBill, isCommercial)

    if (!code) {
        return null
    }

    return (
        <Alert severity="info" variant="filled">
            Use billing code <strong>{code}</strong> in TechMobile
        </Alert>
    )
}

function billingCode(toBill, isCommercial) {
    if (toBill.length === 0) return null

    const quantities = toBill.reduce((accum, b) => {
        accum[b.name] = b.quantity
        return accum
    }, {})

    if (quantities[types.tripCharge]) {
        return null
    }

    const footage = quantities[types.buryWire] + (quantities[types.drivewayBore] || 0) + (quantities[types.sidewalkBore] || 0)

    const isFiber = !!quantities[types.cableFiber];

    if (isFiber && footage > 1800) {
        return 'G19'
    } else if (isFiber && footage > 1600) {
        return 'G18'
    } else if (isFiber && footage > 1400) {
        return 'G17'
    } else if (isFiber && footage > 1200) {
        return 'G16'
    } else if (isFiber && footage > 1000) {
        return 'G15'
    } else if (isFiber && footage > 800) {
        return 'G13'
    } else if (isFiber && footage > 600) {
        return 'G12'
    } else if (isFiber && footage > 400) {
        return 'G11'
    } else if (quantities[types.cableFlex500] || footage > 250) {
        return '364'
    } else if (footage > 150) {
        return '361'
    } else {
        return '359'
    }
}