import Card from "@material-ui/core/Card";
import GoogleMapReact from "google-map-react";
import React, {useState} from "react";
import {googleMapConfig, googleMapKey} from "../Config";
import {makeStyles} from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    mapCard: {
        // Styles here are a workaround for GoogleMapReact component not having a predefined height
        // minHeight: 400,
        height: "100%",
    },
}));

const infoWindowId = "infoWindowContent";

// TODO: We can move some stuff from the InfoCard to this InfoWindow
function InfoWindow({formatted}) {
    return (
        <div>
            <Typography>{formatted}</Typography>
        </div>
    )
}

export default function MapCard({workArea, address: {lat, lng, formatted}}) {
    const classes = useStyles();
    const [google, setGoogle] = useState(null);
    const [infoWindowComponent, setInfoWindowComponent] = useState(null);

    React.useMemo(() => {
        if (!google) return;

        const marker = new google.maps.Marker({
            position: {lat, lng},
            map: google.map,
            title: formatted,
        });

        const infoWindow = new google.maps.InfoWindow({
            content: `<div id="${infoWindowId}"/>`,
        });

        infoWindow.addListener("closeclick", () => {
            setInfoWindowComponent(null);
        });

        const openInfoWindow = () => {
            // To get context passing in, we need to use ReactDOM.createPortal to create the component
            infoWindow.addListener("domready", function () {
                const el = document.getElementById(infoWindowId);
                const component = <InfoWindow formatted={formatted}/>;
                setInfoWindowComponent(ReactDOM.createPortal(component, el));
            });

            infoWindow.open(google.map, marker);
        };

        marker.addListener('click', openInfoWindow);

        // openInfoWindow();

        if (workArea) {
            new google.maps.Polygon({
                map: google.map,
                paths: google.maps.geometry.encoding.decodePath(workArea),
                strokeColor: '#0000FF',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillColor: '#0000FF',
                fillOpacity: 0.35
            });
        }
    }, [google, formatted, lat, lng, workArea]);

    return (
        <Card raised={true} className={classes.mapCard}>
            <>
            {infoWindowComponent}
            <GoogleMapReact
                bootstrapURLKeys={googleMapConfig}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={setGoogle}
                defaultCenter={{lat, lng}}
                defaultZoom={17}
                options={maps => ({
                    mapTypeControl: true,
                    streetViewControl: true,
                })}
            />
            </>
        </Card>
    );
}