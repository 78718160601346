import React from "react";
import {useWork, useWorkActions, WorkProvider} from "../api/work";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Container, Divider, GridList, GridListTile, GridListTileBar, Paper, useMediaQuery} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AssignedCard from "../cards/AssignedCard";
import MapCard from "../cards/MapCard";
import Permits from "../cards/PermitCard";
import WorkInfo from "../cards/WorkInfo";
import TransitionsCard from "../cards/TransitionsCard";
import AttachmentDialog from "../components/AttachmentDialog";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Comments from "../components/Comments";

const useStyles = makeStyles(theme => ({
    gridList: {
        scrollSnapType: "x mandatory",
        // [theme.breakpoints.up('xl')]: {
        //     height: 380
        // },
        [theme.breakpoints.up('md')]: {
            // height: 280
        },
        [theme.breakpoints.down('sm')]: {
            // height: 280
            flexWrap: 'nowrap'
        },
        // [theme.breakpoints.down('xs')]: {
        //     height: 200
        // }
    },
}));

function WorkDetails({match: {params: {workId}}}) {
    const {work, loading, error} = useWork();
    const {setWork, setWorkId} = useWorkActions();
    setWorkId(workId);

    if (error) {
        return <h1>An error has occurred: {error}</h1>
    }

    if (loading || !work) {
        return <h2>Loading...</h2>
    }

    return (
        <Container maxWidth="lg" disableGutters>
            <Grid container>

                <Grid item md={4} xs={12}>
                    <Photos work={work}/>
                </Grid>

                <Grid item md={8} xs={12}>
                    <Paper square elevation={8} style={{height: "100%"}}>
                        <WorkInfo work={work} setWork={setWork} style={{minHeight: 544}}/>

                        <Divider variant="fullWidth" />

                        <Box p={2}>
                            <Typography variant="h6">Comments</Typography>
                            <Comments work={work} onComment={setWork}/>
                        </Box>

                        <Divider variant="fullWidth" />

                        {work.company === "CLW" && <>
                            <Box p={2}>
                                <Typography variant="h6">
                                    Permits
                                </Typography>
                                <Permits work={work} onAdd={setWork}/>
                            </Box>
                            <Divider variant="fullWidth" />
                        </>}

                        <AssignedCard work={work}/>
                        <Divider variant="fullWidth" />

                        <TransitionsCard work={work} setWork={setWork}/>
                    </Paper>
                </Grid>

            </Grid>
        </Container>
    )
}

function Photos({work}) {
    const [selectedAttachment, setSelectedAttachment] = React.useState()

    function validAttachment({content_type}) {
        return content_type.indexOf("image/") === 0 || content_type === "application/pdf"
    }

    let images = []
    work.transitions.forEach(t => {
        const comment = t[t.to_state.toLowerCase()]?.comment
        if (!comment || !comment.attachments) return

        comment.attachments.map(attachment => {
            if (!validAttachment(attachment)) return

            const caption = attachment.caption || comment.message
            const created_at = moment(comment.created_at);

            images.push({
                created_at,
                attachment: {...attachment, caption},
                url: attachment.data_url,
                caption,
                subtitle: comment.user.first_name + " " + comment.user.last_name + " - " + created_at.format('ddd lll'),
            })
        })
    })

    work.ticket_comments.forEach(c => {
        c.attachments.map(attachment => {
            if (!validAttachment(attachment)) return

            const caption = attachment.caption || c.message
            const created_at = moment(c.created_at);

            images.push({
                created_at,
                attachment: {...attachment, caption},
                url: attachment.data_url,
                caption,
                subtitle: c.user.first_name + " " + c.user.last_name + " - " + created_at.format('ddd lll'),
            })
        })
    })

    work.permits.forEach(p => {
        const comment = p.comment
        if (!comment || !comment.attachments) return

        comment.attachments.map(attachment => {
            if (!validAttachment(attachment)) return

            const caption = attachment.caption || `${p.state} - ${comment.message}`
            const created_at = moment(p.created_at);

            images.push({
                created_at,
                attachment: {...attachment, caption},
                url: attachment.data_url,
                caption,
                subtitle: comment.user.first_name + " " + comment.user.last_name + " - " + created_at.format('ddd lll'),
            })
        })
    })

    images.sort((a, b) => b.created_at.diff(a.created_at))

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const isXsmall = useMediaQuery(theme.breakpoints.down('xs'))

    let cellHeight = 480
    if (isXsmall) {
        cellHeight = 240
    } else if (isSmall) {
        cellHeight = 320
    }

    const classes = useStyles()

    // Destructuring with defaults doesn't work if the value is null (as opposed to undefined) :sad_panda:
    const address = work.address || {};

    const workArea = ((work.oups || []).find(o => o.work_area) || {}).work_area;

    return (
        <Paper elevation={8}>
            {selectedAttachment && <AttachmentDialog attachment={selectedAttachment} setAttachment={setSelectedAttachment} />}
            <GridList cols={1} spacing={0} cellHeight={cellHeight} className={classes.gridList}>
                <GridListTile style={{scrollSnapAlign: "center"}}>
                    <MapCard workArea={workArea} address={address}/>
                </GridListTile>
                {images.map(image => (
                    <GridListTile
                        onClick={() => setSelectedAttachment(image.attachment)}
                        style={{cursor: "zoom-in", scrollSnapAlign: "center"}}
                    >
                        {image.attachment.content_type === "application/pdf" ?
                            <object
                                data={image.attachment.data_url + "#toolbar=0&navpanes=0&scrollbar=0"}
                                type={image.attachment.content_type}
                                width='100%'
                                height='100%'
                                style={{pointerEvents: "none"}}
                            >
                                {image.attachment.filename}
                            </object>
                        :
                            <img src={image.url} alt={image.caption}/>
                        }
                        <GridListTileBar title={image.caption} subtitle={image.subtitle}/>
                    </GridListTile>
                ))}
            </GridList>
        </Paper>
    )
}

export default function (props) {
    return <WorkProvider><WorkDetails {...props}/></WorkProvider>
}