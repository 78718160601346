import Grid from "@material-ui/core/Grid";
import RouteCard from "../cards/RouteCard";
import CurrentWorkCard from "../cards/CurrentWorkCard";
import React from "react";
import {useAssignments} from "../api/work";
import List from "@material-ui/core/List";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
    },
    loadingIndicator: {
        marginRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
}));

export default function HomeWorkView() {
    const classes = useStyles();
    const {data: {todo_work, visited_work, route} = {}, loading, error} = useAssignments();
    const [todoWork = [], setTodoWork] = React.useState(todo_work);
    const [visitedWork = [], setVisitedWork] = React.useState(visited_work);
    const [selectedWork, setSelectedWork] = React.useState(undefined)

    React.useEffect(() => {
        if (visited_work && visited_work.length) setVisitedWork(visited_work)
        if (todo_work && todo_work.length) {
            todo_work.forEach((todo, i) => {
                todo.leg = route?.legs[i]
                todo.route_order += visited_work.length
            })
            setTodoWork(todo_work)
            setSelectedWork(todo_work[0])
        }

    }, [visited_work, setVisitedWork, todo_work, setTodoWork])

    // When work has changed, update the todoWork with the new value.
    function onWorkChanged(work) {
        if (!work) return;

        const idx = todoWork.findIndex(({id}) => id === work.id);
        if (idx === -1) return;

        work.route_order = todoWork[idx].route_order

        const newTodoWork = [...todoWork];
        newTodoWork[idx] = work
        setTodoWork(newTodoWork);

        if (idx === 0) {
            setSelectedWork(newTodoWork)
        }

        return [newTodoWork, idx]
    }

    // When work is completed/skipped, Remove it from the todoWork and put it in visitedWork.
    function onRemoveWork(work) {
        if (!work) return;

        const idx = todoWork.findIndex(({id}) => id === work.id);
        if (idx === -1) return;

        work.route_order = todoWork[idx].route_order

        const newTodoWork = [...todoWork];
        newTodoWork.splice(0, 1)
        setTodoWork(newTodoWork)

        const newVisitedWork = [...visitedWork]
        newVisitedWork.unshift(work)
        setVisitedWork(newVisitedWork)

        setSelectedWork(newTodoWork[0])
    }

    function selectWork(work) {
        setSelectedWork(work);
    }

    if (loading) {
        return <Grid item xs={12}>
            <Paper elevation={4} className={classes.paper}>
                <CircularProgress size={32} className={classes.loadingIndicator}/>
                <Typography variant="h6" component="span">Loading assignments...</Typography>
            </Paper>
        </Grid>
    }

    if (error) {
        return <Grid item xs={12}>
            <Paper elevation={4} className={classes.paper}>
                <Typography variant="h6" color="error">Error loading assignments: {error}</Typography>
            </Paper>
        </Grid>
    }

    return <>
        {(todoWork.length > 0 || visitedWork.length > 0) && <Grid item xs={12} lg={4}>
            <RouteCard
                route={route}
                todoWork={todoWork}
                visitedWork={visitedWork}
            />
        </Grid>}
        {selectedWork && <Grid item xs={12} lg={8}>
            <CurrentWorkCard
                work={selectedWork}
                onWorkChanged={onWorkChanged}
                onRemoveWork={onRemoveWork}
            />
        </Grid>}
        {(todoWork.length > 0 || visitedWork.length > 0) && <>
            <Grid item xs={12} lg={6}>
                <Card className={classes.card} elevation={4}>
                    <CardHeader
                        title="Today's work in progress"
                        subheader="Work to complete for the day. Click to select"
                    />
                    <CardContent>
                        <List>
                            {todoWork.map(work => {
                                const {id, raw_address, due, urgent} = work
                                return <ListItem
                                    button
                                    key={id}
                                    onClick={() => selectWork(work)}
                                    selected={work.id === selectedWork?.id}
                                >
                                    <ListItemText
                                        secondary={`${urgent ? 'URGENT' : ''} Due ${moment(due).format('ll')}`}
                                        secondaryTypographyProps={{color: urgent ? 'error' : undefined}}
                                    >
                                        {raw_address}
                                    </ListItemText>
                                </ListItem>
                            })}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Card className={classes.card} elevation={4}>
                    <CardHeader
                        title="Today's visited work"
                        subheader="Completed or skipped work for the day. Click to select"
                    />
                    <CardContent>
                        <List>
                            {visitedWork.map(work => {
                                const {id, raw_address, due, status: {transition: {to_state}}, urgent} = work
                                return <ListItem
                                    button
                                    key={id}
                                    onClick={() => selectWork(work)}
                                    selected={work.id === selectedWork?.id}
                                >
                                    <ListItemText
                                        secondary={`${to_state} - ${urgent ? 'URGENT' : ''} Due ${moment(due).format('ll')}`}
                                        secondaryTypographyProps={{color: urgent ? 'error' : undefined}}
                                    >
                                        {raw_address}
                                    </ListItemText>
                                </ListItem>
                            })}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </>}
    </>
}