import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import React, {useEffect, useRef, useState} from "react";
import {DatePicker} from "@material-ui/pickers";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {ListItemIcon, ListItemSecondaryAction} from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(theme => ({
    filterCollapse: {
        paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
    },
}));

export function Filter({title, secondary, children}) {
    const classes = useStyles({});
    const [open, setOpen] = useState(false);

    return (
        <>
            <ListItem button onClick={() => setOpen(!open)} dense>
                <ListItemText primary={title} secondary={secondary}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterCollapse}>
                {children}
            </Collapse>
        </>
    )
}

export function FilterOptions({title, options, selected, setSelected}) {
    const isSelected = val => selected.indexOf(val) > -1;

    function toggleSelected(val) {
        const idx = selected.indexOf(val);
        const newSelected = selected.slice();
        if (idx > -1) {
            newSelected.splice(idx, 1);
        } else {
            newSelected.push(val);
        }
        setSelected(newSelected);
    }

    // Handles the case where the option is an object, as well as if it's a string
    const selectedOptions = []
    options.forEach(opt => {
        let {value, display} = opt
        if (typeof opt === "string" || opt instanceof String) {
            value = opt
            display = opt
        }
        if (isSelected(value)) {
            selectedOptions.push(display)
        }
    })

    return (
        <Filter title={title} secondary={selectedOptions.join(", ")}>
            <List dense>
                {options.map(opt => (
                    <FilterOption
                        option={opt}
                        isSelected={isSelected}
                        toggleSelected={toggleSelected}
                    />
                ))}
            </List>
        </Filter>
    )
}

export function FilterOption({option, isSelected, toggleSelected}) {
    let {value, display} = option
    if (typeof option === "string" || option instanceof String) {
        value = option
        display = option
    }
    return (
        <ListItem dense button onClick={() => toggleSelected(value)} key={value}>
            <Checkbox checked={isSelected(value)}/>
            <ListItemText primary={display}/>
        </ListItem>
    )
}

export function ContractorOption({title, selected, setSelected, userColors}) {
    const isSelected = val => selected.indexOf(val) > -1;

    function toggleSelected(val) {
        const idx = selected.indexOf(val);
        const newSelected = selected.slice();
        if (idx > -1) {
            newSelected.splice(idx, 1);
        } else {
            newSelected.push(val);
        }
        setSelected(newSelected);
    }

    return (
        <Filter title={title} secondary={selected.join(", ")}>
            <List dense>
                {Object.keys(userColors).map(opt => (
                    <ListItem dense button onClick={() => toggleSelected(opt)} key={opt}>
                        <ListItemIcon>
                            <RoomIcon htmlColor={userColors[opt]} fontSize="large"/>
                        </ListItemIcon>
                        <ListItemText primary={opt}/>
                        <ListItemSecondaryAction>
                            <Checkbox
                                checked={isSelected(opt)}
                                edge="end"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Filter>
    )
}

export function ToggleableDate({enabled, setEnabled, date, setDate, label, format}) {
    return (
        <ListItem button dense>
            <Checkbox checked={enabled} onClick={() => setEnabled(!enabled)}/>
            <DatePicker
                disabled={!enabled}
                showTodayButton
                label={label}
                format={format}
                value={date}
                onChange={setDate}
            />
        </ListItem>
    )
}

function datePickerText(start, end) {
    if (start && end) {
        if (start.isSame(end, 'day')) {
            return start.format("ll")
        }

        return `${start.format("ll")} thru ${end.format("ll")}`
    }

    if (start) {
        return `Since ${start.format("ll")}`
    }

    if (end) {
        return `Until ${end.format("ll")}`
    }
}

export function StartEndDatePicker({defaultEnabled, title, dates, setDates}) {
    // Keeps track of the last known (defined) start and end dates for the pickers
    const start = useRef(dates.start || new moment());
    const end = useRef(dates.end || new moment());
    const startEnabled = useRef(!!defaultEnabled)
    const endEnabled = useRef(!!defaultEnabled)

    function toggleStart(enabled) {
        startEnabled.current = enabled
        setDates({...dates, start: enabled ? start.current : undefined})
    }

    function toggleEnd(enabled) {
        endEnabled.current = enabled
        setDates({...dates, end: enabled ? end.current : undefined})
    }

    // Update the last known (defined) start and end dates
    useEffect(() => {
        if (dates.start) start.current = dates.start
        startEnabled.current = !!dates.start
        if (dates.end) end.current = dates.end
        endEnabled.current = !!dates.end
    }, [dates])

    return (
        <Filter title={title} secondary={datePickerText(dates.start, dates.end)}>
            <List dense>
                <ToggleableDate
                    label="Start date"
                    format="ll"
                    enabled={startEnabled.current}
                    setEnabled={toggleStart}
                    date={start.current}
                    setDate={d => setDates({...dates, start: d})}
                />
                <ToggleableDate
                    label="End date"
                    format="ll"
                    enabled={endEnabled.current}
                    setEnabled={toggleEnd}
                    date={end.current}
                    setDate={d => setDates({...dates, end: d})}
                />
            </List>
        </Filter>
    )
}