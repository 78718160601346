import {useEffect, useState} from "react";
import _ from "lodash";

export default function MapItems(google, works, onCreate, onRemove, canShow) {
    const [mapItems, setMapItems] = useState([]);

    function computeMapItems() {
        // Filter out and remove from the map items that we no longer have work for
        const existingItems = mapItems.filter(({work, mapItem}) => {
            // Map item's work no longer exists in the set of new work or it's not allowed to be shown anymore
            if (!works.find(w => _.isEqual(w, work)) || !canShow(work)) {
                onRemove(work, mapItem)
                return false
            }

            return true
        })

        // Create a list of new map items where the work doesn't exist in the existing items
        const newItems = works.reduce((accum, work) => {
            // Work doesn't exist in the map items so create it.
            if (!existingItems.find(m => _.isEqual(m.work, work)) && canShow(work)) {
                const mapItem = onCreate(work)
                accum.push({work, mapItem})
            }

            return accum
        }, [])

        setMapItems(existingItems.concat(newItems))
    }

    useEffect(() => {
        if (!google) return
        computeMapItems()
    }, [works, google])

    return [mapItems, computeMapItems]
}

MapItems.whyDidYouRender = true
