import {useEffect, useState} from "react";
import useLoading from "./useLoading";

export const useRoutingSettings = () => {
    const [{data: settings, loading, error}, setUsersFunc] = useLoading([], true);

    useEffect(() => {
        setUsersFunc(() => axios => {
            return axios
                .get(`/routing_settings/`)
                .then(res => res.data)
        });
    }, [setUsersFunc]);

    return {settings, loading, error}
};

export const useUpdateRoutingSetting = () => {
    const [{data: updated, loading, error}, submitFunc] = useLoading(undefined, true);
    const [setting, setSetting] = useState(null);

    useEffect(() => {
        if (!setting) {
            return
        }

        submitFunc(() => axios => {
            return axios
                .put(`/routing_settings/${setting.id}`, setting)
                .then(res => res.data)
        });

    }, [setting, submitFunc]);

    return [{updated, loading, error}, setSetting]
};
