import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {defaultUser, useCreateUpdateUser, useUsers} from "../api/users";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Fab from '@material-ui/core/Fab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import UserDialog from "../components/UserDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import {Role} from "../components/UserACL";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(4),
        height: '100%',
    },
    fab: {
        zIndex: 1,
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed',
    },
    fabIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function Users() {
    const classes = useStyles({});

    const {users, loading, error} = useUsers();
    const [open, setOpen] = useState(false);

    const [{newUser, loading: addLoading, error: addError}, submitFunc] = useCreateUpdateUser();
    const [user, setUser] = useState(defaultUser);

    const [listUsers, setListUsers] = useState(users);

    useEffect(() => {
        setListUsers(users.filter(({role}) => role !== Role.Script));
    }, [users, setListUsers]);

    useEffect(() => {
        if (!newUser || addLoading || addError) {
            return
        }

        const idx = listUsers.findIndex(({id}) => id === user.id);
        let newUsers = listUsers.slice();
        if (idx > -1) {
            newUsers[idx] = user
        } else {
            newUsers.push(newUser);
        }

        setListUsers(newUsers)
        setUser(defaultUser)
    }, [newUser, addLoading, addError, setListUsers]);

    function onDialogClose(user) {
        setOpen(false)

        if (!user) {
            setUser(defaultUser)
        } else {
            submitFunc(user)
        }
    }

    function editUser(user) {
        setUser(user);
        setOpen(true);
    }

    if (loading) {
        return <h2>Loading...</h2>
    }

    if (error) {
        const {response: {data: {message} = {}} = {}} = error;
        return <h1>An error has occurred: {message || String(error)}</h1>
    }

    return (
        <Container maxWidth="sm" style={{paddingTop: 16}}>
            <UserDialog
                onClose={onDialogClose}
                open={open}
                user={user}
                setUser={setUser}
            />
            <Fab variant="extended" className={classes.fab} color="secondary" onClick={() => setOpen(true)}>
                <PersonAddIcon className={classes.fabIcon}/>
                Add User
            </Fab>
            <Paper elevation={4}>
                <List subheader={<ListSubheader>Users</ListSubheader>}>
                    {listUsers.map((user, i) => (
                        <ListItemUser
                            key={i}
                            onClick={() => editUser(user)}
                            divider={i < listUsers.length - 1}
                            user={user}
                        />
                    ))}
                </List>
            </Paper>
        </Container>
    )
}

function ListItemUser({onClick, divider, user: {first_name, last_name, avatar, email, role, company, max_assigned_work, disabled}}) {
    return (
        <ListItem
            divider={divider}
            button
            onClick={onClick}
        >
            <ListItemAvatar>
                <Avatar alt={`${first_name} ${last_name}`} src={avatar}/>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <>
                        <Typography component="span" style={{marginRight: 8}}>
                            {first_name} {last_name}
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            {email}
                        </Typography>
                        {disabled && <Typography color="error" component="span" style={{marginLeft:8}}>
                            Disabled
                        </Typography>}
                    </>
                }
                secondary={
                    <Typography
                        component="span"
                        variant="body2"
                        style={{display: 'inline'}}
                        color="textPrimary"
                    >
                        {company} {role} {!!max_assigned_work && ` - ${max_assigned_work} jobs max`}
                    </Typography>
                }
            />
        </ListItem>
    )
}