import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import MapRoute from "../components/MapRoute";
import Box from "@material-ui/core/Box";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexGrow: 1,
        minHeight: 160,
        height: '100%',
    },
    map: {
        // Styles here are a workaround for GoogleMapReact component not having a predefined height
        minHeight: 240,
        height: '100%',
        width: '100%',
    },
    assignedUser: {
        margin: theme.spacing(0.5),
    },
    cardContents: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(1),
            marginLeft: 0,
        },
    },
    tooltip: {
        maxWidth: 400,
    }
}));

export default function RouteCard({route, todoWork, visitedWork}) {
    const classes = useStyles({});

    const total = todoWork.filter(work => !!work.leg).reduce((accum, work) => {
        accum.distance += work.leg.distance.value;
        accum.duration += work.leg.duration.value;
        return accum
    }, {distance: 0, duration: 0})

    // Meters to miles then round to one decimal place
    const distance = (total.distance * 0.000621371192).toFixed(0);
    const duration = Math.round(moment.duration(total.duration, 'seconds').asMinutes());

    const states = [...todoWork, ...visitedWork].reduce((accum, {status}) => {
        const {transition: {created_at, to_state}} = status;
        let state = to_state
        if (to_state === "Skipped") {
            // Check if it was skipped after midnight of the current day. If so, it's still assigned
            if (moment().isAfter(created_at, 'day')) {
                state = "Assigned"
            }
        }
        if (state === "Assigned") {
            state = "Remaining"
        }
        accum[state] = (accum[state] || 0) + 1
        return accum
    }, {})

    return (
        <Paper elevation={4} className={classes.paper}>
            <Box display="flex" flexDirection="column" width="100%">
                <Box p={2} pb={1}>
                    <div style={{display: 'flex'}}>
                        <Typography style={{flex: '1 1 auto'}}>
                            {todoWork.length === 0 ? <>
                                All work completed! Check back tomorrow.
                            </> : <>
                                Today's work
                            </>}
                        </Typography>
                        <Tooltip
                            style={{
                                flex: '0 0 auto',
                                alignSelf: 'flex-start',
                                padding: 0,
                            }}
                            classes={{tooltip: classes.tooltip}}
                            leaveTouchDelay={2500}
                            enterTouchDelay={0}
                            title={<>
                                <Typography>The number on each marker shows the order.</Typography>
                                <br />
                                <Typography><b>Colors</b></Typography>
                                <ul>
                                    <li><Typography>Red: The current route</Typography></li>
                                    <li><Typography>Blue: Your home location</Typography></li>
                                    <li><Typography>Green: Due today</Typography></li>
                                    <li><Typography>Grey: Not due today</Typography></li>
                                    <li><Typography>Orange: Skipped today</Typography></li>
                                    <li><Typography>Black: Canceled</Typography></li>
                                </ul>
                            </>}
                        >
                            <IconButton>
                                <HelpIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    {!!route && <Typography color="textSecondary">
                        {distance} miles ({duration} minutes driving)
                    </Typography>}
                    <Typography color="textSecondary">
                        {Object.keys(states).sort().map(s => `${states[s]} ${s}`).join(" - ")}
                    </Typography>
                </Box>
                <div className={classes.map}>
                    <MapRoute route={route} todoWork={todoWork} visitedWork={visitedWork}/>
                </div>
            </Box>
        </Paper>
    )
}