import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import {RedButton} from "./RedButton";

export default function({open, handleClose, submit}) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Delete transition?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the transition?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <RedButton onClick={submit}>Delete</RedButton>
            </DialogActions>
        </Dialog>
    )
}