import React, {useEffect} from "react";
import {Container, Typography} from "@material-ui/core";
import {useDeleteConstruction, useGetPendingConstruction} from "../api/construction";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import {Link as RouterLink} from "react-router-dom";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ConstructionDeleteDialog from "../components/ConstructionDeleteDialog";

const officeAddress = /3760\s*Interchange\s*Rd\.*[\s|]*Columbus,\s*OH\s*\d{3,}/gi;
const emailNotice1 = `E-MAIL CONFIDENTIALITY NOTICE:`;
const emailNotice2 = `[Charter_Email Signature_Logo]`;
const emailNotice3 = /The\s*contents\s*of\s*this\s*e-mail\s*message\s*and\s*any\s*attachments\s*are\s*intended\s*solely\s*for\s*the\s*addressee\(s\)\s*and\s*may\s*contain\s*confidential\s*and\/or\s*legally\s*privileged\s*information\.\s*If\s*you\s*are\s*not\s*the\s*intended\s*recipient\s*of\s*this\s*message\s*or\s*if\s*this\s*message\s*has\s*been\s*addressed\s*to\s*you\s*in\s*error,\s*please\s*immediately\s*alert\s*the\s*sender\s*by\s*reply\s*e-mail\s*and\s*then\s*delete\s*this\s*message\s*and\s*any\s*attachments\.\s*If\s*you\s*are\s*not\s*the\s*intended\s*recipient,\s*you\s*are\s*notified\s*that\s*any\s*use,\s*dissemination,\s*distribution,\s*copying,\s*or\s*storage\s*of\s*this\s*message\s*or\s*any\s*attachment\s*is\s*strictly\s*prohibited\./ig;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // maxWidth: '48ch',
        backgroundColor: theme.palette.background.paper,
    },
}));

function reduceBody(body) {
    return body.replace(/\[cid:.*?]/g, "").replace(emailNotice1, "").replace(emailNotice2, "").replace(emailNotice3, "").replace(officeAddress, "").trim()
}

export default function ConstructionList() {
    const classes = useStyles({});
    const {construction: pendingConstruction, loading, error} = useGetPendingConstruction();
    const [{data: deleteData, loading: deleteLoading, error: deleteError}, setDeleteId] = useDeleteConstruction();
    const [pendingDelete, setPendingDelete] = React.useState();

    const [construction, setConstruction] = React.useState([]);

    useEffect(() => {
        if (!pendingConstruction) return;
        setConstruction(pendingConstruction);
    }, [pendingConstruction, setConstruction]);

    useEffect(() => {
        if (!deleteData) return;

        const idx = construction.findIndex(c => c.id === pendingDelete);
        if (idx > -1) {
            const newConstruction = construction.slice();
            newConstruction.splice(idx, 1);
            setConstruction(newConstruction);
        }

        setPendingDelete(undefined);
    }, [deleteData, deleteError]);

    if (loading) {
        return <h2>Loading...</h2>
    }

    if (error) {
        return <h1>An error has occurred: {error}</h1>
    }

    return (
        <Container maxWidth="lg" style={{paddingTop: 16}} >
            <ConstructionDeleteDialog
                key="delete_dialog"
                open={!!pendingDelete}
                handleClose={() => setPendingDelete(undefined)}
                submit={() => setDeleteId(pendingDelete)}/>
            <Typography gutterBottom variant="h3">{construction.length} Pending Construction Orders</Typography>
            <Paper elevation={4}>
                <List className={classes.root}>
                    {construction.map(({subject, mail_date, attachments, body, id}) =>
                        <ListItem key={id} alignItems="flex-start" button component={RouterLink}
                                  to={`/construction/${id}`}>
                            {/*<ListItemAvatar>*/}
                            {/*    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />*/}
                            {/*</ListItemAvatar>*/}
                            <ListItemText
                                primary={subject}
                                secondary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            style={{display: 'block'}}
                                            color="textPrimary"
                                        >
                                            {moment(mail_date).format("lll")}
                                        </Typography>
                                        <span style={{whiteSpace: "pre-line"}}>
                                            {reduceBody(body).replace(/(\r?\n)+/g, "\n")}
                                        </span>
                                    </>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => setPendingDelete(id)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            </Paper>
            {/*<Grid container spacing={4}>*/}
            {/*    {construction.map(construction =>*/}
            {/*        <Grid item xs={12} md={6} xl={6} key={construction.id}>*/}
            {/*            <ConstructionCard construction={construction}/>*/}
            {/*        </Grid>*/}
            {/*    )}*/}
            {/*</Grid>*/}
        </Container>
    )
}