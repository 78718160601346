import React, {useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {Box, Collapse} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import UserChip from "../components/UserChip";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function RenderAssignment({className, assignment}) {
    const {
        id,
        created_at,
        assigned: {users},
        user: {first_name, last_name, avatar}
    } = assignment;

    return (
        <div className={className} key={id}>
            <ListItem dense disableGutters>
                <ListItemAvatar>
                    <Avatar alt={`${first_name} ${last_name}`} src={avatar}/>
                </ListItemAvatar>
                <ListItemText secondary={moment(created_at).format('lll')}>
                    {first_name} {last_name}
                    {users.length === 0 ? (
                        <> unassigned all users</>
                    ) : (
                        <> assigned {users.length} user{users.length !== 1 && "s"}</>
                    )}
                </ListItemText>
            </ListItem>
            {users.map(user => {
                return <ListItem dense key={user.id} disableGutters>
                    <ListItemText inset>
                        <UserChip user={user}/>
                    </ListItemText>
                </ListItem>
            })}
        </div>
    )
}

export default function AssignedCard({work,}) {
    const classes = useStyles({});
    const [expanded, setExpanded] = useState(false);

    const assignments = work.transitions.filter(({to_state}) => to_state === "Assigned")

    return (
        <Box p={2}>
            <Typography variant="h6">Assignments</Typography>
            {assignments.length > 0 && <List dense={true} disablePadding>
                    {/* Render the first assignment, if it exists */}
                    {assignments.length > 0 && (
                        <RenderAssignment key={`assignment_${assignments[0].id}`} assignment={assignments[0]}/>
                    )}

                    {/* If there's more assignments, render a list item with an expand icon */}
                    {assignments.length > 1 && (
                        <ListItem button onClick={() => setExpanded(!expanded)} key="previous_assignments" disableGutters>
                            <ListItemText>
                                <Typography variant="button" color="primary">Previous assignments</Typography>
                            </ListItemText>
                            {expanded ? <ExpandLess color="primary"/> : <ExpandMore color="primary"/>}
                        </ListItem>
                    )}

                    {/* Render the collapse panel with the remaining assignments */}
                    {assignments.length > 1 && (
                        <Collapse in={expanded} timeout="auto" unmountOnExit key="collapse_assignments">
                            <List dense={true}>
                                {assignments.slice(1).map((assignment, i) => {
                                    return (
                                        <React.Fragment key={`assignment_${assignment.id}`}>
                                            <RenderAssignment assignment={assignment}/>
                                            {i < assignments.length - 2 && (
                                                <Divider className={classes.divider} component="li"/>
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                            </List>
                        </Collapse>
                    )}
                </List>}
        </Box>
    );
}