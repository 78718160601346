const baseMapsUrl = "https://www.google.com/maps/dir/"

// TODO: Eventually we'd like to stop relying on a missing zip code to determine if we need to use lat/lng
export default function makeGoogleMapsURL({formatted, zip_code, point: {lat, lng}}) {
    let dest = formatted;
    if (!zip_code) {
        dest = `${lat},${lng}`
    }

    const params = new URLSearchParams();
    params.set("api", "1");
    params.set("travelmode", "driving");
    // Uncomment to automatically start navigating when opened
    // params.set("dir_action", "navigate");
    params.set("destination", dest);

    return `${baseMapsUrl}?${params.toString()}`
}