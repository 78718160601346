import React from "react";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

export default function UserLoading() {
    const classes = useStyles({})
    return (
        <Container style={{position: 'relative', width: '100%', height: '100%'}}>
            <div className={classes.loadingContainer}>
                <CircularProgress size={64}/>
            </div>
        </Container>
    )
}