import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {useAddToOUPS} from "../../api/work";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {DialogContentText} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

function generateWorkAreaDescription(work, bore) {
    // TODO: Add some extra logic here for ATT

    const {company, is_commercial, customer, address} = work
    const {name: customerName} = customer || {}
    const {lot} = address || {}

    let desc = `${bore ? "BORE" : "BURY"} from`

    if (company === "TW" && bore) {
        if (is_commercial) {
            desc += ` ${customerName} to SPECTRUM ped/pole/lockbox including all laterals and adjacent properties`
        } else {
            desc += ` residence to SPECTRUM ped/pole/lockbox including all laterals and adjacent properties`
        }
    } else {
        desc += " residence to ped/pole/lockbox including all laterals and adjacent properties"
    }

    if (bore) {
        if (company === "CLW" || company === "WOW") {
            desc += ` Driveway bore to Breezeline terminal`
        } else {
            desc += ` Driveway bore to ${company} terminal`
        }
    }

    let buffer = 300
    if (buffer < 1500 && !!lot && lot !== '') {
        buffer = 1500
    }

    desc += `\nFurthest distance off road ${buffer}ft`

    return desc
}

export default function OUPSSubmitDialog({work, onClose}) {
    const classes = useStyles();

    const [bore, setBore] = React.useState(work.bore)
    const [streetRange, setStreetRange] = React.useState(work.address?.number || '')
    const [workAreaDescription, setWorkAreaDescription] = React.useState(generateWorkAreaDescription(work, work.bore))
    const [descriptionChangedByUser, setDescriptionChangedByUser] = React.useState(false)

    const [{data: updatedWork, loading, error}, addToOUPS] = useAddToOUPS();

    React.useEffect(() => {
        updatedWork && onClose(updatedWork)
    }, [updatedWork])

    React.useEffect(() => {
        if (descriptionChangedByUser) {
            return
        }
        setWorkAreaDescription(generateWorkAreaDescription(work, bore))
    }, [work, bore, descriptionChangedByUser])

    function submit() {
        addToOUPS({
            id: work.id,
            bore,
            street_number: streetRange,
            work_area_description: workAreaDescription,
        })
    }

    return (
        <Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
            <DialogTitle>Submit OUPS</DialogTitle>
            <DialogContent>
                <Typography gutterBottom variant="h6">
                    {work?.raw_address}
                </Typography>
                <DialogContentText>
                    Customize the street number range and/or the entire work area description.
                    Street number can be a single number or a range e.g. 120-150.
                </DialogContentText>
                <div style={{position: 'relative'/*, minHeight: 80*/}}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>
                    )}
                    <FormControlLabel
                        style={{}}
                        control={
                            <Switch
                                checked={bore}
                                onChange={() => setBore(!bore)}
                                color="primary"
                            />
                        }
                        label="Bore"
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        value={streetRange}
                        onChange={e => setStreetRange(e.target.value)}
                        label="Street number or range"
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        rows={4}
                        variant="outlined"
                        value={workAreaDescription}
                        onChange={e => {
                            setDescriptionChangedByUser(true)
                            setWorkAreaDescription(e.target.value)
                        }}
                        multiline
                        label="Entire work area description"
                    />
                </div>
                {error && <Alert elevation={6} variant="filled" severity="error">
                    {error}
                </Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Dismiss</Button>
                <Button
                    color="primary"
                    onClick={submit}
                    disabled={loading}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}