import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import {useConstructionCreateWork, useDeleteConstruction, useSingleConstruction} from "../api/construction";
import {useHistory} from "react-router-dom";
import ConstructionAttachments from "../components/ConstructionAttachments";
import ConstructionEmail from "../components/ConstructionEmail";
import VisibleAttachmentsDialog from "../components/VisibleAttachmentsDialog";
import ConstructionDeleteDialog from "../components/ConstructionDeleteDialog";
import {RedButton} from "../components/RedButton";

const useStyles = makeStyles(theme => ({
    card: {
        // height: "100%",
    },
    cardContent: {
        padding: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
        // "&:last-child": {
        //     paddingBottom: theme.spacing(2)
        // }
    },
    autocompleteContents: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
        },
    },
}));

const filterOptions = createFilterOptions({
    stringify: option => option.value.toString(),
});

function makeOptions(field, attachments) {
    return attachments.reduce((accum, {filename, fields}) => {
        if (!fields || !fields[field]) return accum;
        const options = fields[field].map(it => {
            return {
                filename, value: it
            }
        });
        return accum.concat(options)
    }, []).sort((a, b) => {
        const filename = a.filename.localeCompare(b.filename);
        if (filename !== 0) {
            return filename
        }
        if (!isNaN(a.value) && !isNaN(b.value)) {
            if (a.value === b.value) return 0;
            return a.value < b.value ? 1 : -1;
        }
        return a.value.localeCompare(b.value);
    })
}

function FieldAutoComplete({label, type, required, multiple, options, selected, setSelected}) {
    const requiredError = required && (!selected || selected.length === 0);
    return (
        <Autocomplete
            value={selected}
            freeSolo
            multiple={multiple}
            autoSelect
            autoHighlight
            onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                    setSelected({
                        filename: "User entered text",
                        value: newValue.inputValue
                    });
                    return
                }
                setSelected(newValue);
            }}
            options={options}
            getOptionSelected={(a, b) => a.value === b.value}
            renderInput={params => {
                return <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    type={type}
                    required={required}
                    fullWidth
                    error={requiredError}
                    helperText={requiredError ? "Required" : null}
                />
            }}
            getOptionLabel={option => `${option.value}`}
            filterOptions={(options, params) => {
                const filtered = filterOptions(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        filename: "User entered text",
                        value: params.inputValue,
                    });
                }
                return filtered;
            }}
            groupBy={option => option.filename}
            // renderOption={(option, state) => {
            //     console.log("renderOption", {option, state});
            //     return <ListItemText key={option.value}>
            //         <Typography>{option.value}</Typography>
            //     </ListItemText>
            // }}
        />
    )
}

function filterAttachments(filter, attachments) {
    if (!filter) return attachments;

    return attachments.filter(({filename}) => {
        const lowerName = filename.toLowerCase();
        return lowerName.indexOf("cover") > -1 || lowerName.indexOf("ntp") > -1 || lowerName.indexOf("wo") > -1;
    });
}

function ConstructionFields({construction: {id, attachments}, visibleAttachments, classes: {card, cardContent}}) {
    const classes = useStyles({});
    const [{data: deleteData, loading: deleteLoading, error: deleteError}, setDeleteId] = useDeleteConstruction();

    const defaultFilter = true;
    const filtered = filterAttachments(defaultFilter, attachments);

    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);

    const [filter, setFilter] = React.useState(defaultFilter);
    const [needsOUPS, setNeedsOUPS] = React.useState(true);

    const [strings, setStrings] = React.useState(makeOptions("strings", filtered));
    const [integers, setIntegers] = React.useState(makeOptions("integers", filtered));
    // const [floatOptions, setFloatOptions] = React.useState([]);
    // const [timeOptions, setTimeOptions] = React.useState([]);

    // TODO: Can refactor like this https://2ality.com/2017/04/conditional-literal-entries.html
    const initialAddress = [];
    const street = strings.find(o => o.value.match(/\d+ (\w+( |$))+/));
    if (street) {
        initialAddress.push(street);
    }
    const cityState = strings.find(o => o.value.match(/\w+, \w{2} \d{5}/));
    if (cityState) {
        initialAddress.push(cityState);
    }

    const [address, setAddress] = React.useState(initialAddress);

    const [orderNumber, setOrderNumber] = React.useState(strings.find(o => o.value.match(/PO\d+/)));

    const [dueDate, setDueDate] = React.useState(null);
    const [prismId, setPrismId] = React.useState(integers.find(o => o.value > 800000 && o.value.toString().match(/\d{6,}/)));
    // Finding the contact name is unreliable as there are so many string options that match
    const [contactName, setContactName] = React.useState(/*strings.find(o => o.value.match(/(\w{2,}( |$)){2,5}/))*/);
    const [contactPhone, setContactPhone] = React.useState(strings.find(o => o.value.match(/\d{3}.*\d{3}.*\d{4}/)));
    const [technicianComments, setTechnicianComments] = React.useState("");

    const [{work, loading, error}, setRequest] = useConstructionCreateWork();
    const history = useHistory();

    // TODO: Handle loading and error

    useEffect(() => {
        if (!work) return;
        history.push(`/work/${work.id}`)
    }, [work, loading, error]);

    useEffect(() => {
        if (!deleteData) return;
        history.push("/construction");
    }, [deleteData, deleteLoading, deleteError]);

    useEffect(() => {
        const filtered = filterAttachments(filter, attachments);

        setStrings(makeOptions("strings", filtered));
        setIntegers(makeOptions("integers", filtered));
        // setFloatOptions(makeOptions("floats", filtered));
        // setTimeOptions(makeOptions("times", filtered));
    }, [filter, setStrings, setIntegers, attachments]);

    function handleSubmit() {
        // TODO: Validate the user has filled out all the required info.
        // Show a confirmation dialog when no attachments are marked as visible.
        if (visibleAttachments.length === 0) {
            setVisibleDialog(true);
            return;
        }

        submitConstruction();
    }

    function submitConstruction() {
        setVisibleDialog(false);

        const body = {
            address: address.map(it => it.value).join(' '),
            order_num: orderNumber.value.toString(),
            due: dueDate.format(),
            prism_id: prismId.value.toString(),
            contact_name: contactName.value.toString(),
            contact_phone: contactPhone.value.toString(),
            technician_comments: technicianComments.toString(),
            needs_oups: needsOUPS,
            visible_attachments: visibleAttachments,
        };

        setRequest({id, body})
    }

    function deleteConstruction() {
        setDeleteDialog(false);
        setDeleteId(id)
    }

    return (
        <Card raised={true} className={card}>
            <VisibleAttachmentsDialog
                open={visibleDialog}
                handleClose={() => setVisibleDialog(false)}
                submit={submitConstruction}/>
            <ConstructionDeleteDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                submit={deleteConstruction}/>
            <CardHeader
                title="Create Construction Work Order"
                subheader="Confirm the information below to create the work order. If the correct value is not suggested, it can be entered free-form. Be sure to also check plans and other attachments so they can be seen by contractors."
            />
            <CardContent className={clsx(cardContent, classes.autocompleteContents)}>
                <FormControlLabel
                    control={
                        <Switch checked={filter} onChange={() => setFilter(!filter)}
                                color="primary"/>
                    }
                    label="Suggest values from only cover letters, work orders, and NTPs"
                />
                <FieldAutoComplete
                    options={strings}
                    label="Address"
                    multiple
                    selected={address}
                    setSelected={setAddress}
                    required
                />
                <FieldAutoComplete
                    options={strings.concat(integers)}
                    label="Order Number"
                    selected={orderNumber}
                    setSelected={setOrderNumber}
                    required
                />
                {/*Date is weird, we use a different component for it and can't easily autocomplete*/}
                <KeyboardDatePicker
                    onChange={date => setDueDate(date)}
                    value={dueDate}
                    variant="inline"
                    autoOk
                    label="Due Date"
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY"
                    fullWidth
                    error={!dueDate}
                    helperText={!dueDate && "Due date is required"}
                />
                <FieldAutoComplete
                    type="number"
                    options={integers}
                    label="PRISM ID"
                    selected={prismId}
                    setSelected={setPrismId}
                    required
                />
                <FieldAutoComplete
                    options={strings}
                    label="Contact Name"
                    selected={contactName}
                    setSelected={setContactName}
                    required
                />
                <FieldAutoComplete
                    options={strings}
                    type="tel"
                    label="Contact Phone"
                    selected={contactPhone}
                    setSelected={setContactPhone}
                    required
                />
                <TextField
                    multiline
                    fullWidth
                    value={technicianComments}
                    onChange={e => setTechnicianComments(e.target.value)}
                    label="Technician comments"
                />
                <FormControlLabel
                    control={
                        <Switch checked={needsOUPS} onChange={() => setNeedsOUPS(!needsOUPS)} color="primary"/>
                    }
                    label="Needs OUPS locating"
                />
            </CardContent>
            <CardActions>
                <RedButton
                    variant="contained"
                    style={{margin: 8, marginLeft: 'auto'}}
                    onClick={() => setDeleteDialog(true)}
                >
                    Delete
                </RedButton>
                <Button
                    color="primary"
                    variant="contained"
                    style={{margin: 8}}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </CardActions>
        </Card>
    )
}

export default function Construction({match: {params: {constructionId}}}) {
    const classes = useStyles({});

    const [visibleAttachments, setVisibleAttachments] = React.useState([]);

    const [{construction, loading, error}] = useSingleConstruction(constructionId);

    if (error) {
        return <h1>An error has occurred: {error}</h1>
    }

    if (loading || !construction) {
        return <h2>Loading...</h2>
    }

    return (
        <Container maxWidth={false} style={{paddingTop: 16}}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <ConstructionEmail
                        construction={construction}
                        classes={classes}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <ConstructionFields
                        construction={construction}
                        visibleAttachments={visibleAttachments}
                        classes={classes}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <ConstructionAttachments
                        construction={construction}
                        visible={visibleAttachments}
                        setVisible={setVisibleAttachments}
                        classes={classes}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
