import React from "react";

export default function DrawingManager({google, onOverlayComplete, setDrawingManager, children}) {
    const ref = React.createRef();

    React.useEffect(() => {
        if (!google) return

        google.map.controls[google.maps.ControlPosition.LEFT_TOP].push(ref.current);

        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: false,
            polygonOptions: {
                geodesic: true,
            },
        });
        drawingManager.setMap(google.map)

        const listener = google.maps.event.addListener(drawingManager, 'overlaycomplete', onOverlayComplete)

        setDrawingManager(drawingManager)

        return () => {
            console.log("DrawingManager useEffect cleanup")
            listener.remove();
        }
    }, [google])

    return (
        <div ref={ref}>
            {children}
        </div>
    )
}