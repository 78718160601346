import {useEffect, useState} from "react";
import useLoading from "./useLoading";

export const useGetPendingConstruction = () => {
    const [{data: construction, loading, error}, setConstructionFunc] = useLoading(undefined, true);

    useEffect(() => {
        setConstructionFunc(() => axios => {
            return axios
                .get(`/construction/pending`)
                .then(res => res.data)
        });
    }, [setConstructionFunc]);

    return {construction, loading, error};
};

export const useSingleConstruction = (initialConstructionId) => {
    const [{data: construction, loading, error}, setConstructionFunc] = useLoading({}, !!initialConstructionId);
    const [constructionId, setConstructionId] = useState(initialConstructionId);

    useEffect(() => {
        setConstructionFunc(() => axios => {
            return axios
                .get(`/construction/${constructionId}`)
                .then(res => res.data)
        });
    }, [constructionId, setConstructionFunc]);

    return [{construction, loading, error}, setConstructionId];
};

export const useConstructionCreateWork = () => {
    const [{data: work, loading, error}, setCreateFunc] = useLoading(null, false);
    const [request, setRequest] = useState(null);

    useEffect(() => {
        if (!request) return;

        setCreateFunc(() => axios => {
            return axios
                .post(`/construction/${request.id}/createWork`, request.body)
                .then(res => res.data)
        })
    }, [request, setCreateFunc]);

    return [{work, loading, error}, setRequest]
};

export const useDeleteConstruction = () => {
    const [{data, loading, error}, setCreateFunc] = useLoading(null, false);
    const [id, setId] = useState(null);

    useEffect(() => {
        if (!id) return;

        setCreateFunc(() => axios => {
            return axios
                .delete(`/construction/${id}`)
                .then(res => res.data)
        })
    }, [id, setCreateFunc]);

    return [{data, loading, error}, setId]
};

export const useQueryConstruction = () => {
    const [{data: results, loading, error}, setQueryFunc] = useLoading({
        construction: [],
        totalResults: 0,
        pageCount: 0
    }, false);
    // {sortBy: [], filters: [], pageSize: 0, pageIndex: 0}
    const [args, setArgs] = useState(null);

    useEffect(() => {
        // When the hook is first used, queryArgs will be null so don't fetch any data until it's set
        if (!args) {
            return
        }

        const query = {
            filter: args.filters.reduce((accum, {id, value}) => {
                accum[id] = {"$like": `%${value}%`};
                return accum;
            }, {}),
            sort: args.sortBy.map(({id, desc}) => `${desc ? '-' : '+'}${id}`),
            limit: args.pageSize,
            offset: args.pageIndex * args.pageSize,
        };

        setQueryFunc(() => axios => {
            return axios
                .get(`/construction/?query=${new Buffer(JSON.stringify(query)).toString('base64')}`)
                .then(res => ({
                    construction: res.data.construction,
                    totalResults: res.data.total,
                    pageCount: Math.ceil(res.data.total / args.pageSize)
                }));
        });

    }, [args, setQueryFunc]);

    return [{results, loading, error}, setArgs]
};
