import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import AddressAutocomplete from "./AddressAutocomplete";
import {Role} from "./UserACL";
import {defaultUser} from "../api/users";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
    formItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        minWidth: 120,
    },
}));

export default function UserDialog({user = defaultUser, setUser, open, onClose}) {
    const classes = useStyles({});

    function handleSubmit() {
        onClose(user)
    }

    function handleClose() {
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
            <DialogTitle>{user === defaultUser ? "Add User" : "Edit User"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Enter a name, email address, and select a role to create a user</DialogContentText>
                <form>
                    <TextField
                        autoFocus
                        fullWidth
                        required
                        label="First name"
                        className={classes.formItem}
                        value={user.first_name}
                        onChange={event => {
                            const first_name = event.target.value;
                            setUser(user => ({...user, first_name}))
                        }}
                    />

                    <TextField
                        fullWidth
                        required
                        label="Last name"
                        className={classes.formItem}
                        value={user.last_name}
                        onChange={event => {
                            const last_name = event.target.value;
                            setUser(user => ({...user, last_name}))
                        }}
                    />

                    <TextField
                        fullWidth
                        required
                        className={classes.formItem}
                        label="Email address"
                        helperText="Corresponds to an existing Google account"
                        value={user.email}
                        onChange={event => {
                            const email = event.target.value;
                            setUser(user => ({...user, email}))
                        }}
                    />

                    {user.role === Role.Contractor && <>
                        <TextField
                            fullWidth
                            required
                            type="number"
                            className={classes.formItem}
                            label="Technician ID"
                            helperText="The technician's ID"
                            value={user.technician_id}
                            onChange={event => {
                                const technician_id = parseInt(event.target.value);
                                setUser(user => ({...user, technician_id}))
                            }}
                        />
                        <TextField
                            fullWidth
                            required
                            type="number"
                            className={classes.formItem}
                            label="Max assigned work"
                            helperText="Maximum number of jobs that can be automatically assigned"
                            value={user.max_assigned_work || 0}
                            onChange={event => {
                                const max_assigned_work = parseInt(event.target.value);
                                setUser(user => ({...user, max_assigned_work}))
                            }}
                        />
                    </>}

                    <AddressAutocomplete
                        fullWidth
                        required
                        className={classes.formItem}
                        label="Home address"
                        helperText="Home base for the user for routing purposes"
                        value={user.address}
                        onSelected={function (location) {
                            setUser(user => ({...user, ...location}))
                        }}
                    />

                    {user.role !== Role.Script && <FormControl className={classes.formItem}>
                        <FormLabel>Role</FormLabel>
                        <RadioGroup
                            row
                            value={user.role}
                            onChange={event => {
                                const role = event.target.value;
                                setUser(user => {
                                    // Set company and technician_id to null if user is not a contractor
                                    let company = null;
                                    let technician_id = null;
                                    if (role === Role.Contractor) {
                                        company = user.company
                                        technician_id = user.technician_id
                                    }
                                    return {...user, role, company, technician_id}
                                })
                            }}
                        >
                            <FormControlLabel value={Role.Contractor} control={<Radio/>} label={Role.Contractor}/>
                            <FormControlLabel value={Role.QualityControl} control={<Radio/>} label={Role.QualityControl}/>
                            <FormControlLabel value={Role.Manager} control={<Radio/>} label={Role.Manager}/>
                            <FormControlLabel value={Role.Admin} control={<Radio/>} label={Role.Admin}/>
                        </RadioGroup>
                    </FormControl>}

                    {user.role === Role.Contractor && <FormControl className={classes.formItem}>
                        <FormLabel>Company</FormLabel>
                        <RadioGroup
                            row
                            value={user.company}
                            onChange={event => {
                                const company = event.target.value;
                                setUser(user => ({...user, company}))
                            }}
                        >
                            <FormControlLabel value="WOW" control={<Radio/>} label="WOW"/>
                            <FormControlLabel value="TW" control={<Radio/>} label="TW"/>
                            <FormControlLabel value="ATT" control={<Radio/>} label="ATT"/>
                            <FormControlLabel value="CLW" control={<Radio/>} label="CLW"/>
                        </RadioGroup>
                    </FormControl>}

                    <FormControl
                        className={classes.formItem}>
                        <FormLabel>Disable the user's account (cannot login, no automatic routing)</FormLabel>
                        <Switch
                            checked={user.disabled}
                            onChange={() => setUser({...user, disabled: !user.disabled})}
                            color="primary"
                        />
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
