import GoogleMapReact from "google-map-react";
import React, {useState} from "react";
import {googleMapConfig, googleMapKey} from "../Config";
import {useAuth} from "../api/auth";
import markerUrl from "../util/markerUrl";
import moment from "moment";
import ReactDOM from "react-dom";
import {WorkInfoWindow} from "./WorkInfoWindow";

const infoWindowId = "infoWindowContent";

export default function MapRoute({route, todoWork, visitedWork}) {
    const [google, setGoogle] = useState(null);
    const {user} = useAuth();

    const [infoWindowComponent, setInfoWindowComponent] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [polylines, setPolylines] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [lineInterval, setLineInterval] = useState(-1);

    React.useEffect(() => {
        if (!google) return;

        polylines.forEach(line => line.setMap(null));
        markers.forEach(line => line.setMap(null));
        lineInterval && clearInterval(lineInterval)

        let bounds = new google.maps.LatLngBounds();

        if (visitedWork.length === 0) {
            if (route) {
                bounds = new google.maps.LatLngBounds(route.bounds.southwest, route.bounds.northeast)
            } else {
                todoWork.forEach(({address}) => {
                    if (address) {
                        bounds.extend(new google.maps.LatLng(address.lat, address.lng))
                    }
                })
            }
        }

        const newPolylines = todoWork.map((work, i) => {
            let color = "#000000";
            let opacity = 0.25;
            let icons = [];
            if (i === 0) {
                color = "#FF0000";
                opacity = 0.0;
                icons = [{
                    repeat: '6px',
                    icon: {
                        path: 'M 0,0 0,0',
                        strokeOpacity: 1,
                        scale: 4,
                    },
                }]
            }

            // Let each leg be it's own line rather than each step in the leg
            let route = [];
            work.leg && work.leg.steps.forEach(step => {
                const points = google.maps.geometry.encoding.decodePath(step.polyline.points);
                if (visitedWork.length > 0 && i === 0) {
                    points.forEach(point => bounds.extend(point))
                }
                route = route.concat(points);
            })

            const polyline = new google.maps.Polyline({
                map: google.map,
                path: route,
                strokeColor: color,
                strokeOpacity: opacity,
                strokeWeight: 4,
                geodesic: true,
                icons: icons,
            });

            if (i === 0) {
                let count = 0;
                const interval = setInterval(() => {
                    count = (count + 1) % 200;
                    const icons = polyline.get('icons');
                    if (icons && icons[0]) {
                        icons[0].offset = (count / 2) + 'px';
                        polyline.set('icons', icons);
                    }
                }, 40)
                setLineInterval(interval)
            }
            return polyline
        })

        setPolylines(newPolylines)

        const newInfoWindow = new google.maps.InfoWindow({
            content: `<div id="${infoWindowId}"/>`,
        });

        newInfoWindow.addListener("closeclick", () => {
            setInfoWindowComponent(null);
        });

        setInfoWindow(oldInfoWindow => {
            oldInfoWindow && oldInfoWindow.setMap(null);
            return newInfoWindow
        })

        const visitedMarkers = visitedWork.map(work => {
            const {
                address,
                route_order,
                status: {transition: {to_state}},
            } = work;

            const {lat, lng, formatted} = address || {lat: 0, lng: 0, formatted: work.raw_address}

            let color = "purple"
            switch (to_state) {
                case "Complete":
                    color = "white"
                    break;
                case "Skipped":
                    color = "orange"
                    break;
                case "Canceled":
                    color = "black"
                    break;
            }

            if (todoWork.length === 0) {
                bounds.extend({lat, lng})
            }

            const marker = new google.maps.Marker({
                position: {lat, lng},
                map: google.map,
                title: formatted,
                icon: markerUrl(color, route_order),
            });

            marker.addListener('click', () => {
                // This is necessary in order to prevent momentarily showing old content when a new window is clicked
                setInfoWindowComponent(null);

                // This is a neat trick to allow us to render React components inside a map InfoWindow. The InfoWindow
                // has a root element with id infoWindowContent which we can render into using ReactDOM but first
                // we need to listen for the InfoWindow's DOM to be ready hence the domready listener and render after.
                // Additionally, to get context passing in, we need to use ReactDOM.createPortal to create the component
                const listener = newInfoWindow.addListener("domready", function () {
                    console.log("infoWindow domready listener")
                    listener.remove();
                    const el = document.getElementById(infoWindowId);
                    const component = <WorkInfoWindow work={work}/>;
                    setInfoWindowComponent(ReactDOM.createPortal(component, el));
                });

                newInfoWindow.open(google.map, marker);
            });

            return marker;
        })

        const todoMarkers = todoWork.map((work, i) => {
            // TODO: Show info windows for current work?

            let now = moment()

            // If it's Friday, work is due counting all the way up until Sunday
            if (now.day() === 5) {
                now = now.endOf("week")
            }

            const due = now.isSameOrAfter(work.due, 'day');

            return new google.maps.Marker({
                position: work.address,
                map: google.map,
                icon: markerUrl(due ? "green" : "grey", work.route_order),
            });
        })

        const newMarkers = [...visitedMarkers, ...todoMarkers];

        newMarkers.push(new google.maps.Marker({
            position: user.location,
            map: google.map,
            opacity: 0.5,
            title: `${user.first_name} ${user.last_name}`,
            icon: markerUrl("blue")
        }))

        setMarkers(newMarkers);

        google.map.fitBounds(bounds)

    }, [google, todoWork, visitedWork]);

    return (
        <>
            {infoWindowComponent}
            <GoogleMapReact
                bootstrapURLKeys={googleMapConfig}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={setGoogle}
                defaultCenter={{lat: 0, lng: 0}}
                defaultZoom={14}
                options={maps => ({
                    // mapTypeControl: true,
                    // streetViewControl: true,
                })}
            />
        </>
    );
}