import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {Link} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import formatPhoneNumber from "../util/formatPhoneNumber";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Comments from "../components/Comments";
import CommentAttachmentDialog from "../components/CommentAttachmentDialog";
import {useSkipWork} from "../api/work";
import CompleteDialog from "../components/CompleteDialog";
import makeGoogleMapsURL from "../util/makeGoogleMapsURL";
import QualityDialog from "../components/QualityDialog";
import {useAuth} from "../api/auth";
import {Role} from "../components/UserACL";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RouterIcon from "@material-ui/icons/Router";
import CommentIcon from "@material-ui/icons/Comment";
import PersonIcon from "@material-ui/icons/Person";
import {Phone} from "@material-ui/icons";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventIcon from '@material-ui/icons/Event';
import Divider from "@material-ui/core/Divider";
import TimelineIcon from "@material-ui/icons/Timeline";
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexGrow: 1,
        minHeight: 160,
        height: '100%',
    },
    map: {
        // Styles here are a workaround for GoogleMapReact component not having a predefined height
        minHeight: 320,
        height: '100%',
        width: '100%',
    },
    buttonContainer: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    listIcon: {
        minWidth: 0,
        paddingRight: theme.spacing(2)
    }
}));

function CurrentWorkDetails({work, setCompleteOpen, setSkipOpen, setQualityOpen}) {
    const classes = useStyles({});

    const {user, error} = useAuth();

    return (
        <>
            <Grid container style={{flexGrow: 1}}>
                <Grid item xs={12} lg={6}>
                    <SimpleWorkInfo work={work} />
                        <Box className={classes.buttonContainer} p={2} pt={0} flexGrow={1} display="flex"
                             flexDirection="row" alignItems="flex-end">
                            {user.role === Role.QualityControl ?
                                <>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setQualityOpen(true)}
                                    >
                                        Submit QC
                                    </Button>
                                    <Button variant="outlined" component={RouterLink} to={`/work/${work.id}`}>
                                        Details
                                    </Button>
                                </>
                                :
                                <>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setCompleteOpen(true)}
                                    >
                                        Complete
                                    </Button>
                                    <Button variant="outlined" onClick={() => setSkipOpen(true)}>Skip</Button>
                                </>
                            }
                        </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default function CurrentWorkCard({work, onRemoveWork, onWorkChanged}) {
    const classes = useStyles({});

    const [completeOpen, setCompleteOpen] = React.useState(false);
    const [skipOpen, setSkipOpen] = React.useState(false);
    const [qualityOpen, setQualityOpen] = React.useState(false);

    if (!work) return null;

    return (
        <Paper elevation={4} className={classes.paper}>
            {completeOpen && <CompleteDialog
                onClose={newWork => {
                    setCompleteOpen(false)
                    onRemoveWork(newWork)
                }}
                work={work}
            />}
            {qualityOpen && <QualityDialog
                onClose={newWork => {
                    setCompleteOpen(false)
                    onRemoveWork(newWork)
                }}
                work={work}
            />}
            {skipOpen && <CommentAttachmentDialog
                apiFunc={useSkipWork}
                title="Skip work"
                description="Add a comment and optional photos/videos of why the work was skipped"
                submitText="Skip"
                onClose={newWork => {
                    setSkipOpen(false)
                    onRemoveWork(newWork)
                }}
                workId={work.id}
            />}
            <Grid container>
                <Grid item xs={12} lg={8} style={{display: 'flex', flexDirection: 'column'}}>
                    <CurrentWorkDetails
                        work={work}
                        setCompleteOpen={setCompleteOpen}
                        setSkipOpen={setSkipOpen}
                        setQualityOpen={setQualityOpen}
                    />
                    <Divider variant="fullWidth" />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Box p={2}>
                        <Typography variant="h6">Ticket Comments</Typography>
                        <Comments work={work} onComment={onWorkChanged}/>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}

function SimpleWorkInfo({work}) {
    const classes = useStyles()

    const {
        company,
        raw_address,
        technician_comments,
        order_num,
        customer: {name, home_phone},
        address,
        service_codes,
        drop_bury_length,
        primary_reason,
        is_commercial,
        due,
        oups,
        wire_type,
        urgent,
        duplicate_completions,
    } = work;

    return <Box p={2} pb={0}>
        <Typography variant="h6">
            {address ? <Link href={makeGoogleMapsURL(address)} target="_blank">
                {address.formatted}
            </Link> : raw_address}
        </Typography>

        <List dense disablePadding>
            {!!duplicate_completions && duplicate_completions.length > 0 && <ListItem key="investigate" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <WarningIcon color="error"/>
                </ListItemIcon>
                <ListItemText>
                    <Typography color="error" variant="h6">
                        Investigation required. Drop was buried less than 90 days ago. Investigate why the drop isn't working.
                    </Typography>
                </ListItemText>
            </ListItem>}

            {due && <ListItem key="due" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <EventIcon/>
                </ListItemIcon>
                <ListItemText>
                    Due {moment(due).format("ddd ll")}
                </ListItemText>
            </ListItem>}

            <ListItem key="building_type" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <BusinessIcon/>
                </ListItemIcon>
                <ListItemText>
                    {is_commercial ? "Commercial" : "Residential"}
                </ListItemText>
            </ListItem>

            <ListItem key="order_num" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText>
                    {company} order number {order_num}
                </ListItemText>
            </ListItem>

            {wire_type && <ListItem key="wire_type" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <RouterIcon/>
                </ListItemIcon>
                <ListItemText>
                    Wire type {wire_type}
                </ListItemText>
            </ListItem>}

            {drop_bury_length && <ListItem key="drop_bury_length" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <TimelineIcon/>
                </ListItemIcon>
                <ListItemText>
                    Drop bury length {drop_bury_length}
                </ListItemText>
            </ListItem>}

            {primary_reason && <ListItem key="primary_reason" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <CommentIcon/>
                </ListItemIcon>
                <ListItemText>
                    Primary reason {primary_reason}
                </ListItemText>
            </ListItem>}

            {service_codes && <ListItem key="service_codes" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <RouterIcon/>
                </ListItemIcon>
                <ListItemText color={urgent ? "error" : undefined}>
                    Service codes {service_codes}
                </ListItemText>
            </ListItem>}

            <ListItem key="customer_name" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <PersonIcon/>
                </ListItemIcon>
                <ListItemText>
                    {name}
                </ListItemText>
            </ListItem>

            {home_phone && <ListItem key="customer_phone" disableGutters>
                <ListItemIcon className={classes.listIcon}>
                    <Phone/>
                </ListItemIcon>
                <ListItemText>
                    <Link href={`tel:${home_phone}`}>{formatPhoneNumber(home_phone)}</Link>
                </ListItemText>
            </ListItem>}

            {technician_comments.map(({id, message}) => (
                <ListItem key={`comment_${id}`} disableGutters>
                    <ListItemIcon className={classes.listIcon}>
                        <CommentIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        {message}
                    </ListItemText>
                </ListItem>
            ))}

            {(oups || []).map((o, i) => {
                if (!o.work_date || !o.start_by_date) return null
                if (moment().isAfter(o.start_by_date)) return null
                return <OUPS oups={o} key={`oups_${i}`} />
            })}
        </List>
    </Box>
}

function OUPS({oups}) {
    const classes = useStyles()

    return <ListItem disableGutters>
        <ListItemIcon className={classes.listIcon}>
            <ScheduleIcon/>
        </ListItemIcon>
        <ListItemText secondary={
            <>{oups.type} at {moment(oups.created_at).format('lll')}</>
        }>
            OUPS good {moment(oups.work_date).format('ddd MMM Do')} to {moment(oups.start_by_date).format('ddd MMM Do')}
        </ListItemText>
    </ListItem>
}