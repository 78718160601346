import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import getFontAwesomeIconFromMIME from "../util/fontAwesomeIcon";
import ListItemText from "@material-ui/core/ListItemText";
import humanFileSize from "../util/humanFileSize";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachmentDialog from "./AttachmentDialog";

const useStyles = makeStyles(theme => ({
    fileIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        fontSize: 32,
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        "-ms-transform": "translateY(-50%)",
        transform: "translateY(-50%)",
    },
    attachment: {
        wordWrap: 'break-word',
    },
    attachment_indent: {
        paddingLeft: theme.spacing(8),
        wordWrap: 'break-word',
    },
}));

export default function Attachment({url, type, size, name, onDelete, indent, onClick}) {
    const classes = useStyles({});
    const preview_url = type.indexOf("image/") === 0 ? url : null
    return (
        <ListItem
            dense
            button
            className={indent ? classes.attachment_indent : classes.attachment}
            component="a"
            href={!onClick ? url : undefined}
            target="_blank"
            onClick={onClick}
        >
            <ListItemAvatar>
                <Avatar
                    src={preview_url}
                    variant="square"
                    className={classes.fileIcon}
                >
                    <Icon className={`${classes.icon} fa ${getFontAwesomeIconFromMIME(type)}`}/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={name}
                secondary={humanFileSize(size, true)}
            />
            {onDelete && <ListItemSecondaryAction>
                <IconButton edge="end" onClick={onDelete}>
                    <DeleteIcon/>
                </IconButton>
            </ListItemSecondaryAction>}
        </ListItem>
    )
}