import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import Attachment from "./Attachment";
import Divider from "@material-ui/core/Divider";
import {Typography} from "@material-ui/core";
import MuiLinkify from "material-ui-linkify";
import AttachmentDialog from "./AttachmentDialog";

export default function Comment({comment, divider, onToggleExpand, isExpanded, disableGutters}) {
    const [selectedAttachment, setSelectedAttachment] = React.useState()

    const {created_at, message, attachments, user: {first_name, last_name, avatar}} = comment;
    return (
        <>
            {divider && <Divider/>}
            <ListItem
                button={onToggleExpand && attachments.length > 0}
                onClick={() => onToggleExpand && onToggleExpand()}
                disableGutters={disableGutters}
            >
                <ListItemAvatar>
                    <Avatar alt={`${first_name} ${last_name}`} src={avatar}/>
                </ListItemAvatar>
                <ListItemText
                    style={{whiteSpace: 'pre-line'}}
                    primary={
                        <MuiLinkify>
                            <Typography component="span">
                                {message}
                            </Typography>
                        </MuiLinkify>
                    }
                    secondary={`${first_name} ${last_name} - ${moment(created_at).format('lll')}`}
                />
                {onToggleExpand && attachments.length > 0 && (
                    isExpanded ? <ExpandLess/> : <ExpandMore/>
                )}
            </ListItem>
            {selectedAttachment && <AttachmentDialog attachment={selectedAttachment} setAttachment={setSelectedAttachment} />}
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                {attachments.map(attachment => {
                        return <Attachment
                            indent
                            key={attachment.id}
                            url={attachment.data_url}
                            type={attachment.content_type}
                            size={attachment.size}
                            name={attachment.caption || attachment.filename}
                            onClick={() => setSelectedAttachment(attachment)}
                        />
                    }
                )}
            </Collapse>
        </>
    )
}