import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import BuildIcon from "@material-ui/icons/Build";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function BillingItem({item, setItem, onDelete}) {
    return (
        <ListItem>
            <ListItemIcon>
                <BuildIcon/>
            </ListItemIcon>
            <ListItemText
                primary={item.unit ?
                    <TextField
                        label={item.name}
                        type="number"
                        value={item.quantity}
                        onChange={ev => setItem({...item, quantity: parseInt(ev.target.value) || ""})}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{item.unit}</InputAdornment>
                        }}
                    />
                    : item.name
                }
                secondary={item.description}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onDelete(item)}>
                    <DeleteIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}