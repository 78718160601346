import {Dialog, TextField, useMediaQuery} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "@fortawesome/fontawesome-free/css/all.css";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import AddAttachments from "./AddAttachments";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

export default function CommentAttachmentDialog({onClose, title, description, submitText, requireAttachment, apiFunc, workId}) {
    const classes = useStyles();
    const [userComment, setUserComment] = React.useState('');
    const [attached, setAttached] = React.useState([]);
    const [{work, loading, error}, submitReq] = apiFunc();

    // Called when the complete button is clicked
    async function onComplete() {
        submitReq({
            workId,
            message: userComment,
            attachments: await Promise.all(attached.map(async a => ({
                filename: a.name,
                data: await a.toBase64(),
            }))),
        });
    }

    // Once the API responds, call onClose with the newly returned work
    React.useEffect(() => {
        work && onClose(work)
    }, [work, onClose]);

    return (
        <Dialog
            fullScreen={useMediaQuery(useTheme().breakpoints.down('sm'))}
            open={true}
            onClose={() => onClose()}
            fullWidth={true}
            maxWidth="sm"
            disableBackdropClick
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                <List>
                    <div style={{position: 'relative'}}>
                        {loading && <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>}
                        <ListItem disableGutters key="add_comment">
                            <TextField
                                variant="outlined"
                                multiline
                                fullWidth
                                value={userComment}
                                onChange={ev => setUserComment(ev.target.value)}
                                label="Add your comment"
                            />
                        </ListItem>
                        <AddAttachments
                            id="complete-file-upload"
                            accept="image/*;capture=camera"
                            label="Attach files"
                            attached={attached}
                            setAttached={setAttached}
                        />
                    </div>
                </List>
                {error && <Alert elevation={6} variant="filled" severity="error">
                    <AlertTitle>{submitText} Error</AlertTitle>
                    {error}
                </Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    Dismiss
                </Button>
                <Button
                    color="primary"
                    onClick={onComplete}
                    disabled={!userComment || (requireAttachment && attached.length === 0)}
                >
                    {submitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}