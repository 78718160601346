import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import OUPSMap from "../OUPSMap";

function parseSuggestion(suggestion) {
    let {
        group,
        range,
        street: {place, name: streetName},
        county: {county, place: countyPlace},
        suggestion: {name},
        distance_miles
    } = suggestion
    const streetNum = streetName.match(/^\d+/)?.[0] || range;

    return {
        group,
        distance_miles,
        primary: `${streetNum} ${name} ${place}`,
        secondary: `${countyPlace} - ${county} COUNTY`,
    }
}

export default function OUPSSuggestions({address, selected, setSelected, suggestions}) {
    if (suggestions.length === 0) {
        return <Typography variant="h6" color="error">No suggestions found</Typography>
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={4} style={{overflow: 'auto', maxHeight: 440}}>
                <Typography variant="h6">{suggestions.length} Potential Matches</Typography>
                <List>
                    {suggestions.map((s, i) => {
                        const {group, primary, secondary, distance_miles} = parseSuggestion(s);
                        return (
                            <ListItem
                                key={i}
                                button
                                onClick={() => setSelected(s)}
                                selected={selected === s}
                                divider
                            >
                                <ListItemText
                                    primary={<>
                                        <Typography
                                            color="primary"
                                            component="span"
                                            display="block"
                                        >
                                            {group} match - {Math.round(distance_miles * 100) / 100} mi
                                        </Typography>
                                        <Typography component="span">
                                            {primary}
                                        </Typography>
                                    </>}
                                    secondary={secondary}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
            {selected && <Grid item xs={12} lg={8} style={{minHeight: 480}}>
                <OUPSMap
                    address={address}
                    suggestion={selected}
                />
            </Grid>}
        </Grid>
    )
}