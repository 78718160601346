import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function VisibleAttachmentsDialog({open, handleClose, submit}) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create work order without visible attachments?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    It may be necessary for contractors to view plans and other important documents.
                    If attachments need to be marked as visible - click dismiss, mark one or more attachments as
                    visible, and try again. If this is intentional, click submit.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Dismiss</Button>
                <Button onClick={submit} color="primary">Create</Button>
            </DialogActions>
        </Dialog>
    )
}
