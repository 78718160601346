import GoogleMapReact from "google-map-react";
import React, {useState} from "react";
import {googleMapConfig} from "../Config";

export default function OUPSMap({address, suggestion, defaultZoom = 16}) {
    const [google, setGoogle] = useState(null);
    const [addressMarker, setAddressMarker] = useState(null);
    const [pointMarker, setPointMarker] = useState(null);
    const [line, setLine] = useState(null);

    React.useMemo(() => {
        if (!google || !google.maps) return;

        addressMarker && addressMarker.setMap(null)
        pointMarker && pointMarker.setMap(null)
        line && line.setMap(null)

        const {lat, lng, formatted} = address;
        const bounds = new google.maps.LatLngBounds();

        // A marker for the (Google) geocoded address
        const newAddressMarker = new google.maps.Marker({
            position: {lat, lng},
            map: google.map,
            title: formatted,
        })

        setAddressMarker(newAddressMarker)
        bounds.extend(newAddressMarker.position)

        // If there's only one point, use a marker instead of a polyline
        if (suggestion.points.length === 1) {
            const newAddressMarker = new google.maps.Marker({
                position: {...suggestion.points[0]},
                map: google.map,
                title: formatted,
            })

            setPointMarker(newAddressMarker)
        } else {
            const newLine = new google.maps.Polyline({
                map: google.map,
                path: suggestion.points,
                strokeColor: "#FF0000",
                strokeOpacity: 0.9,
                strokeWeight: 8,
                geodesic: true,
            });

            setLine(newLine)
        }

        suggestion.points.forEach(point => bounds.extend(point))
        google.map.fitBounds(bounds)
    }, [google, address, suggestion]);

    return (
        <GoogleMapReact
            bootstrapURLKeys={googleMapConfig}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={setGoogle}
            center={address}
            defaultZoom={defaultZoom}
            options={maps => ({
                mapTypeId: 'hybrid',
                mapTypeControl: true,
                streetViewControl: true,
                gestureHandling: 'greedy',
            })}
        />
    );
}