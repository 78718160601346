import {Link, Typography} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import UserChip from "./UserChip";
import moment from "moment";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    infoWindow: {},
    b: {
        display: "inline-block",
        width: 100,
    },
    assignedUser: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

// TODO: Add status
export function WorkInfoWindow({work}) {
    const classes = useStyles({});
    const {
        id,
        company,
        order_num,
        raw_address,
        address: {
            formatted
        } = {},
        created_at,
        entered,
        due,
        assigned_user,
        transitions,
        status,
        oups,
        urgent,
        skip_reason,
    } = work;

    const [{
        work_date,
        start_by_date,
        ticket,
        error
    } = {}] = oups || []

    // Array and object destructuring with defaults to get the most recent array of assigned users, empty if none
    const [{assigned: {users: assigned_users = []} = {}} = {}] = (transitions || []).filter(({to_state}) => to_state === "Assigned");

    const [{created_at: completed_at} = {}] = (transitions || []).filter(({to_state}) => to_state === "Complete");

    return (
        <div className={classes.infoWindow}>
            <Link component={RouterLink} to={`/work/${id}`} variant="h6">
                {company} Order {order_num}
            </Link>

            <Typography variant="subtitle2">{formatted || raw_address}</Typography>

            {status && assigned_users.map(a => <UserChip key={a.id} user={a} className={classes.assignedUser}/>)}

            {assigned_user && <Typography>
                <b className={classes.b}>User:</b> {assigned_user}
            </Typography>}

            {urgent && <Typography color="error">
                <b className={classes.b}>Urgent</b>
            </Typography>}

            {skip_reason && <Typography color="error">
                <b className={classes.b}>Not routed:</b> {skip_reason}
            </Typography>}

            {due && <Typography>
                <b className={classes.b}>Due:</b> {moment(due).utc().format('ll')}
            </Typography>}

            <Typography>
                <b className={classes.b}>Created:</b> {moment(created_at).format('lll')}
            </Typography>

            <Typography>
                <b className={classes.b}>Entered:</b> {moment(entered).utc().format('ll')}
            </Typography>

            {completed_at && <Typography>
                <b className={classes.b}>Completed:</b> {moment(completed_at).format('lll')}
            </Typography>}

            {ticket && <Typography>
                <b className={classes.b}>OUPS:</b> {ticket}
            </Typography>}

            {error && <Typography color="error">
                <b className={classes.b}>OUPS Error:</b> {error}
            </Typography>}

            {work_date && <Typography>
                <b className={classes.b}>Work Date:</b> {moment(work_date).format('lll')}
            </Typography>}

            {start_by_date && <Typography>
                <b className={classes.b}>Start By:</b> {moment(start_by_date).format('lll')}
            </Typography>}

        </div>
    )
}