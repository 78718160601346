import moment from "moment";
import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import {useQueryWork} from "../api/work";
import Table, {DateRangeFilter, MultiSelectFilterFactory} from "../components/Table";
import UserChip from "../components/UserChip";
import {makeStyles} from "@material-ui/core/styles";
import {Container, Link} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import WorkMenu from "../components/WorkMenu";
import {Status} from "../const/status";
import MapSingle from "../components/MapSingle";
import IconButton from "@material-ui/core/IconButton";
import {ExpandLess, ExpandMore} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    assigned: {
        marginTop: theme.spacing(0.25),
        marginBottom: theme.spacing(0.25),
    },
    tableContainer: {
        width: '100%',
        overflowX: 'auto',
        height: 'calc(100% - 48px)',
        // paddingTop: 16,
        // paddingBottom: 16,
    },
    // mapContainer: {
    //     height: 320,
    //     margin: -16,
    // }
}));

function hueFromHours(hours) {
    if (hours < 0) {
        return 0 // 100% red
    }
    if (hours > (5 * 24)) {
        return 120 // 100% green
    }

    return hours // Somewhere in between
}

function dateCell(justDate) {
    return input => {
        return React.useMemo(() => {
            const {cell: {value}} = input;
            if (!value) {
                return "";
            }
            return moment(value).format(justDate ? 'll' : 'lll');
        }, [input, justDate])
    }
}

function colorDateCell({value}) {
    if (!value) {
        return null
    }
    const diff = moment(value).diff(moment());
    const hoursFromNow = moment.duration(diff).as('hours');
    return {
        backgroundColor: `hsl(${hueFromHours(hoursFromNow)}, 100%, 72%)`
    };
}

function assignedAccessor({transitions}) {
    const assignments = transitions.filter(({to_state}) => to_state === "Assigned")
    const [{assigned: {users = []} = {}} = {}] = assignments;
    return {assigned: users};
}

function AssignedCell({cell: {value}}) {
    const classes = useStyles();

    if (!value) {
        return null;
    }
    const {assigned} = value;
    return (<>
        {assigned.map(assigned => {
            return <UserChip className={classes.assigned} key={assigned.id} user={assigned}/>
        })}
    </>);
}

function MenuCell(setReloadData) {
    return function ({row: {original}}) {
        return <WorkMenu
            id={original.id}
            // onWorkChanged={() => setReloadData(true)}
        />
    }
}

function IDCell({cell: {value}}) {
    return <Link component={RouterLink} to={`/work/${value}`}>
        <b>{value}</b>
    </Link>
}

function RowNumberCell({row: {index}, state: {pageSize, pageIndex}}) {
    return index + 1 + (pageSize * pageIndex)
}

function oupsAccessor({oups}) {
    if (oups.length === 0) {
        return null
    }
    return oups[0];
}

function OUPSCell({cell: {value}}) {
    if (!value) {
        return null
    }
    const {error, type, ticket, updated_at} = value;
    return (
        <span>
            <div>
                <b>{type}: </b>
                {ticket && (
                    ticket
                )}
                {error && (
                    <Typography color="error" display="inline" variant="inherit">Error</Typography>
                )}
            </div>
            <div>{moment(updated_at).format('lll')}</div>
        </span>
    )
}

function DueCell({row: {original: {entered, due}}}) {
    return <Grid container direction="column">
        <Grid container item>
            <Grid item xs>
                <b>Due</b>
            </Grid>
            <Grid item>
                {due == null ? "MISSING" : moment(due).format('ll')}
            </Grid>
        </Grid>
        <Grid container item>
            <Grid item xs>
                <b>Entered</b>
            </Grid>
            <Grid item>
                {entered == null ? "MISSING" : moment(entered).format('ll')}
            </Grid>
        </Grid>
    </Grid>
}

function BillingTypeCell({cell: {value}}) {
    return !value ? "Residential" : "Commercial"
}

function CommentsCell({cell: {value}}) {
    return <>{(value || []).map(({message}) => message)}</>;
}

function ExpanderHeader({getToggleAllRowsExpandedProps, isAllRowsExpanded}) {
    return <span {...getToggleAllRowsExpandedProps()} >
        <IconButton size="small">
            {isAllRowsExpanded ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
    </span>
}

function ExpanderCell({row: {isExpanded, getToggleRowExpandedProps}}) {
    return <span {...getToggleRowExpandedProps()} >
        <IconButton size="small">
            {isExpanded ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
    </span>
}

function NullHeader() {
    return null;
}

export default function WorkTable() {
    const classes = useStyles();
    const [reloadData, setReloadData] = React.useState(false);
    const [{results: {work, totalResults, pageCount} = {}, loading, error}, queryWork, forceLoadData] = useQueryWork();

    React.useEffect(() => {
        reloadData && forceLoadData()
        setReloadData(false)
    }, [reloadData, setReloadData, forceLoadData])

    const columns = React.useMemo(() => ([
            {
                id: 'expander',
                width: 48,
                Header: ExpanderHeader,
                Cell: ExpanderCell,
            },
            {
                id: 'menu',
                width: 90,
                Header: NullHeader,
                Cell: MenuCell(setReloadData)
            },
            {
                Header: '#',
                id: 'row_number',
                Cell: RowNumberCell,
                Filter: '',
                width: 40,
            },
            {
                Header: 'ID',
                accessor: 'id',
                Cell: IDCell,
                Filter: '',
                width: 60,
            },
            {
                Header: 'Company',
                accessor: 'company',
                width: 100,
                Filter: MultiSelectFilterFactory(["ATT", "TW", "WOW", "CLW"])
            },
            {
                Header: 'Order #',
                accessor: 'order_num',
                width: 160,
            },
            {
                Header: 'Job #',
                accessor: 'job_number',
                width: 120,
            },
            {
                Header: 'Subaccount #',
                accessor: 'subaccount_number',
                width: 160,
            },
            {
                Header: 'Status',
                accessor: 'status.transition.to_state',
                width: 160,
                Filter: MultiSelectFilterFactory(Object.values(Status))
            },
            {
                Header: 'Assigned',
                // There is a workaround on the backend query service for this field to be sorted and filtered on
                id: 'assigned_user',
                accessor: assignedAccessor,
                Cell: AssignedCell,
                width: 180,
            },
            {
                Header: 'Created',
                accessor: 'created_at',
                Cell: dateCell(false),
                Filter: DateRangeFilter,
                width: 200,
            },
            {
                Header: 'Last Received',
                accessor: 'last_received',
                Cell: dateCell(false),
                Filter: DateRangeFilter,
                width: 200,
            },
            {
                Header: 'Latest OUPS',
                disableSortBy: true,
                width: 200,
                accessor: oupsAccessor,
                Cell: OUPSCell
            },
            {
                Header: 'Due',
                accessor: 'due',
                width: 180,
                getCellStyle: colorDateCell,
                Cell: DueCell,
                Filter: DateRangeFilter,
            },
            {
                Header: "Service Codes",
                accessor: "service_codes",
                width: 160,
            },
            {
                Header: "Billing Type",
                accessor: "is_commercial",
                Cell: BillingTypeCell,
                Filter: MultiSelectFilterFactory({true: "Commercial", false: "Residential"})
            },
            {
                Header: 'Address',
                disableSortBy: true,
                accessor: 'raw_address',
                width: 200,
            },
            {
                Header: 'Drop Bury Length',
                accessor: 'drop_bury_length',
                width: 180,
                Filter: MultiSelectFilterFactory(["", "0-150 FT", "151-250 FT", "251-400 FT", ">400 FT", "401-600 FT", "601-800 FT", "801-1000 FT", ">1000 FT"])
            },
            {
                Header: 'Primary reason',
                accessor: 'primary_reason',
                Filter: MultiSelectFilterFactory(["", "BORE", "DROP BURY REQD", "PRIORITY BORE"]),
                width: 180,
            },
            {
                Header: 'Customer Account',
                disableSortBy: true,
                accessor: 'customer.account',
                // width: 200,
            },
            {
                Header: 'Technician Comments',
                Filter: '',
                disableSortBy: true,
                width: 320,
                accessor: 'technician_comments',
                Cell: CommentsCell,
            },
        ]),
        []
    );

    function fetchData(props) {
        const {filters, sortBy, pageSize, pageIndex} = props;
        queryWork({
            query: {
                filter: filters.reduce((accum, {id, value}) => {
                    if (typeof value === "string" || value instanceof String) {
                        accum[id] = {"$like": `%${value}%`};
                    } else if (Array.isArray(value)) {
                        // Replacement for not having "IN" support
                        accum["$and"] = (accum["$and"] || []).concat([{
                            "$or": value.map(el => {
                                // TODO: Booleans in a select are a bit weird. Can probably do this better
                                if (el === "true" || el === "false") {
                                    el = el === "true"
                                }
                                return {[id]: el}
                            })
                        }])
                    } else if (typeof value === "object" && (value.start || value.end)) {
                        accum[id] = {
                            "$gt": value.start,
                            "$lt": value.end,
                        };
                    } else {
                        accum[id] = value
                    }
                    return accum;
                }, {}),
                sort: sortBy.map(({id, desc}) => `${desc ? '-' : '+'}${id}`),
                limit: pageSize,
                offset: pageIndex * pageSize,
            }
        })
    }

    return (
        <Container disableGutters maxWidth={false} className={classes.tableContainer}>
            <Table
                columns={columns}
                data={work}
                totalResults={totalResults}
                pageCount={pageCount}
                fetchData={fetchData}
                loading={loading}
                error={error}
                renderRowSubComponent={WorkDetails}
                getRowProps={getRowProps}
            />
        </Container>
    )
}

const getRowProps = row => ({
    // onClick: (e) => {
    //     // Handles navigation to the individual work page from clicking on a row
    //     history.push(`/work/${row.original.id}`)
    // },
    style: {
        background: row.index % 2 === 0 ? 'rgba(0,0,0,.075)' : 'white',
    },
})

const mapContainerStyles = {
    height: 480,
    margin: -16,
}

function WorkDetails({row: {original: work}}) {
    // const classes = useStyles();
    const {geocode_error, address, oups} = work;

    const workArea = ((oups || []).find(o => o.work_area) || {}).work_area;

    if (geocode_error) {
        return <Typography color="error">{geocode_error}</Typography>
    }

    return (
        <div style={mapContainerStyles}>
            <MapSingle
                address={address}
                workArea={workArea}
                gestureHandling="cooperative"
                defaultZoom={19}
            />
        </div>
    )
}