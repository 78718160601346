import React, {useState} from 'react';
import loader from "google-map-react/lib/loaders/google_map_loader";
import {googleMapKey} from "../Config";
import TextField from "@material-ui/core/TextField";
import useGeolocation from "react-hook-geolocation";

export default function AddressAutocomplete({onSelected, value: initialValue, ...props}) {
    const ref = React.createRef();
    const [maps, setMaps] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const geolocation = useGeolocation({enableHighAccuracy: true});
    const [value, setValue] = React.useState(initialValue);

    loader({key: googleMapKey, libraries: 'geometry,places'}).then(setMaps);

    // Setup the autocomplete component with event listeners
    React.useEffect(() => {
        if (!maps) return;
        const ac = new maps.places.Autocomplete(ref.current, {types: ['address']});
        ac.setFields(['formatted_address', 'geometry.location']);
        ac.addListener('place_changed', () => {
            const {
                formatted_address: address,
                geometry: {
                    location: {
                        lat,
                        lng,
                    }
                }
            } = ac.getPlace();
            // Set the value so the user can see it
            setValue(address);
            onSelected({address, location: {lat: lat(), lng: lng()}});
        });

        setAutocomplete(ac);
    }, [maps, setAutocomplete]);

    React.useEffect(() => {
        if (!autocomplete || !maps) return;
        // Cleanup event listeners on component unmounted
        return () => {
            autocomplete && maps.event.clearInstanceListeners(autocomplete)
        }
    }, [autocomplete, maps]);

    // Bias the autocomplete to the user's geographical location
    React.useEffect(() => {
        if (!autocomplete || !maps || !geolocation || !geolocation.latitude || !geolocation.longitude) return;
        const circle = new maps.Circle({
            center: {lat: geolocation.latitude, lng: geolocation.longitude},
            radius: geolocation.accuracy,
        });
        autocomplete.setBounds(circle.getBounds())
    }, [maps, geolocation, autocomplete]);

    return (
        <TextField
            {...props}
            inputRef={ref}
            type="text"
            value={value}
            onChange={event => {
                // Trigger an empty address selected if the field is cleared
                if (!event.target.value) onSelected({});
                setValue(event.target.value)
            }}
        />
    )
}