import moment from "moment";
import MapItems from "./MapItems";
import {useTheme} from "@material-ui/core";
import {useEffect, useState} from "react";

const markerColors = [
    '#1D6996',
    '#CC503E',
    '#0F8554',
    '#5F4690',
    '#38A6A5',
    '#73AF48',
    '#EDAD08',
    '#E17C05',
    '#94346E',
    '#6F4070',
    '#994E95',
]

const markerPath = "M 12 2 C 8.13 2 5 5.13 5 9 c 0 5.25 7 13 7 13 s 7 -7.75 7 -13 c 0 -3.87 -3.13 -7 -7 -7 z z"
const highPriorityPath = "M 10 3 h 6 v 12 h -6 z M 10 20 a 3 3 0 1 1 6 0 a 3 3 0 1 1 -6 0"

export default function MapWorkMarkers(google, works, hideIneligible, onClick) {
    const [userColors, setUserColors] = useState({});
    const theme = useTheme()

    function onCreate(work) {
        const {address: {lat, lng}, due, status, transitions, assigned_user, urgent, skip_reason} = work;

        const [{assigned: {users = []} = {}} = {}] = (transitions || []).filter(({to_state}) => to_state === "Assigned");

        let color = "#FFFFFF"
        if (skip_reason) {
            color = "#CCCCCC"
        } else if (assigned_user) {
            // Automatic routing preview
            color = userColors[assigned_user]
            if (!color) {
                color = markerColors[Object.keys(userColors).length % markerColors.length]
                userColors[assigned_user] = color
            }
        } else if (users[0] && status) {
            // Adding status to this check is a hack to remove transition-assigned-colors when automatically routing

            // Non-automatic routing preview assigned work
            // TODO: How to handle multiple assigned users?
            const name = `${users[0].first_name} ${users[0].last_name}`
            color = userColors[name]
            if (!color) {
                // TODO: Should probably rework this - it's possible to have more contractors than colors
                color = markerColors[Object.keys(userColors).length % markerColors.length]
                userColors[name] = color
            }
        }

        const daysUntilDue = Math.ceil(moment(due).endOf('day').diff(moment(), 'days', true))

        let svgMarker;
        if (urgent) {
            svgMarker = {
                path: highPriorityPath,
                fillColor: color,
                fillOpacity: 1.0,
                scale: 2.0,
                anchor: new google.maps.Point(13, 23),
                labelOrigin: new google.maps.Point(13, 9),
            }
        } else {
            svgMarker = {
                path: markerPath,
                fillColor: color,
                fillOpacity: 1.0,
                scale: 1.25,
                anchor: new google.maps.Point(12, 22),
                labelOrigin: new google.maps.Point(12, 10),
            }
        }

        let labelSize = '18px'
        if (urgent && daysUntilDue >= 10) {
            labelSize = '10px'
        } else if (daysUntilDue >= 10) {
            labelSize = '12px'
        }

        const marker = new google.maps.Marker({
            position: {lat, lng},
            map: google.map,
            label: {
                text: String(daysUntilDue),
                color: theme.palette.getContrastText(color),
                fontSize: labelSize,
                fontWeight: 'bold',
            },
            icon: svgMarker,
        })

        marker.addListener('click', () => onClick(work, marker))

        return marker
    }

    function onRemove(work, marker) {
        marker.setMap(null)
    }

    function canShow(work) {
        if (hideIneligible && work.skip_reason) {
            return false
        }
        return true
    }

    const [mapItems, computeMapItems] = MapItems(google, works, onCreate, onRemove, canShow)

    useEffect(computeMapItems, [hideIneligible])

    return [mapItems, userColors]
}

MapWorkMarkers.whyDidYouRender = true