import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";

export default function MapInfoWindow(google, id) {
    const [infoWindowComponent, setInfoWindowComponent] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);

    // To be used on the first render of Google Maps
    useEffect(() => {
        if (!google) return

        const iw = new google.maps.InfoWindow({
            content: `<div id="${id}"/>`,
        });

        iw.addListener("closeclick", () => {
            setInfoWindowComponent(null);
        });

        setInfoWindow(iw);
    }, [google])

    function openWindowAtMarker(marker, component) {
        // This is necessary in order to prevent momentarily showing old content when a new window is clicked
        setInfoWindowComponent(null);

        // This is a neat trick to allow us to render React components inside a map InfoWindow. The InfoWindow
        // has a root element with id infoWindowContent which we can render into using ReactDOM but first
        // we need to listen for the InfoWindow's DOM to be ready hence the domready listener and render after.
        // Additionally, to get context passing in, we need to use ReactDOM.createPortal to create the component
        const listener = infoWindow.addListener("domready", function () {
            listener.remove();
            const el = document.getElementById(id);
            setInfoWindowComponent(ReactDOM.createPortal(component, el));
        });

        infoWindow.open(google.map, marker);
    }

    return [infoWindowComponent, openWindowAtMarker]
}

MapInfoWindow.whyDidYouRender = true
