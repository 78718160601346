import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {usePatchWork} from "../api/work";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

export default function UrgentDialog({work, onClose}) {
    const classes = useStyles();

    const [{work: updatedWork, loading, error}, patchWork] = usePatchWork();

    React.useEffect(() => {
        if (!work) return;
        let service_codes = (work.service_codes || "").split(" ");
        if (work.company === 'TW') {
            service_codes.push("Z5")
        } else {
            service_codes.push("URGENT")
        }

        work && patchWork({
            workId: work.id,
            changes: {
                service_codes: service_codes.join(" ")
            }
        })
    }, [work])

    React.useEffect(() => {
        updatedWork && onClose(updatedWork)
    }, [updatedWork])

    return (
        <Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
            <DialogTitle>Set Work Urgent</DialogTitle>
            <DialogContent>
                <Typography gutterBottom variant="h6">
                    {work?.raw_address}
                </Typography>
                <div style={{position: 'relative', minHeight: 80}}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>
                    )}
                </div>
                {error && <Alert elevation={6} variant="filled" severity="error">
                    {error}
                </Alert>}
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => onClose()}>Dismiss</Button>
            </DialogActions>
        </Dialog>
    )
}