export const Status = {
    Created: "Created",
    // OUPSFiling: "OUPS filing",
    // OUPSLocating: "OUPS locating",
    Assigned: "Assigned",
    Complete: "Complete",
    // Due: "Due",
    Canceled: "Canceled",
    Hold: "On hold",
    HoldReleased: "Hold released",
    Skipped: "Skipped",
    Recreated: "Recreated",
    QCComplete: "QC complete",
};