import {GoogleLogin} from "@react-oauth/google";
import React, {useEffect} from "react";
import {useAuthActions} from "../api/auth";

const useIsMounted = () => {
    const isMounted = React.useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);
    return isMounted;
};

export default function Login() {
    const isMounted = useIsMounted();

    const {login} = useAuthActions();

    function onSuccess(response) {
        // console.log("onSuccess", {isMounted: isMounted.current});
        // Check if component was unmounted, don't bother trying to set the token
        if (isMounted.current) {
            login(response.credential);
        }
    }

    const onError = (response) => {
        console.log("onError", response);
        // TODO: Logout here?
    };

    return (
        <GoogleLogin
            auto_select
            onSuccess={onSuccess}
            onError={onError}
        />
    )
}
