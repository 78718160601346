import {useEffect, useState} from "react";
import {useAuth} from "./auth";
import * as rootAxios from "axios";

export default function useLoading(initialData, initialLoading, keepDataOnLoad) {
    const [state, setState] = useState({error: null, loading: initialLoading, data: initialData});

    const [dataFunc, setDataFunc] = useState(null);
    // const [data, setData] = useState(initialData);
    // const [error, setError] = useState(null);
    // const [loading, setLoading] = useState(initialLoading);

    const {axios} = useAuth();

    useEffect(() => {
        if (!axios || !dataFunc) {
            return
        }
        if (keepDataOnLoad) {
            setState(state => ({...state, error: null, loading: true}))
        } else {
            setState({error: null, loading: true, data: initialData})
        }
        // setError(null)
        // setLoading(true);
        dataFunc(axios)
            .then(resData => {
                setState({error: null, loading: false, data: resData})
                // setError(null);
                // setData(resData);
            })
            .catch(err => {
                // The request was canceled intentionally, don't set an error or anything
                if (rootAxios.isCancel(err)) return
                // TODO: May need to revisit this logic
                const {response: {data, data: {message} = {}} = {}} = err;
                const error = message || data || String(err);
                // setError(message || data || String(err));
                setState({error, loading: false, data: initialData})
            })
            // .then(() => setLoading(false))
    }, [axios, dataFunc]);

    return [state, setDataFunc];
}

export function useLoading2(initialData, initialLoading, initialReq) {
    const [state, setState] = useState({error: null, loading: initialLoading, data: initialData});
    const [req, setReq] = useState(initialReq);

    const {axios} = useAuth();

    useEffect(() => {
        if (!axios || !req) return

        setState(state => ({...state, error: null, loading: true}))

        axios(req)
            .then(res => setState({error: null, loading: false, data: res.data}))
            .catch(err => {
                // The request was canceled intentionally, don't set an error or anything
                if (rootAxios.isCancel(err)) return
                // TODO: May need to revisit this logic
                const {response: {data, data: {message} = {}} = {}} = err;
                const error = message || data || String(err);
                // setError(message || data || String(err));
                setState({error, loading: false, data: initialData})
            })
    }, [axios, req]);

    return [state, setReq];
}