import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import humanFileSize from "../util/humanFileSize";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import getFontAwesomeIconFromMIME from "../util/fontAwesomeIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import AttachmentDialog from "./AttachmentDialog";
import {makeStyles} from "@material-ui/core/styles";
import {baseAPIUrl} from "../Config";

const useStyles = makeStyles(theme => ({
    fileIcon: {
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        fontSize: 32,
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        "-ms-transform": "translateY(-50%)",
        transform: "translateY(-50%)",
    },
}));

export default function ConstructionAttachments({construction: {attachments}, visible, setVisible, classes: {card, cardContent}}) {
    const classes = useStyles({});
    const [openAttachment, setOpenAttachment] = React.useState();

    function toggleVisible(id) {
        const idx = visible.indexOf(id);
        const newVisible = visible.slice();
        if (idx > -1) {
            newVisible.splice(idx, 1);
        } else {
            newVisible.push(id);
        }
        setVisible(newVisible);
    }

    const emailAttachments = attachments.filter(({content_id}) => !content_id);
    return (
        <Card raised={true} className={card}>
            {openAttachment && <AttachmentDialog attachment={openAttachment} setAttachment={setOpenAttachment}/>}
            <CardHeader title="Construction Attachments" subheader={setVisible && "Only checked attachments will be visible to contractors"}/>
            <CardContent className={cardContent}>
                <List>
                    {emailAttachments.map((attachment, i) => {
                        const {id, data_url, filename, size, content_type, caption} = attachment;

                        // Only show images which we actually have the content type for
                        let src;
                        if (content_type.indexOf("image/") === 0 && content_type !== "image/heif") {
                            src = data_url;
                        }

                        let description = `${humanFileSize(size)} - ${caption ? caption : content_type}`;

                        // TODO: Only allow the dialog for images, pdfs, stuff that can be previewed in the browser
                        return <ListItem button={true} key={i} onClick={() => setOpenAttachment(attachment)}>
                            <ListItemAvatar>
                                <Avatar src={src} variant="square" className={classes.fileIcon}>
                                    <Icon className={`${classes.icon} fa ${getFontAwesomeIconFromMIME(content_type)}`}/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={filename} secondary={description}/>
                            {setVisible && <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={() => toggleVisible(id)}
                                    checked={visible.indexOf(id) > -1}
                                    color="primary"
                                />
                            </ListItemSecondaryAction>}
                        </ListItem>
                    })}
                </List>
            </CardContent>
        </Card>
    )
}