import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {useEmailRecipients, useAddRecipient, useRemoveRecipient} from "../api/emailRecipients";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ErrorSnackbar from "../components/ErrorSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

export default function EmailRecipients() {
    const classes = useStyles();

    const {emailRecipients, loading, error} = useEmailRecipients()

    const [rawEmailRecipients, setRawEmailRecipients] = useState({})

    const [mapped, setMapped] = useState({})

    useEffect(() => {
        if (!rawEmailRecipients?.reports) return

        setMapped(
            rawEmailRecipients.reports.reduce(function (result, value) {
                result[value] = rawEmailRecipients.recipients
                    .filter(recipient => recipient.report === value)
                    .map(recipient => recipient.email)

                return result
            }, {})
        )
    }, [rawEmailRecipients, setMapped])

    const [{updated: added, loading: addLoading, error: addError}, add] = useAddRecipient()
    const [{updated: removed, loading: removeLoading, error: removeError}, remove] = useRemoveRecipient()

    useEffect(() => {
        emailRecipients?.reports && setRawEmailRecipients(emailRecipients)
    }, [emailRecipients, setRawEmailRecipients])

    useEffect(() => {
        added?.reports && setRawEmailRecipients(added)
    }, [added, setRawEmailRecipients])

    useEffect(() => {
        removed?.reports && setRawEmailRecipients(removed)
    }, [removed, setRawEmailRecipients])

    function onAdd(report, email) {
        add({report, email})
    }

    function onRemove(report, email) {
        remove({report, email})
    }

    if (loading) {
        return <h2>Loading...</h2>
    }

    if (error) {
        const {response: {data: {message} = {}} = {}} = error;
        return <h1>An error has occurred: {message || String(error)}</h1>
    }

    return (
        <Container maxWidth="sm" style={{paddingTop: 16}}>
            {(addLoading || removeLoading) && (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={64}/>
                </div>
            )}
            {addError && <ErrorSnackbar message={addError}/>}
            {removeError && <ErrorSnackbar message={removeError}/>}
            <Typography variant="h3">Email recipients</Typography>
            {Object.keys(mapped).map(report => (
                <ReportCard
                    key={report}
                    report={report}
                    emails={mapped[report]}
                    onAdd={onAdd}
                    onRemove={onRemove}
                />
            ))}
        </Container>
    )
}

function ReportCard({report, emails, onAdd, onRemove}) {
    const displayName = report.split('_').reduce(function(result, value) {
        return result + ' ' + value.charAt(0).toUpperCase() + value.slice(1)
    }, '')

    const [newEmail, setNewEmail] = React.useState("");

    const add = function() {
        onAdd(report, newEmail)
        setNewEmail("")
    }

    return (
        <Card raised={true} style={{marginTop: 16}}>
            <CardHeader title={displayName}/>
            <CardContent style={{paddingTop: 0}}>
                <List>
                    {emails.map(email => (
                        <ReportEmail
                            key={email}
                            email={email}
                            onRemove={() => onRemove(report, email)}
                        />
                    ))}
                    <ListItem key="add_email">
                        <ListItemText
                            primary={
                                <TextField
                                    label="Add email"
                                    fullWidth
                                    value={newEmail}
                                    onChange={ev => setNewEmail(ev.target.value)}
                                    onKeyDown={ev => {
                                        if (ev.key === 'Enter') {
                                            ev.preventDefault()
                                            add()
                                        }
                                    }}
                                />
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={add}>
                                <AddIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}

function ReportEmail({email, onRemove}) {
    return <ListItem>
        <ListItemText primary={email}/>
        <ListItemSecondaryAction>
            <IconButton edge="end" onClick={onRemove}>
                <DeleteIcon/>
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
}
