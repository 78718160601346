import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";

export default function AttachmentDialog({attachment, attachment: {caption, filename, content_type, data_url}, setAttachment}) {
    const supportedImage = content_type.indexOf("image/") === 0 && content_type !== "image/heif";

    let props = {};
    if (!supportedImage) {
        props.PaperProps = {style: {height: '100%'}};
        props.fullWidth = true;
    }

    function renderContent() {
        if (content_type === "application/pdf") {
            return <object
                data={data_url}
                type={content_type}
                width='100%'
                height='99%'
            >
                {filename}
            </object>
        }

        if (supportedImage) {
            return <TransformWrapper centerOnInit={true}>
                <TransformComponent wrapperStyle={{maxWidth: "100%"}}>
                    <img src={data_url} alt={filename}/>
                </TransformComponent>
            </TransformWrapper>
        }

        // For testing with the google docs viewer when running locally
        const url = data_url.replace("http://localhost:8080", "https://roush-data.horner.codes");

        return <iframe
            title="Document viewer"
            src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
            width="100%"
            height="99%"
            style={{border: 0}}
            onLoad={event => {
                // TODO: We can remove the pop-out action of the Google docs embed https://stackoverflow.com/questions/29349556/how-to-disable-pop-out-option-in-pdf-viewer-with-google-doc-iframe
                console.log("iframe loaded", event, event.target);
            }}
        />
    }

    return (
        <Dialog open={!!attachment} onClose={() => setAttachment()} maxWidth={false} {...props}>
            <DialogTitle>{caption || filename}</DialogTitle>
            <DialogContent>
                {renderContent(attachment)}
            </DialogContent>
            <DialogActions>
                <Button
                    component="a"
                    href={data_url}
                    target="_blank"
                    color="primary"
                    variant="contained"
                    startIcon={<GetAppIcon/>}
                    style={{margin: 12}}
                >
                    Download
                </Button>
            </DialogActions>
        </Dialog>
    )
}
