import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";
import React from "react";

export default function ConstructionEmail({construction: {subject, body, attachments, mail_date}, classes: {card, cardContent}}) {
    const bodyAttachments = attachments.filter(({content_id}) => content_id);

    return (
        <Card raised={true} className={card}>
            <CardHeader
                title="Original Email Contents"
                subheader={moment(mail_date).format('lll')}
            />
            <CardContent className={cardContent}>
                <Typography variant="h6">{subject}</Typography>
                {body.split(/\r?\n/).filter(el => el).map((part, i) => {
                    const found = part.match(/\[cid:(.*?)]/);
                    if (found) {
                        const attachment = bodyAttachments.find(({content_id}) => {
                            // TODO: This may not be the right way to handle Content-ID. See: https://tools.ietf.org/html/rfc2392
                            return content_id.split("@")[0] === found[1].split("@")[0];
                        });

                        if (attachment) {
                            // TODO: Expand the image on click, e.g. lightbox
                            return <img
                                key={i}
                                src={attachment.data_url}
                                style={{maxWidth: "100%"}}
                                alt={attachment.filename}
                            />
                        }
                    }

                    return <p key={i}>{part}</p>
                })}
            </CardContent>
        </Card>
    )
}