import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {StepIcon} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import Created from "../components/steps/Created";
import Complete from "../components/steps/Complete";
import Due from "../components/steps/Due";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import StepContent from "@material-ui/core/StepContent";
import UserChip from "../components/UserChip";
import Comment from "../components/Comment";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from '@material-ui/icons/Delete';
import {red} from "@material-ui/core/colors";
import {useDeleteTransition} from "../api/work";
import ErrorSnackbar from "../components/ErrorSnackbar";
import TransitionDeleteDialog from "../components/TransitionDeleteDialog";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    buttonBase: {
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: theme.palette.action.hover,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    iconButton: {
        padding: 0
    },
    deleteIcon: {
        color: red[700]
    }
}));

function ExpandLabel({children, onExpandClick, ...other}) {
    const classes = useStyles({});
    const {expanded} = other;
    return <ButtonBase style={{display: "flex"}} className={classes.buttonBase} component="div" onClick={onExpandClick}>
        {React.cloneElement(children, other)}
        <span style={{flexGrow: 1}}/>
        <IconButton style={{marginTop: -6, marginBottom: -6}}>
            {expanded ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
    </ButtonBase>
}

function AssignedTransition({assigned: {users}}) {
    return users.map(user => (
        <UserChip key={user.id} user={user} /*className={classes.assignedUser}*//>
    ))
}

function CommentTransition({comment}) {
    return <Comment comment={comment} isExpanded disableGutters/>
}

function transitionLabel(transition, onDelete) {
    const {to_state, created_at, user, qc_complete} = transition;

    function onClick(e) {
        e.stopPropagation();
        onDelete && onDelete(transition);
    }

    const onClickProp = to_state === 'Created' ? undefined : onClick

    switch (to_state) {
        case "QC complete":
            const text = `QC ${qc_complete.pass ? "Pass" : "Fail"}`
            return defaultLabel({date: created_at, user, text: text, error: !qc_complete.pass, onClick: onClickProp})
    }

    return defaultLabel({date: created_at, user, text: to_state, onClick: onClickProp})
}

function transitionContents(transition) {
    const {to_state, canceled, complete, hold, hold_released, skipped, assigned, qc_complete} = transition;

    switch (to_state) {
        case "Created":
            return null
        case "Assigned":
            return <AssignedTransition assigned={assigned}/>
        case "Skipped":
            return <CommentTransition comment={skipped.comment}/>
        case "Complete":
            return <>
                {complete.billed.map((billed, i) => {
                    const {id, quantity, billable_item: {name, description, unit}} = billed
                    return <ListItem key={id}>
                        <ListItemText
                            primary={`${unit ? `${quantity} ${unit} - ` : ''}${name}`}
                            secondary={description}
                        />
                    </ListItem>
                })}
                <Divider/>
                <CommentTransition comment={complete.comment}/>
            </>
        case "Canceled":
            return <CommentTransition comment={canceled.comment}/>
        case "On hold":
            return <CommentTransition comment={hold.comment}/>
        case "Hold released":
            return <CommentTransition comment={hold_released.comment}/>
        case "QC complete":
            return <>
                <ListItem key="qc_measured_depth">
                    <ListItemText
                        primary={`${qc_complete.measured_depth} inches`}
                        secondary="Measured depth"
                    />
                </ListItem>
                <ListItem key="qc_resolution">
                    <ListItemText
                        primary={qc_complete.resolution}
                        secondary="Resolution"
                    />
                </ListItem>
                <Divider/>
                <CommentTransition comment={qc_complete.comment}/>
            </>
    }

    return null
}

const DeleteStepIcon = props => {
    const classes = useStyles({});
    const [hover, setHover] = useState(false);
    const {onClick, ...others} = props;

    if (!onClick) {
        return <StepIcon {...others}/>
    }

    return <IconButton
        onClick={onClick}
        className={classes.iconButton}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        {hover ? <DeleteIcon className={classes.deleteIcon}/> : <StepIcon {...others}/>}
    </IconButton>
}

const defaultLabel = ({date, user, text, error, dateFormat = 'ddd lll', onClick}) => <StepLabel
    error={error}
    optional={
        <>
            <Typography variant="caption" component="div">
                {moment(date).format(dateFormat)}
            </Typography>
            {/*<UserChip user={user} />*/}
            {user && <Typography variant="caption" component="div">
                By {user.first_name} {user.last_name}
            </Typography>}
        </>
    }
    StepIconComponent={DeleteStepIcon}
    StepIconProps={{onClick}}
>
    {text}
</StepLabel>

const isStartOfUTCDay = date => {
    if (date.isSame(moment(date).utc().startOf('day'))) {
        date.utc();
        return true
    }
    return false
}

function makeTransitionStep(transition, onDelete) {
    return {
        date: transition.created_at,
        label: transitionLabel(transition, onDelete),
        contents: transitionContents(transition)
    }
}

export default function TransitionsCard({work, setWork, classes: {card, cardContent} = {}}) {
    // The active step is the first step which is not completed.
    const {id: workId, company, order_num, transitions, entered, due, oups} = work;

    const [expanded, setExpanded] = React.useState(new Array(transitions.length));
    const [{work: updatedWork, loading, error}, setReq] = useDeleteTransition();
    const [transitionToDelete, setTransitionToDelete] = useState(undefined);

    function onExpandClick(idx) {
        const newExpanded = expanded.slice();
        newExpanded[idx] = !newExpanded[idx];
        setExpanded(newExpanded);
    }

    useEffect(() => {
        updatedWork && setWork(updatedWork)
    }, [updatedWork, setWork])

    const steps = transitions.map(transition => {
        return makeTransitionStep(transition, setTransitionToDelete)
    })

    function addTimestampStep(date, label) {
        const dateMoment = moment(date)
        const utcStart = isStartOfUTCDay(dateMoment)
        steps.push({
            date: dateMoment,
            label: defaultLabel({
                date: dateMoment,
                text: label,
                dateFormat: utcStart ? 'ddd ll' : undefined,
            }),
        })
    }

    entered && addTimestampStep(entered, "Entered");
    due && addTimestampStep(due, "Due");

    (oups || []).forEach(({type, created_at, work_date, start_by_date, ticket, error}) => {
        let contents;
        if (error) {
            contents = <Typography>Error filing ticket: <b>{error}</b></Typography>
        } else if (type === "Add" || type === "Update") {
            contents = <>
                <Typography>Ticket <b>{ticket}</b> {type === "Add" ? "filed" : "updated"}</Typography>
                <Typography>Good date: <b>{moment(work_date).format('ddd lll')}</b></Typography>
                <Typography>Start by date: <b>{moment(start_by_date).format('ddd lll')}</b></Typography>
            </>
        } else {
            contents = <Typography>Ticket was removed</Typography>
        }

        steps.push({
            date: created_at,
            label: defaultLabel({date: created_at, text: `${type} OUPS Ticket`, error: !!error || type === "Remove"}),
            contents,
        })
    })

    steps.sort((a, b) => moment(a.date).diff(b.date))

    let activeStep = steps.findIndex(step => moment().isSameOrBefore(step.date)) - 1
    if (activeStep < 0) {
        activeStep = steps.length - 1
    }

    return (
        <>
            <Box pt={2} pl={2}>
                <Typography variant="h6">History</Typography>
            </Box>
            <TransitionDeleteDialog
                open={!!transitionToDelete}
                handleClose={() => setTransitionToDelete(undefined)}
                submit={() => {
                    setReq({workId, transitionId: transitionToDelete.id})
                    setTransitionToDelete(undefined)
                }}/>
            {error && <ErrorSnackbar message={error}/>}

            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                {steps.map((step, i) => {
                    const {label, contents} = step
                    return <Step key={i} expanded={expanded[i]} completed={i < activeStep}>
                        {/*The stepper forces the active step to always be expanded. Any later step should always collapse*/}
                        {i < activeStep && contents ? (
                            <ExpandLabel onExpandClick={() => onExpandClick(i)}>
                                {label}
                            </ExpandLabel>
                        ) : (
                            label
                        )}
                        <StepContent>
                            {contents}
                        </StepContent>
                    </Step>
                })}
            </Stepper>
        </>
    );
}