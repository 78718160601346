import { googleLogout } from '@react-oauth/google';
import React from "react";
import {useAuthActions} from "../api/auth";
import Button from "@material-ui/core/Button";

export default function Logout() {
    const {logout} = useAuthActions();

    const onClick = () => {
        googleLogout()
        logout()
    }

    return (
        <Button
            onClick={onClick}
            variant="contained"
            size="small"
        >
            Logout
        </Button>
    )
}