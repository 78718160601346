import React, {createRef, useEffect, useMemo, useRef, useState} from "react";
import {CardContent, Container} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {FileUploader} from "react-drag-drop-files";
import * as XLSX from "xlsx";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import Button from "@material-ui/core/Button";
import {useAuth} from "../api/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CheckCircle, Error} from "@material-ui/icons";
import {useTable} from "react-table";
import {useFlexLayout} from "react-table/src/plugin-hooks/useFlexLayout";
import MaUTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 16,
        width: '100%',
        overflowX: 'auto',
    },
    fileUploadHeader: {
        marginBottom: 16,
    },
    tableContainer: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '75vh',
    },
    tableHeader: {
        marginBottom: 16,
    },
    tableHead: {
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 2
    },
    tooltip: {
        maxWidth: "none"
    },
    errorTooltipText: {
        whiteSpace: "pre",
    },
    attachments: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: 8
    },
    attachmentIcon: {
        height: 80,
        width: 80,
        cursor: 'pointer',
        objectFit: "cover"
    },
    attachment: {
        maxWidth: "100%",
        maxHeight: "calc(100vh - 160px)",
    }
}));

export default function ATTZoho() {
    const classes = useStyles({});
    return (
        <Container className={classes.container} maxWidth={false}>
            <UDMSAssign/>
            <br/>
            <UDMSCloseout/>
            <br/>
            <QualtekCloseout/>
        </Container>
    )
}

function errorMessage(err) {
    const {response: {data, data: {message} = {}} = {}} = err;
    return message || data || String(err)
}

function useDataProcessing(data, setData, dataField, doRequest) {
    const isProcessing = useRef(false)
    const [idx, setIdx] = useState(undefined);

    const setResult = result => setData(data => {
        const elements = [...data]
        elements[idx] = {...elements[idx], [dataField]: result}
        return elements
    })

    useEffect(() => {
        if (idx === undefined || data[idx] === undefined || isProcessing.current) return

        // Check to see if we already did this one. If we did, and it was successful, skip it.
        if (data[idx][dataField]?.data) {
            setIdx(idx + 1)
            return
        }

        isProcessing.current = true
        setResult({loading: true})

        doRequest(data[idx])
            .then(resp => setResult({data: resp.data}))
            .catch(err => setResult({error: errorMessage(err)}))
            .finally(() => {
                if (idx >= data.length - 1) {
                    // We're done. Unset idx
                    setIdx(undefined)
                } else if (isProcessing.current) {
                    // On to the next one, check to make sure we weren't canceled by stopProcessing()
                    setIdx(idx + 1)
                }
                isProcessing.current = false
            })
    }, [data, setData, idx, setIdx, dataField, doRequest])

    const start = () => setIdx(0)
    const stop = () => {
        isProcessing.current = false
        setIdx(undefined)
    }

    return {start, stop, isProcessing: idx !== undefined && isProcessing.current}
}

function UDMSAssign() {
    const classes = useStyles({});
    const [data, setData] = useState([]);
    const {axios} = useAuth();

    const doRequest = item => axios.post(`/att_zoho/udms_assign`, item)

    const {start, stop, isProcessing} = useDataProcessing(data, setData, "response", doRequest)

    async function fileChanged(file) {
        const json = await parseSpreadsheet(file)
        const parsedData = json.map(row => ({
            workRequestNumber: String(row['Request #']),
            assignedResource: row['Tech Name / Crew'],
            locateNumber: row['OUPS'],
            expectedCompletionDate: moment(row['Promise Date']).format("MM-DD-YYYY"),
            partnerNotes: row['Tech Comments']
        }))
        setData(parsedData)
    }

    const resetData = () => setData([])
    const uploadTypes = ["XLSX"]

    return (
        <Card raised={true}>
            <CardHeader
                title="ATT UDMS Assign"
                action={
                    <IconButton aria-label="Reset" onClick={resetData}>
                        <ClearIcon/>
                    </IconButton>
                }
            />
            <CardContent>
                {data.length === 0 ? <>
                    <Typography component="div">
                        To assign ATT jobs in UDMS, begin by uploading an Excel spreadsheet
                    </Typography>
                    <Typography>
                        Required spreadsheet columns:
                    </Typography>
                    <ul className={classes.fileUploadHeader}>
                        <li>Request #</li>
                        <li>Tech Name / Crew</li>
                        <li>OUPS</li>
                        <li>Promise Date</li>
                        <li>Tech Comments</li>
                    </ul>
                    <FileUploader
                        label="Drag and drop a file here or click to upload"
                        handleChange={fileChanged}
                        name="file"
                        types={uploadTypes}
                    />
                </> : <>
                    <Typography component="div" className={classes.tableHeader}>
                        {data.length} jobs parsed from uploaded spreadsheet
                    </Typography>
                    <div>
                        {isProcessing ?
                            <Button variant="contained" onClick={stop}>
                                Click here to stop assigning in UDMS
                            </Button>
                            :
                            <Button variant="contained" color="primary" onClick={start}>
                                Click here to assign in UDMS
                            </Button>
                        }
                    </div>
                    <UDMSAssignTable data={data}/>
                </>
                }
            </CardContent>
        </Card>
    )
}

function UDMSAssignTable({data}) {
    const columns = useMemo(() => ([
        {
            Header: "Status",
            accessor: "response",
            width: 40,
            Cell: StatusCell
        },
        {
            Header: "Request number",
            accessor: "workRequestNumber",
            width: 60
        },
        {
            Header: "Locate number",
            accessor: "locateNumber",
            width: 70
        },
        {
            Header: "Expected completion",
            accessor: "expectedCompletionDate",
            width: 80
        },
        {
            Header: "Assigned resource",
            accessor: "assignedResource",
            width: 120
        },
        {
            Header: "Partner notes",
            accessor: "partnerNotes",
            width: 300
        },
    ]), [])

    const memoizedData = useMemo(() => data, [data])

    const instance = useTable({columns, data: memoizedData}, useFlexLayout);

    return (
        <Table instance={instance}/>
    )
}

async function parseSpreadsheet(file) {
    const data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
    const workbook = XLSX.read(data, {cellDates: true});
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    return XLSX.utils.sheet_to_json(worksheet)
}

function downloadReport(tableRef, sheetPrefix) {
    const worksheet = XLSX.utils.table_to_sheet(tableRef.current)

    const headerKeys = Object.keys(worksheet)
        .filter(key => key.match(/[A-Z]+1/))

    // Do we need to check that worksheet[key].v is a string?
    worksheet['!cols'] = headerKeys.map(key => ({wch: worksheet[key].v.length}))

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet)

    const filename = `${sheetPrefix} Report ${moment().format('l')}.xlsx`
    XLSX.writeFileXLSX(workbook, filename)
}

function toTabbedNewlines(values) {
    return values.map(el => "    • " + el).join("\n")
}

function parseAndValidateUDMSCloseout(row) {
    const wireTypes = {
        "C": "Fiber",
        "F": "Fiber",
        "P": "Fiber",
        "S": "Fiber",
        "2": "Copper",
        "5": "Copper",
    }

    const wireTypeValue = row["Wire Type"]
    let wireType = wireTypes[wireTypeValue]
    if (!wireTypeValue) {
        wireType = {error: `Wire type not found. Must be one of:\n${toTabbedNewlines(Object.keys(wireTypes))}`}
    } else if (!wireType) {
        wireType = {error: `Wire type '${wireTypeValue}' unknown. Must be one of:\n${toTabbedNewlines(Object.keys(wireTypes))}`}
    }

    const validCutOver = [
        "NO - No Cutover Required",
        "ASW - TEMPORARY WIRE PLACED BY TECH - AERIAL WIRE",
        "BOR - BORE",
        "DIR - DIRECTV",
        "DMG - PERMANENT WIRE PLACED BY TECH FOUND DAMAGED",
        "ISW - TEMPORARY WIRE PLACED BY TECH - INSIDE WIRE",
        "NWP - NO WIRE PLACED, HELD ORDER",
        "RTE - DIFFERENT ROUTE/EASEMENT ISSUE",
        "SLK - PERMANENT WIRE DID NOT HAVE ENOUGH SLACK",
        "TMS - TOO MUCH SLACK",
    ]

    let cutOverRequired
    let cutOverReason

    const cutOverValue = row["Cut over required  yes or no"]
    if (!cutOverValue) {
        cutOverReason = {error: `Cut over value not found. Must be one of:\n${toTabbedNewlines(validCutOver)}`}
    } else if (validCutOver.indexOf(cutOverValue) === -1) {
        cutOverReason = {error: `Cut over value '${cutOverValue}' unknown. Must be one of:\n${toTabbedNewlines(validCutOver)}`}
    } else {
        cutOverRequired = cutOverValue !== "NO - No Cutover Required"
        if (cutOverRequired) {
            cutOverReason = cutOverValue
        }
    }

    return {
        requestId: row["Request #"],
        techName: row["Tech Name / Crew"],
        pda6: parseString(row["PDa 6\" Depth, 1-25' Length"]),
        pdb6: parseString(row["PDb 6\" Depth, 26-150' Length"]),
        pdc6: parseString(row["PDc 6\" Depth, 151-250' Length"]),
        pdd6: parseString(row["PDd 6\" Depth, >250' Length"]),
        sidewalkBore: parseString(row["SW Bore"]),
        drivewayBore: parseString(row["DW Bore"]),
        conduitFtg: parseString(row["Conduit/Fish Footage."]),
        pedestalCount: parseString(row["Place Pedestal (count)"]),
        fiberCutover: parseString(row["Fiber Cutover"]),
        copperCutover: parseString(row["Copper Cutover"]),
        wireType,
        techComments: row["Tech Comments"],
        completeDate: moment(row['Complete Date']).format("MM-DD-YYYY"),
        cutOverRequired,
        cutOverReason,
    }
}

function parseAndValidateQualtekCloseout(row) {
    const validCutOver = [
        "NO - No Cutover Required",
        "ASW - TEMPORARY WIRE PLACED BY TECH - AERIAL WIRE",
        "BOR - BORE",
        "DIR - DIRECTV",
        "DMG - PERMANENT WIRE PLACED BY TECH FOUND DAMAGED",
        "ISW - TEMPORARY WIRE PLACED BY TECH - INSIDE WIRE",
        "NWP - NO WIRE PLACED, HELD ORDER",
        "RTE - DIFFERENT ROUTE/EASEMENT ISSUE",
        "SLK - PERMANENT WIRE DID NOT HAVE ENOUGH SLACK",
        "TMS - TOO MUCH SLACK",
    ]

    let cutOverRequired
    let cutOverReason

    const cutOverValue = row["Cut over required  yes or no"]
    if (!cutOverValue) {
        cutOverReason = {error: `Cut over value not found. Must be one of:\n${toTabbedNewlines(validCutOver)}`}
    } else if (validCutOver.indexOf(cutOverValue) === -1) {
        cutOverReason = {error: `Cut over value '${cutOverValue}' unknown. Must be one of:\n${toTabbedNewlines(validCutOver)}`}
    } else {
        cutOverRequired = cutOverValue !== "NO - No Cutover Required"
        if (cutOverRequired) {
            cutOverReason = cutOverValue
        }
    }

    let oups = row["OUPS"]
    if (!oups) {
        oups = {error: `OUPS ticket number required`}
    }

    return {
        requestId: row["Request #"],
        oupsTicket: oups,
        pda6: parseString(row["PDa 6\" Depth, 1-25' Length"]),
        pdb6: parseString(row["PDb 6\" Depth, 26-150' Length"]),
        pdc6: parseString(row["PDc 6\" Depth, 151-250' Length"]),
        pdd6: parseString(row["PDd 6\" Depth, >250' Length"]),
        sidewalkBore: parseString(row["SW Bore"]),
        drivewayBore: parseString(row["DW Bore"]),
        conduitFtg: parseString(row["Conduit/Fish Footage."]),
        pedestalCount: parseString(row["Place Pedestal (count)"]),
        fiberCutover: parseString(row["Fiber Cutover"]),
        copperCutover: parseString(row["Copper Cutover"]),
        techComments: row["Tech Comments"],
        completeDate: moment(row['Complete Date']).format("MM-DD-YYYY"),
        cutOverRequired,
        cutOverReason,
    }
}

function parseString(value) {
    if (value === undefined) {
        return undefined
    }
    const parsed = parseInt(value)
    if (isNaN(parsed)) {
        return {error: `Required to be a number but was '${value}'. Check for extra characters`}
    }
    return parsed
}

function UDMSCloseout() {
    const classes = useStyles({});
    const [data, setData] = useState([])
    const {axios} = useAuth()
    const tableRef = createRef()

    const loadAttachments = item => axios.get(`/att_zoho/udms_attachments/${item.requestId}`).then(resp => {
        if (resp.data.length === 0) {
            return Promise.reject("No attachments found")
        }
        return resp
    })
    const {
        start: startAttachments,
        isProcessing: attachmentsLoading
    } = useDataProcessing(data, setData, "attachments", loadAttachments)

    const closeout = item => {
        const hasError = Object.keys(item).some(key => key !== "result" && item[key]?.error)
        if (hasError) {
            return Promise.reject("Spreadsheet errors must be fixed before closeout")
        }
        return axios.post(`/att_zoho/udms_closeout`, {...item, attachments: item.attachments.data})
    }
    const {start, stop, isProcessing} = useDataProcessing(data, setData, "result", closeout)

    async function fileChanged(file) {
        const json = await parseSpreadsheet(file)
        const parsedData = json.map(row => {
            return parseAndValidateUDMSCloseout(row)
        })
        setData(parsedData)
        startAttachments()
    }

    const resetData = () => {
        stop()
        setData([])
    }
    const uploadTypes = ["XLSX"]

    return (
        <Card raised={true}>
            <CardHeader
                title="ATT UDMS Closeout"
                action={
                    <IconButton aria-label="Reset" onClick={resetData}>
                        <ClearIcon/>
                    </IconButton>
                }
            />
            <CardContent>
                {data.length === 0 ? <>
                    <Typography component="div">
                        To closeout ATT UDMS jobs in Zoho, begin by uploading an Excel spreadsheet
                    </Typography>
                    <Typography>
                        Required spreadsheet columns:
                    </Typography>
                    <ul className={classes.fileUploadHeader}>
                        <li>Request #</li>
                        <li>Tech Name / Crew</li>
                        <li>PDa 6" Depth, 1-25' Length</li>
                        <li>PDb 6" Depth, 26-150' Length</li>
                        <li>PDc 6" Depth, 151-250' Length</li>
                        <li>PDd 6" Depth, >250' Length</li>
                        <li>SW Bore</li>
                        <li>DW Bore</li>
                        <li>Conduit/Fish Footage.</li>
                        <li>Place Pedestal (count)</li>
                        <li>Fiber Cutover</li>
                        <li>Copper Cutover</li>
                        <li>Wire Type</li>
                        <li style={{ whiteSpace: "pre" }}>Cut over required  yes or no</li>
                        <li>Tech Comments</li>
                        <li>Complete Date</li>
                    </ul>
                    <FileUploader
                        label="Drag and drop a file here or click to upload"
                        handleChange={fileChanged}
                        name="file"
                        types={uploadTypes}
                    />
                </> : <>
                    <Typography component="div" className={classes.tableHeader}>
                        {data.length} jobs parsed from uploaded spreadsheet
                    </Typography>
                    <div>
                        {attachmentsLoading ?
                            <Typography>Waiting for attachments to load...</Typography>
                            :
                            isProcessing ?
                                <Button variant="contained" onClick={stop}>
                                    Click here to stop closeout
                                </Button>
                                :
                                <>
                                    <Button variant="contained" color="primary" onClick={start}>
                                        Click here to start closeout
                                    </Button>
                                    <br /><br />
                                    <Button variant="contained" color="primary" onClick={() => downloadReport(tableRef, "UDMS Closeout")}>
                                        Click here to download report
                                    </Button>
                                </>

                        }
                    </div>
                    <UDMSCloseoutTable data={data} innerRef={tableRef}/>
                </>
                }
            </CardContent>
        </Card>
    )
}

function QualtekCloseout() {
    const classes = useStyles({});
    const [data, setData] = useState([])
    const {axios} = useAuth()
    const tableRef = createRef()

    const loadAttachments = item => axios.get(`/att_zoho/qualtek_attachments/${item.requestId}`).then(resp => {
        if (resp.data.length === 0) {
            return Promise.reject("No attachments found")
        }
        return resp
    })
    const {
        start: startAttachments,
        isProcessing: attachmentsLoading
    } = useDataProcessing(data, setData, "attachments", loadAttachments)

    const closeout = item => {
        const hasError = Object.keys(item).some(key => key !== "result" && item[key]?.error)
        if (hasError) {
            return Promise.reject("Spreadsheet errors must be fixed before closeout")
        }
        return axios.post(`/att_zoho/qualtek_closeout`, {...item, attachments: item.attachments.data})
    }
    const {start, stop, isProcessing} = useDataProcessing(data, setData, "result", closeout)

    async function fileChanged(file) {
        const json = await parseSpreadsheet(file)
        const parsedData = json.map(row => {
            return parseAndValidateQualtekCloseout(row)
        })
        setData(parsedData)
        startAttachments()
    }

    const resetData = () => {
        stop()
        setData([])
    }
    const uploadTypes = ["XLSX"]

    return (
        <Card raised={true}>
            <CardHeader
                title="ATT Qualtek Closeout"
                action={
                    <IconButton aria-label="Reset" onClick={resetData}>
                        <ClearIcon/>
                    </IconButton>
                }
            />
            <CardContent>
                {data.length === 0 ? <>
                    <Typography component="div">
                        To closeout ATT Qualtek jobs in Zoho, begin by uploading an Excel spreadsheet
                    </Typography>
                    <Typography>
                        Required spreadsheet columns:
                    </Typography>
                    <ul className={classes.fileUploadHeader}>
                        <li>Request #</li>
                        <li>OUPS</li>
                        <li>PDa 6" Depth, 1-25' Length</li>
                        <li>PDb 6" Depth, 26-150' Length</li>
                        <li>PDc 6" Depth, 151-250' Length</li>
                        <li>PDd 6" Depth, >250' Length</li>
                        <li>SW Bore</li>
                        <li>DW Bore</li>
                        <li>Conduit/Fish Footage.</li>
                        <li>Place Pedestal (count)</li>
                        <li>Fiber Cutover</li>
                        <li>Copper Cutover</li>
                        <li style={{ whiteSpace: "pre" }}>Cut over required  yes or no</li>
                        <li>Tech Comments</li>
                        <li>Complete Date</li>
                    </ul>
                    <FileUploader
                        label="Drag and drop a file here or click to upload"
                        handleChange={fileChanged}
                        name="file"
                        types={uploadTypes}
                    />
                </> : <>
                    <Typography component="div" className={classes.tableHeader}>
                        {data.length} jobs parsed from uploaded spreadsheet
                    </Typography>
                    <div>
                        {attachmentsLoading ?
                            <Typography>Waiting for attachments to load...</Typography>
                            :
                            isProcessing ?
                                <Button variant="contained" onClick={stop}>
                                    Click here to stop closeout
                                </Button>
                                :
                                <>
                                    <Button variant="contained" color="primary" onClick={start}>
                                        Click here to start closeout
                                    </Button>
                                    <br /><br />
                                    <Button variant="contained" color="primary" onClick={() => downloadReport(tableRef, "Qualtek Closeout")}>
                                        Click here to download report
                                    </Button>
                                </>

                        }
                    </div>
                    <QualtekCloseoutTable data={data} innerRef={tableRef}/>
                </>
                }
            </CardContent>
        </Card>
    )
}

function ErrorCell({value}) {
    const classes = useStyles({});

    if (value?.error) {
        const title = <Typography className={classes.errorTooltipText}>{value.error}</Typography>
        return <Tooltip
            classes={{tooltip: classes.tooltip}}
            title={title}
        >
            <Error color="error"/>
        </Tooltip>
    }

    return value || null
}

function ErrorTooltip({error}) {
    const title = <Typography>{error}</Typography>
    return <Tooltip
        leaveTouchDelay={2500}
        enterTouchDelay={400}
        title={title}
    >
        <Error color="error"/>
    </Tooltip>
}

function AttachmentThumbnail({attachment}) {
    const classes = useStyles({});
    const [open, setOpen] = useState(false)
    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false)
    return <>
        <img className={classes.attachmentIcon} src={attachment.proxyUrl + "?size=80"} alt={attachment.name} onClick={onOpen}/>
        <Dialog open={open} onClose={onClose} maxWidth="xl">
            <DialogTitle>{attachment.name}</DialogTitle>
            <DialogContent>
                <img src={attachment.proxyUrl} alt={attachment.name} className={classes.attachment}/>
            </DialogContent>
        </Dialog>
    </>
}

function StatusCell({value}) {
    if (value?.loading) return <CircularProgress size={24}/>
    if (value?.data) return <>
        <CheckCircle color="primary"/>
        <span style={{display:"none"}}>Success</span>
    </>
    if (value?.error) return <>
        <ErrorTooltip error={value.error}/>
        <span style={{display:"none"}}>{value.error}</span>
    </>
    return null
}

function UDMSCloseoutTable({data, innerRef}) {
    const columns = useMemo(() => ([
        {
            Header: "Status",
            accessor: "result",
            width: 60,
            Cell: StatusCell
        },
        {
            Header: "Request ID",
            accessor: "requestId",
            width: 120
        },
        {
            Header: "Tech name",
            accessor: "techName",
            width: 100
        },
        {
            Header: "PDa",
            accessor: "pda6",
            width: 60,
            Cell: ErrorCell,
        },
        {
            Header: "PDb",
            accessor: "pdb6",
            width: 60,
            Cell: ErrorCell,
        },
        {
            Header: "PDc",
            accessor: "pdc6",
            width: 60,
            Cell: ErrorCell,
        },
        {
            Header: "PDd",
            accessor: "pdd6",
            width: 60,
            Cell: ErrorCell,
        },
        {
            Header: "SW bore",
            accessor: "sidewalkBore",
            width: 100,
            Cell: ErrorCell,
        },
        {
            Header: "DW bore",
            accessor: "drivewayBore",
            width: 100,
            Cell: ErrorCell,
        },
        {
            Header: "Conduit",
            accessor: "conduitFtg",
            width: 80,
            Cell: ErrorCell,
        },
        {
            Header: "Pedestals",
            accessor: "pedestalCount",
            width: 100,
            Cell: ErrorCell,
        },
        {
            Header: "Fiber Cutover",
            accessor: "fiberCutover",
            Cell: ErrorCell,
            width: 140
        },
        {
            Header: "Copper Cutover",
            accessor: "copperCutover",
            Cell: ErrorCell,
            width: 140
        },
        {
            Header: "Wire",
            accessor: "wireType",
            Cell: ErrorCell,
            width: 100,
        },
        {
            Header: "Completed",
            accessor: "completeDate",
            width: 120,
            Cell: ErrorCell,
        },
        {
            Header: "Cut over reason",
            accessor: "cutOverReason",
            Cell: ErrorCell,
        },
        {
            Header: "Tech comments",
            accessor: "techComments",
            Cell: ErrorCell,
            width: 250,
        },
        {
            Header: "First six attachments",
            accessor: "attachments",
            Cell: attachmentsCell(6),
            width: 300,
        }
    ]), [])

    const memoizedData = useMemo(() => data, [data])
    const instance = useTable({columns, data: memoizedData}, useFlexLayout);

    return (
        <Table instance={instance} innerRef={innerRef}/>
    )
}

function QualtekCloseoutTable({data, innerRef}) {
    const columns = useMemo(() => ([
        {
            Header: "Status",
            accessor: "result",
            Cell: StatusCell,
            width: 60,
        },
        {
            Header: "Request ID",
            accessor: "requestId",
            width: 120,
        },
        {
            Header: "OUPS ticket",
            accessor: "oupsTicket",
            Cell: ErrorCell,
            width: 120,
        },
        {
            Header: "PDa",
            accessor: "pda6",
            Cell: ErrorCell,
            width: 60,
        },
        {
            Header: "PDb",
            accessor: "pdb6",
            Cell: ErrorCell,
            width: 60,
        },
        {
            Header: "PDc",
            accessor: "pdc6",
            Cell: ErrorCell,
            width: 60,
        },
        {
            Header: "PDd",
            accessor: "pdd6",
            Cell: ErrorCell,
            width: 60,
        },
        {
            Header: "SW bore",
            accessor: "sidewalkBore",
            Cell: ErrorCell,
            width: 100,
        },
        {
            Header: "DW bore",
            accessor: "drivewayBore",
            Cell: ErrorCell,
            width: 100,
        },
        {
            Header: "Conduit",
            accessor: "conduitFtg",
            Cell: ErrorCell,
            width: 80,
        },
        {
            Header: "Pedestals",
            accessor: "pedestalCount",
            Cell: ErrorCell,
            width: 100,
        },
        {
            Header: "Fiber Cutover",
            accessor: "fiberCutover",
            Cell: ErrorCell,
            width: 140,
        },
        {
            Header: "Copper Cutover",
            accessor: "copperCutover",
            Cell: ErrorCell,
            width: 140,
        },
        {
            Header: "Completed",
            accessor: "completeDate",
            Cell: ErrorCell,
            width: 120,
        },
        {
            Header: "Cut over reason",
            accessor: "cutOverReason",
            Cell: ErrorCell,
        },
        {
            Header: "Tech comments",
            accessor: "techComments",
            Cell: ErrorCell,
            width: 250,
        },
        {
            Header: "First six attachments",
            accessor: "attachments",
            Cell: attachmentsCell(6),
            width: 300,
        }
    ]), [])

    const memoizedData = useMemo(() => data, [data])
    const instance = useTable({columns, data: memoizedData}, useFlexLayout);

    return (
        <Table instance={instance} innerRef={innerRef}/>
    )
}

function attachmentsCell(maxAttachments) {
    return ({cell: {value}}) => {
        const classes = useStyles({});

        if (!value) return null
        if (value.loading) return <CircularProgress size={24}/>
        if (value.error) return <ErrorTooltip error={value.error}/>
        if (value.data.length === 0) return "No attachments"

        return <>
            <div className={classes.attachments}>
                {value.data.slice(0, maxAttachments).map(attachment => <AttachmentThumbnail attachment={attachment} key={attachment.name}/>)}
            </div>
        </>
    }
}

function Table({instance, innerRef}) {
    const classes = useStyles({});
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = instance;

    return <TableContainer component={Paper} elevation={4} className={classes.tableContainer}>
        <MaUTable stickyHeader {...getTableProps()} ref={innerRef}>
            <TableHead className={classes.tableHead}>
                {headerGroups.map(headerGroup =>
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column =>
                            <TableCell
                                component="th"
                                // Return an array of prop objects and react-table will merge them appropriately
                                {...column.getHeaderProps([
                                    {
                                        className: column.className,
                                        style: column.style,
                                    },
                                ])}
                            >
                                <b>{column.render('Header')}</b>
                            </TableCell>
                        )}
                    </TableRow>
                )}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <TableRow {...row.getRowProps(getRowProps(row))}>
                            {row.cells.map(cell =>
                                <TableCell
                                    {...cell.getCellProps([
                                        {
                                            className: cell.column.className,
                                            style: (cell.column.getCellStyle && cell.column.getCellStyle(cell)) || cell.column.style,
                                        },
                                    ])}
                                >
                                    {cell.render('Cell')}
                                </TableCell>
                            )}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
    </TableContainer>
}

function getRowProps(row) {
    return {
        style: {
            minHeight: 62,
            background: row.index % 2 === 0 ? 'rgba(0,0,0,.075)' : 'white',
        },
    }
}
