import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {SnackbarContent} from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        margin: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    msgIcon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    btnIcon: {
        fontSize: 20,
    },
}));

export default function ErrorSnackbar({message}) {
    const classes = useStyles();
    const [open, setOpen] = useState(!!message);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            // autoHideDuration={6000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={classes.error}
                aria-describedby="client-snackbar"
                message={
                    <span id="error-snackbar" className={classes.message}>
                        <ErrorIcon className={classes.msgIcon}/>
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                        <CloseIcon className={classes.icon}/>
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
}