import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {useMetrics} from "../api/work";
import moment from "moment";
import {
    CartesianGrid,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    LineChart
} from "recharts";
import {Status} from "../const/status";
import Typography from "@material-ui/core/Typography";

const statusColors = {
    [Status.Created]: '#E07F00',
    [Status.Complete]: '#1976d2',
}

export default function MetricsView() {
    const {data: {statuses = [], open = []} = {}, loading} = useMetrics();

    return (<>
        <Grid item xs={12} lg={4}>
            <Paper elevation={4} style={{padding: 16}}>
                {!loading && <WorkStatusGraph statuses={statuses} company="TW"/>}
            </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
            <Paper elevation={4} style={{padding: 16}}>
                {!loading && <WorkStatusGraph statuses={statuses} company="WOW"/>}
            </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
            <Paper elevation={4} style={{padding: 16}}>
                {!loading && <WorkStatusGraph statuses={statuses} company="CLW"/>}
            </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
            <Paper elevation={4} style={{padding: 16}}>
                {!loading && <WorkOpenGraph open={open} company="TW"/>}
            </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
            <Paper elevation={4} style={{padding: 16}}>
                {!loading && <WorkOpenGraph open={open} company="WOW"/>}
            </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
            <Paper elevation={4} style={{padding: 16}}>
                {!loading && <WorkOpenGraph open={open} company="CLW"/>}
            </Paper>
        </Grid>
    </>)
}

function facetData(array, facetFunc, valueFunc, filterFunc = () => true) {
    const facets = [...new Set(array.filter(filterFunc).map(facetFunc).filter(m => m).sort())];
    const data = array.filter(filterFunc).reduce((accum, {time, ...others}) => {
        const timeStamp = moment(time).unix()

        const interval = facets.reduce((accum, c) => {
            if (facetFunc(others) === c) {
                accum[c] = valueFunc(others)
            }
            return accum

        }, {time: timeStamp})

        accum[timeStamp] = {...accum[timeStamp], ...interval}
        return accum
    }, {});

    return [facets, Object.values(data)]
}

function WorkStatusGraph({statuses, company}) {
    statuses = statuses.filter(status => ["Created", "Complete"].indexOf(status.status) > -1)
    const [facets, data] = facetData(statuses, d => d.status, d => d.count, d => d.company === company)

    const [opacity, setOpacity] = React.useState(facets.reduce((accum, f) => {
        accum[f] = 1;
        return accum
    }, {}))

    function onMouseEnter({dataKey}) {
        setOpacity(opacity => {
            Object.keys(opacity).forEach(it => opacity[it] = (it === dataKey) ? 1 : 0.25)
            return {...opacity}
        })
    }

    function onMouseLeave() {
        setOpacity(opacity => {
            Object.keys(opacity).forEach(it => opacity[it] = 1)
            return {...opacity}
        })
    }

    return (
        <>
            <Typography variant="h5" style={{textAlign: "center"}}>{company} created and completed work</Typography>
            <ResponsiveContainer height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey="time"
                        tickFormatter={val => moment.unix(val).utc().format('ddd')} // 'M/D hA'
                    />
                    <Legend onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}/>
                    <Tooltip labelFormatter={value => moment.unix(value).utc().format('ll')}/>
                    <YAxis />
                    {facets.map((c, i) => (
                        <Line
                            type="monotone"
                            key={i}
                            name={c}
                            dataKey={c}
                            stroke={statusColors[c] || "#000000"}
                            strokeWidth={3}
                            strokeOpacity={opacity[c]}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}

function WorkOpenGraph({open, company}) {
    const [facets, data] = facetData(open, () => "Open", d => d.count, d => d.company === company)

    const [opacity, setOpacity] = React.useState(facets.reduce((accum, f) => {
        accum[f] = 1;
        return accum
    }, {}))

    function onMouseEnter({dataKey}) {
        setOpacity(opacity => {
            Object.keys(opacity).forEach(it => opacity[it] = (it === dataKey) ? 1 : 0.25)
            return {...opacity}
        })
    }

    function onMouseLeave() {
        setOpacity(opacity => {
            Object.keys(opacity).forEach(it => opacity[it] = 1)
            return {...opacity}
        })
    }

    return (
        <>
            <Typography variant="h5" style={{textAlign: "center"}}>{company} open work</Typography>
            <ResponsiveContainer height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey="time"
                        tickFormatter={val => moment.unix(val).utc().format('ddd')} // 'M/D hA'
                    />
                    <Legend onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}/>
                    <Tooltip labelFormatter={value => moment.unix(value).utc().format('ll')}/>
                    <YAxis />
                    {facets.map((c, i) => (
                        <Line
                            type="monotone"
                            key={i}
                            name={c}
                            dataKey={c}
                            stroke={statusColors[Status.Created]}
                            strokeWidth={3}
                            strokeOpacity={opacity[c]}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}
