import {Typography} from "@material-ui/core";
import React from "react";

export function UserInfoWindow({user: {first_name, last_name}}) {
    return (
        <div>
            <Typography>
                {first_name} {last_name}
            </Typography>
        </div>
    )
}