import React, {createContext, useContext, useMemo, useState} from "react";

const ProgressContext = createContext({loading: false});

export const ProgressProvider = props => {
    const [loading, setLoading] = useState(false);

    const progress = useMemo(() => ({
        loading,
        setLoading: val => {
            setLoading(val);
        },
    }), [loading]);

    return <ProgressContext.Provider value={progress} {...props} />;
};

export const useProgress = () => useContext(ProgressContext);