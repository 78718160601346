import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import useLoading, {useLoading2} from "./useLoading";
import * as rootAxios from "axios";

export const useSingleWork = (initialWorkId) => {
    const [{data: work, loading, error}, setWorkFunc] = useLoading(undefined, !!initialWorkId);
    const [workId, setWorkId] = useState(initialWorkId);

    useEffect(() => {
        if (!workId) {
            return
        }
        setWorkFunc(() => axios => {
            return axios
                .get(`/work/${workId}`)
                .then(res => res.data)
        });
    }, [workId, setWorkFunc]);

    return [{work, loading, error}, setWorkId];
};

export const useMetrics = () => {
    const [{data, loading, error}, setMetricsFunc] = useLoading(undefined, false);

    useEffect(() => {
        setMetricsFunc(() => axios => {
            return axios
                .get(`/work/metrics`)
                .then(res => res.data)
        });
    }, [setMetricsFunc]);

    return {data, loading, error};
};

export const useAssignments = () => {
    const [{data, loading, error}, setAssignmentsFunc] = useLoading(undefined, true);

    useEffect(() => {
        setAssignmentsFunc(() => axios => {
            return axios
                .get(`/work/assignments`)
                .then(res => res.data)
        });
    }, [setAssignmentsFunc]);

    return {data, loading, error};
};

export const useToFixWork = () => {
    const [{data, loading, error}, reqFunc] = useLoading({}, false);

    useEffect(() => {
        reqFunc(() => axios => {
            return axios
                .get(`/work/toFix`)
                .then(res => res.data)
        })
    }, [])

    return {data, loading, error}
}

// {id, address}
export const useSetAddress = () => {
    const [query, setQuery] = useState({});
    const [{data, loading, error}, reqFunc] = useLoading(undefined, false);

    useEffect(() => {
        if (!query.id) return

        reqFunc(() => axios => {
            return axios
                .put(`/work/${query.id}/address`, query.address)
                .then(res => res.data)
        })
    }, [query])

    return [{data, loading, error}, setQuery]
}

// id
export const useAddToOUPS = () => {
    const [query, setQuery] = useState({});
    const [{data, loading, error}, reqFunc] = useLoading(undefined, false);

    useEffect(() => {
        if (!query.id) return

        const {id, ...body} = query;

        reqFunc(() => axios => {
            return axios
                .post(`/work/${id}/addToOUPS`, body)
                .then(res => res.data)
        })
    }, [query])

    return [{data, loading, error}, setQuery]
}

// {id, components}
export const useNewtinGeocodeWork = initialQuery => {
    const [query, setQuery] = useState(initialQuery);
    const [{data, loading, error}, reqFunc] = useLoading({}, false);

    useEffect(() => {
        if (!query) return

        reqFunc(() => axios => {
            return axios
                .post(`/work/newtinGeocode`, query)
                .then(res => res.data)
        })
    }, [query])

    return [{data, loading, error}, setQuery]
}

const initialQueryData = {work: [], clusters: [], totalResults: 0, pageCount: 0};

export const useQueryWork = () => {
    const [req, setReq] = useState({});
    // TODO: Move cancel token logic into useLoading()
    const cancelToken = useRef();
    const [{data, loading, error}, reqFunc] = useLoading(initialQueryData, false, true);

    function executeRequest(axios) {
        return axios
            .get(
                `/work/?query=${new Buffer(JSON.stringify(req.query)).toString('base64')}`,
                {cancelToken: cancelToken.current.token})
            .then(res => ({
                work: res.data.work,
                totalResults: res.data.total,
                clusters: res.data.clusters,
                // pageCount: Math.ceil(res.data.total / args.pageSize)
                pageCount: Math.ceil(res.data.total / req.query.limit)
            }))
    }

    const requestFunc = () => executeRequest;

    function loadData() {
        if (!req.query) {
            return
        }
        const source = rootAxios.CancelToken.source();
        if (cancelToken.current) {
            cancelToken.current.cancel("Cancelling in-flight request")
        }
        cancelToken.current = source

        reqFunc(requestFunc)
    }

    useEffect(loadData, [req]);

    return [{results: data, loading, error}, setReq, loadData]
};

const initialQuery = {}

export const useQueryWork2 = () => {
    const [query, setQuery] = useState(initialQuery);
    // TODO: Move cancel token logic into useLoading()
    const cancelToken = useRef();
    const [{data, loading, error}, setReq] = useLoading2(initialQueryData, false);

    function loadData() {
        if (!query) {
            return
        }
        const source = rootAxios.CancelToken.source();
        if (cancelToken.current) {
            cancelToken.current.cancel("Cancelling in-flight request")
        }
        cancelToken.current = source

        setReq({
            method: "get",
            url: `/work/?query=${new Buffer(JSON.stringify(query)).toString('base64')}`,
            cancelToken: cancelToken.current.token
        })
    }

    useEffect(loadData, [query]);

    return [{results: data, loading, error}, setQuery, loadData]
};

export function useAssignWork() {
    const [{data: work, loading, error}, assignFunc] = useLoading(null, false);
    const [assign, setAssign] = useState({workId: undefined, users: []});

    useEffect(() => {
        if (!assign.workId) return;

        assignFunc(() => axios => {
            return axios
                .post(`/work/${assign.workId}/assign`, {
                    users: assign.users.map(user => ({id: user.id}))
                })
                .then(res => res.data)
        });

    }, [assign, assignFunc]);

    return [{work, loading, error}, setAssign]
}

export function useAutomaticRouting() {
    const [{data: route, loading, error}, load] = useLoading(null, false);
    const [req, setReq] = useState(null);

    useEffect(() => {
        if (!req) return;

        load(() => axios => {
            return axios
                .post(`/work/automaticRouting`, req)
                .then(res => res.data)
        });

    }, [req, load]);

    return [{route, loading, error}, setReq]
}

export function useBulkAssignWork() {
    const [{data: work, loading, error}, assignFunc] = useLoading(null, false);
    const [assign, setAssign] = useState({workIds: [], users: []});

    useEffect(() => {
        if (assign.workIds.length === 0) return;

        assignFunc(() => axios => {
            return axios
                .post(`/work/bulkAssign`, {
                    work_ids: assign.workIds,
                    users: assign.users.map(user => ({id: user.id}))
                })
                .then(res => res.data)
        });

    }, [assign, assignFunc]);

    return [{work, loading, error}, setAssign]
}

export function useCommentWork() {
    const [{data: work, loading, error}, commentFunc] = useLoading(null, false);
    const [comment, setComment] = useState({workId: undefined, message: "", attachments: []});

    useEffect(() => {
        if (!comment.workId) return;

        commentFunc(() => axios => {
            return axios
                .post(`/work/${comment.workId}/comment`, {comment})
                .then(res => res.data)
        });

    }, [comment, commentFunc]);

    return [{work, loading, error}, setComment]
}

export function usePermitWork() {
    const [{data: work, loading, error}, permitFunc] = useLoading(null, false);
    const [permit, setPermit] = useState({workId: undefined, state: "", comment: {message: "", attachments: []}});

    useEffect(() => {
        if (!permit.workId) return;

        permitFunc(() => axios => {
            return axios
                .post(`/work/${permit.workId}/addPermit`, {state: permit.state, comment: permit.comment})
                .then(res => res.data)
        });

    }, [permit, permitFunc]);

    return [{work, loading, error}, setPermit]
}

export function useCancelWork() {
    const [{data: work, loading, error}, commentFunc] = useLoading(null, false);
    const [cancel, setCancel] = useState({workId: undefined, message: "", attachments: []});

    useEffect(() => {
        if (!cancel.workId) return;

        commentFunc(() => axios => {
            return axios
                .post(`/work/${cancel.workId}/cancel`, {comment: cancel})
                .then(res => res.data)
        });

    }, [cancel, commentFunc]);

    return [{work, loading, error}, setCancel]
}

export function useHoldWork() {
    const [{data: work, loading, error}, commentFunc] = useLoading(null, false);
    const [hold, setHold] = useState({workId: undefined, message: "", attachments: []});

    useEffect(() => {
        if (!hold.workId) return;

        commentFunc(() => axios => {
            return axios
                .post(`/work/${hold.workId}/hold`, {
                    on_hold: true,
                    comment: hold
                })
                .then(res => res.data)
        });

    }, [hold, commentFunc]);

    return [{work, loading, error}, setHold]
}

export function useReleaseWork() {
    const [{data: work, loading, error}, commentFunc] = useLoading(null, false);
    const [release, setRelease] = useState({workId: undefined, message: "", attachments: []});

    useEffect(() => {
        if (!release.workId) return;

        commentFunc(() => axios => {
            return axios
                .post(`/work/${release.workId}/hold`, {
                    on_hold: false,
                    comment: release
                })
                .then(res => res.data)
        });

    }, [release, commentFunc]);

    return [{work, loading, error}, setRelease]
}

export function useGetReport(initialReq) {
    const [{data, loading, error}, getFunc] = useLoading(null, false);
    const [req, setReq] = useState(initialReq);

    useEffect(() => {
        if (!req) return

        getFunc(() => axios => {
            return axios
                .get(`${req.url}?json`)
                .then(res => res.data)
        });
    }, [req, getFunc]);

    return [{data, loading, error}, setReq];
}

export function useCompleteWork() {
    const [{data: work, loading, error}, commentFunc] = useLoading(null, false);
    const [complete, setComplete] = useState({workId: undefined, comment: {}, billed: [], investigated: false, previous_drop_depth: undefined});

    useEffect(() => {
        if (!complete.workId) return;

        commentFunc(() => axios => {
            return axios
                .post(`/work/${complete.workId}/complete`, {
                    comment: complete.comment,
                    billed: complete.billed,
                    investigated: complete.investigated,
                    previous_drop_depth: complete.previous_drop_depth,
                })
                .then(res => res.data)
        });

    }, [complete, commentFunc]);

    return [{work, loading, error}, setComplete]
}

export function useQCWork() {
    const [{data: work, loading, error}, qcFunc] = useLoading(null, false);
    const [qc, setQc] = useState({workId: undefined, comment: {}, pass: false, resolution: "", measured_depth: 0});

    useEffect(() => {
        if (!qc.workId) return;

        qcFunc(() => axios => {
            return axios
                .post(`/work/${qc.workId}/qc`, {
                    comment: qc.comment,
                    pass: qc.pass,
                    resolution: qc.resolution,
                    measured_depth: qc.measured_depth,
                })
                .then(res => res.data)
        });

    }, [qc, qcFunc]);

    return [{work, loading, error}, setQc]
}

export function useSkipWork() {
    const [{data: work, loading, error}, commentFunc] = useLoading(null, false);
    const [comment, setComment] = useState({workId: undefined, message: "", attachments: []});

    useEffect(() => {
        if (!comment.workId) return;

        commentFunc(() => axios => {
            return axios
                .post(`/work/${comment.workId}/skip`, {comment})
                .then(res => res.data)
        });

    }, [comment, commentFunc]);

    return [{work, loading, error}, setComment]
}

export function usePatchWork() {
    const [{data: work, loading, error}, patchFunc] = useLoading(null, false);
    const [req, setReq] = useState({workId: undefined, changes: {}});

    useEffect(() => {
        if (!req.workId) return;

        patchFunc(() => axios => {
            return axios
                .patch(`/work/${req.workId}`, req.changes)
                .then(res => res.data)
        });

    }, [req, patchFunc]);

    return [{work, loading, error}, setReq]
}

export function useCreateWork() {
    const [{data: created, loading, error}, createFunc] = useLoading(null, false);
    const [work, setWork] = useState(undefined);

    useEffect(() => {
        if (!work) return;

        createFunc(() => axios => {
            return axios
                .post(`/work/`, work)
                .then(res => res.data)
        });

    }, [work, createFunc]);

    return [{created, loading, error}, setWork]
}

export function useDeleteTransition() {
    const [{data: work, loading, error}, deleteFunc] = useLoading(null, false);
    const [req, setReq] = useState({workId: undefined, transitionId: undefined});

    useEffect(() => {
        if (!req.workId || !req.transitionId) return;

        deleteFunc(() => axios => {
            return axios
                .delete(`/work/${req.workId}/transition/${req.transitionId}`)
                .then(res => res.data)
        });

    }, [req, deleteFunc]);

    return [{work, loading, error}, setReq]
}

const WorkContext = createContext({});
const WorkActionsContext = createContext({});

export const useWork = () => {
    const context = useContext(WorkContext);
    if (context === undefined) {
        throw new Error('useWork must be called within WorkContext.Provider')
    }
    return context
};

export const useWorkActions = () => {
    const context = useContext(WorkActionsContext);
    if (context === undefined) {
        throw new Error('useWorkActions must be called within WorkActionsContext.Provider')
    }
    return context
};

export const WorkProvider = props => {
    const [work, setWork] = useState(undefined);
    const [{work: gotWork, loading, error}, setWorkId] = useSingleWork();

    useEffect(() => {
        if (gotWork) setWork(gotWork);
    }, [gotWork, setWork]);

    return (
        <WorkContext.Provider value={{work, loading, error}}>
            <WorkActionsContext.Provider value={{setWorkId, setWork}}>
                {props.children}
            </WorkActionsContext.Provider>
        </WorkContext.Provider>
    );
};