import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import React from "react";
import Switch from "@material-ui/core/Switch";

export default function RoutingSettingsConfig({settings, setSettings}) {
    return (<>
        <Typography gutterBottom>Require good OUPS</Typography>
        <Switch
            checked={settings.requireGoodOups}
            onChange={() => setSettings({...settings, requireGoodOups: !settings.requireGoodOups})}
            color="primary"
        />
        <Typography variant="caption" color="textSecondary">
            If enabled, only work having an OUPS work date before tomorrow will be selected.
        </Typography>
        <Typography gutterBottom>Max travel distance - {settings.maxTravelDistance} miles</Typography>
        <Typography variant="caption" color="textSecondary">
            Contractor routes will not exceed the maximum travel distance in miles.
        </Typography>
        <Slider
            valueLabelDisplay="auto"
            value={settings.maxTravelDistance}
            onChange={(_, newValue) => setSettings({...settings, maxTravelDistance: newValue})}
            step={5}
            marks
            min={0}
            max={100}
        />
        <Typography gutterBottom>Urgent multiplier - {settings.urgentMultiplier.toFixed(1)}x</Typography>
        <Typography variant="caption" color="textSecondary">
            A multiplier applied the routing weight of urgent jobs, compared to a non-urgent job due the same day.
        </Typography>
        <Slider
            valueLabelDisplay="auto"
            value={settings.urgentMultiplier}
            valueLabelFormat={v => v.toFixed(1)}
            onChange={(_, newValue) => setSettings({...settings, urgentMultiplier: newValue})}
            step={0.1}
            marks
            min={0.1}
            max={3.0}
        />
        <Typography gutterBottom>Cost coefficient - {settings.costCoefficient}</Typography>
        <Typography variant="caption" color="textSecondary">
            How much priority is given to optimizing the route distance. A lower value favors older work.
        </Typography>
        <Slider
            valueLabelDisplay="auto"
            value={settings.costCoefficient}
            onChange={(_, newValue) => setSettings({...settings, costCoefficient: newValue})}
            step={1}
            marks
            min={1}
            max={50}
        />
        <Typography gutterBottom>Solve time - {settings.solveTime} seconds</Typography>
        <Typography variant="caption" color="textSecondary">
            Time allowed to solve the optimization problem.
        </Typography>
        <Slider
            valueLabelDisplay="auto"
            value={settings.solveTime}
            onChange={(_, newValue) => setSettings({...settings, solveTime: newValue})}
            step={10}
            marks
            min={0}
            max={300}
        />
    </>)
}
