import {green, red, yellow} from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    // overrides: {
    //     MuiCssBaseline: {
    //         '@global': {
    //             '*::-webkit-scrollbar': {
    //                 width: '0.6em',
    //                 height: '0.6em',
    //             },
    //             '*::-webkit-scrollbar-track': {
    //                 '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    //             },
    //             '*::-webkit-scrollbar-thumb': {
    //                 backgroundColor: 'rgba(0,0,0,.25)',
    //                 outline: '1px solid slategrey'
    //             }
    //         }
    //     }
    // },
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#00796b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
        admin: {
            main: red[600],
        },
        manager: {
            main: green[700],
        },
        qualityControl: {
            main: yellow[500],
        },
        contractor: {
            main: '#1976d2',
        },
    },
});

export default theme;