import {useEffect, useState} from "react";
import useLoading from "./useLoading";

export const useFeatures = () => {
    const [{data: features, loading, error}, setFeaturesFunc] = useLoading({}, true);

    useEffect(() => {
        setFeaturesFunc(() => axios => {
            return axios
                .get(`/features/`)
                .then(res => res.data)
        });
    }, [setFeaturesFunc]);

    return {features, loading, error}
};

export const useActivateFeature = () => {
    const [{data: updated, loading, error}, submitFunc] = useLoading(undefined, false);
    const [feature, setFeature] = useState(null);

    useEffect(() => {
        if (!feature) {
            return
        }

        submitFunc(() => axios => {
            return axios
                .post(`/features/activate`, feature)
                .then(res => res.data)
        });

    }, [feature, submitFunc]);

    return [{updated, loading, error}, setFeature]
}

export const useDeactivateFeature = () => {
    const [{data: updated, loading, error}, submitFunc] = useLoading(undefined, false);
    const [feature, setFeature] = useState(null);

    useEffect(() => {
        if (!feature) {
            return
        }

        submitFunc(() => axios => {
            return axios
                .post(`/features/deactivate`, feature)
                .then(res => res.data)
        });

    }, [feature, submitFunc]);

    return [{updated, loading, error}, setFeature]
}
