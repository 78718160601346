import MapItems from "./MapItems";
import React from "react";

const markerColors = [
    '#1D6996',
    '#CC503E',
    '#0F8554',
    '#5F4690',
    '#38A6A5',
    '#73AF48',
    '#EDAD08',
    '#E17C05',
    '#94346E',
    '#6F4070',
    '#994E95',
]

export default function MapRoutingPaths(google, users, paths, userColors) {
    function onCreate({first_name, last_name}) {
        const name = `${first_name} ${last_name}`
        let color = userColors[name]
        if (!color) {
            color = markerColors[Object.keys(userColors).length % markerColors.length]
            userColors[name] = color
        }

        return new google.maps.Polyline({
            path: paths[name].map(p => p.point),
            map: google.map,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            geodesic: true,
            icons: [{
                icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
                offset: '100%',
                repeat: '5%'
            }]
        })
    }

    function onRemove(user, polyline) {
        polyline.setMap(null)
    }

    function canShow(user) {
        return true
    }

    const [mapItems, computeMapItems] = MapItems(google, users, onCreate, onRemove, canShow)

    return [mapItems, userColors]
}
