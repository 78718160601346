import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import {useUsers} from "../api/users";
import UserChip from "./UserChip";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import {useAssignWork, useBulkAssignWork} from "../api/work";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ErrorSnackbar from "./ErrorSnackbar";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

const filterOptions = createFilterOptions({
    stringify: option => `${option.first_name} ${option.last_name}`,
});

export default function BulkAssignDialog({workIds, onClose}) {
    const classes = useStyles();

    const {users, loading: usersLoading} = useUsers();
    const [selected, setSelected] = React.useState([]);
    const [{work, loading: assignLoading, error}, apiBulkAssign] = useBulkAssignWork();
    const [loading, setLoading] = React.useState(usersLoading || assignLoading);

    React.useEffect(() => {
        setLoading(usersLoading || assignLoading);
    }, [usersLoading, assignLoading, setLoading])

    React.useEffect(() => {
        work && onClose(work)
    }, [work])

    const submit = () => apiBulkAssign({workIds, users: selected})

    return (
        <Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
            {error && <ErrorSnackbar message={error}/>}
            <DialogTitle>Assign Users</DialogTitle>
            <DialogContent>
                <DialogContentText>Select one or multiple users to bulk assign work orders</DialogContentText>
                <div style={{position: 'relative'/*, minHeight: 80*/}}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>
                    )}
                    <Autocomplete
                        multiple
                        closeIcon={null}
                        loading={loading}
                        disableCloseOnSelect
                        value={selected}
                        getOptionSelected={(a, b) => a.id === b.id}
                        onChange={(event, newValue) => setSelected(newValue)}
                        filterOptions={filterOptions}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => {
                                return <UserChip user={option} {...getTagProps({index})} />
                            })
                        }}
                        getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                        // We have to sort the users according to how they will be grouped by
                        options={users.sort((a, b) => -b.role.localeCompare(a.role))}
                        renderInput={params => (
                            <TextField {...params} variant="standard" label="Assigned users" fullWidth/>
                        )}
                        groupBy={option => option.role}
                        renderOption={(option, {selected}) => {
                            return <>
                                <ListItemAvatar>
                                    <Avatar src={option.avatar}/>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography>{option.first_name} {option.last_name}</Typography>
                                </ListItemText>
                                <Checkbox checked={selected}/>
                            </>
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    color="primary"
                    disabled={loading}
                >
                    Assign
                </Button>
            </DialogActions>
        </Dialog>
    );
}