import {useToFixWork} from "../api/work";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment";
import {Link as RouterLink} from "react-router-dom";
import OUPSLookupDialog from "../components/oups/OUPSLookupDialog";
import OUPSSubmitDialog from "../components/oups/OUPSSubmitDialog";
import Fab from "@material-ui/core/Fab";
import BuildIcon from "@material-ui/icons/Build";
import {useAuth} from "../api/auth";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    fab: {
        zIndex: 1,
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed',
    },
    fabIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function ToFix() {
    const {data, loading, error} = useToFixWork();
    const [companies, setCompanies] = React.useState({});

    React.useEffect(() => {
        setCompanies(data)
    }, [data, setCompanies])

    const classes = useStyles({});
    const [tab, setTab] = React.useState(0);

    if (loading) {
        return <h2>Loading...</h2>
    }

    if (error) {
        return <h1>An error has occurred: {error}</h1>
    }

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                <Tabs
                    variant="fullWidth"
                    value={tab}
                    onChange={(event, newValue) => setTab(newValue)}
                >
                    {Object.keys(companies).map(company =>
                        <Tab label={`${company} (${companies[company].length})`} key={company}/>
                    )}
                </Tabs>
            </AppBar>
            {Object.keys(companies).map((company, idx) =>
                <TabPanel value={tab} index={idx} key={company}>
                    <FixList
                        works={companies[company]}
                        setWorks={works => setCompanies({...companies, [company]: works})}
                    />
                </TabPanel>
            )}
        </div>
    )
}

function FixList({works, setWorks}) {
    const classes = useStyles({});

    const {axios} = useAuth();

    const [lookupWork, setLookupWork] = React.useState()
    const [submitWork, setSubmitWork] = React.useState()

    function onWorkChanged(updatedWork) {
        setWorks(works.filter(work => work.id !== updatedWork.id))
    }

    function onWorkSelected(work, skipLookup) {
        if (skipLookup) {
            setSubmitWork(work)
        } else {
            setLookupWork(work)
        }
    }

    function onLookupClosed(updatedWork, submitOUPS) {
        setLookupWork(null)
        if (submitOUPS) {
            setSubmitWork(updatedWork)
        } else if (updatedWork) {
            onWorkChanged(updatedWork)
        }
    }

    function onSubmitClosed(updatedWork) {
        setSubmitWork(null)
        updatedWork && onWorkChanged(updatedWork)
    }

    async function oupsAllMissing() {
        let missing = works.filter(work => {
            return !!work.geocode_error || work.oups.length === 0 || !work.address
        })

        await Promise.all(missing.map(work => {
            return axios.post(`/work/${work.id}/geocodeAndOUPS`)
        }))
        alert("OUPSing in progress, check back in a few minutes")
    }

    return (
        <>
            <Fab variant="extended" className={classes.fab} color="secondary" onClick={() => oupsAllMissing()}>
                <BuildIcon className={classes.fabIcon}/>
                Attempt to OUPS all missing
            </Fab>
            {lookupWork &&
                <OUPSLookupDialog
                    allowOUPSSubmit
                    work={lookupWork}
                    onClose={onLookupClosed}
                />
            }
            {submitWork &&
                <OUPSSubmitDialog
                    work={submitWork}
                    onClose={onSubmitClosed}
                />
            }
            <List>
                {works.map(work => {
                    const {id, geocode_error, address, oups} = work;

                    let skipLookup = false
                    let error = ""
                    if (!address) {
                        error = `Missing address ${geocode_error && '-'} ${geocode_error}`
                    } else if (!oups?.length) {
                        error = `Missing OUPS`
                        // skipLookup = true
                    } else if (oups[0]?.error) {
                        error = `OUPS ${oups[0].type} error - ${oups[0].error}`
                    }

                    return <WorkErrorListItem
                        key={id}
                        work={work}
                        error={error}
                        onClick={() => onWorkSelected(work, skipLookup)}
                    />
                })}
            </List>
        </>
    )
}

function WorkErrorListItem({work, error, onClick}) {
    const {id, company, order_num, created_at, raw_address} = work;

    return (
        <ListItem divider button onClick={onClick}>
            <ListItemText
                primary={
                    <><RouterLink to={`/work/${id}`}>{company} #{order_num}</RouterLink> - {raw_address}</>
                }
                secondary={
                    <>
                        <Typography component="span" display="block">
                            Created {moment(created_at).format('lll')}
                        </Typography>
                        <Typography color="error" component="span" display="block">
                            {error}
                        </Typography>
                    </>
                }
            />
        </ListItem>
    )
}
