import React, {useState, useEffect} from "react";
import markerUrl from "../../util/markerUrl";

const defaultSelectedMarkers = []

export default function MapDrawing(google, markers, isDrawing) {
    const [drawnOverlay, setDrawnOverlay] = useState(null); // {overlay, polygon}
    const [drawingManager, setDrawingManager] = useState(null);
    const [selectedMarkers, setSelectedMarkers] = useState(defaultSelectedMarkers);

    useEffect(() => {
        if (isDrawing) {
            drawingManager.setDrawingMode('polygon')
        } else {
            drawingManager && drawingManager.setDrawingMode(null)
            drawnOverlay && drawnOverlay.overlay.setMap(null)
            setDrawnOverlay(null)
        }
    }, [isDrawing])

    // If there's a drawn overlay, set selected to all that items within the overlay
    useEffect(() => {
        if (drawnOverlay) {
            setSelectedMarkers(markers.filter(({mapItem}) => {
                const inside = google.maps.geometry.poly.containsLocation(mapItem.getPosition(), drawnOverlay.overlay)
                if (inside) {
                    mapItem.oldIcon = mapItem.getIcon();
                    mapItem.setIcon(markerUrl("black"))
                }
                return inside
            }))
        } else {
            // Effect to reset selected item icons if they're being emptied
            selectedMarkers.forEach(({mapItem}) => {
                mapItem.setIcon(mapItem.oldIcon)
                delete mapItem.oldIcon
            })
            setSelectedMarkers([])
        }
    }, [drawnOverlay, markers])

    return [setDrawingManager, setDrawnOverlay, selectedMarkers]
}

MapDrawing.whyDidYouRender = true
