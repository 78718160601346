import Dialog from "@material-ui/core/Dialog";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import {useAutomaticRouting} from "../api/work";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RoutingSettingsConfig from "./RoutingSettingsConfig";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

const initialSettings = {
    maxTravelDistance:0,
    urgentMultiplier:1.2,
    costCoefficient:1,
    solveTime:120,
    requireGoodOups: false,
}

export default function RoutingDialog({open, onClose}) {
    const classes = useStyles();

    const [company, setCompany] = React.useState("TW")
    const [settings, setSettings] = React.useState(initialSettings)
    const [{route, loading, error}, routeFunc] = useAutomaticRouting()

    function submit(preview) {
        routeFunc({
            preview,
            company,
            urgentMultiplier: settings.urgentMultiplier,
            costCoefficient: settings.costCoefficient,
            solveTime: settings.solveTime,
            maxTravelDistance: settings.maxTravelDistance,
            requireGoodOups: settings.requireGoodOups,
        })
    }

    React.useEffect(() => {
        route && onClose(route)
    }, [route])

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Automatic Routing</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Adjust the below settings to preview automatic routing.
                </DialogContentText>
                <DialogContentText>
                    If 'Preview' is selected, automatically routed work will not be assigned to contractors.
                </DialogContentText>
                <DialogContentText>
                    The current map filters will be ignored.
                </DialogContentText>
                <DialogContentText>
                    Routing may take up to several minutes.
                </DialogContentText>
                <DialogContentText>
                    Only contractors and work for the chosen company will be selected for routing.
                </DialogContentText>
                <DialogContentText>
                    Only open work and work with a geocoded address will be selected for routing.
                </DialogContentText>
                <div style={{position: 'relative'}}>
                    {loading && <div className={classes.loadingContainer}>
                        <CircularProgress size={64}/>
                    </div>}
                    <FormControl style={{marginTop: 16, marginBottom: 16}}>
                        <FormLabel>Company</FormLabel>
                        <RadioGroup
                            row
                            value={company}
                            onChange={event => setCompany(event.target.value)}
                        >
                            <FormControlLabel value="TW" control={<Radio color="primary"/>} label="TW"/>
                            <FormControlLabel value="WOW" control={<Radio color="primary"/>} label="WOW"/>
                            <FormControlLabel value="CLW" control={<Radio color="primary"/>} label="CLW"/>
                            <FormControlLabel value="ATT" control={<Radio color="primary"/>} label="ATT"/>
                        </RadioGroup>
                    </FormControl>
                    <RoutingSettingsConfig settings={settings} setSettings={setSettings}/>
                </div>
                {error && <Alert elevation={6} variant="filled" severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Dismiss</Button>
                <Button onClick={() => submit(true)} color="primary">Preview</Button>
                <Button onClick={() => submit(false)} color="primary">Route</Button>
            </DialogActions>
        </Dialog>
    )
}