import {useEffect, useState} from "react";
import useLoading from "./useLoading";

export const useUsers = () => {
    const [{data: users, loading, error}, setUsersFunc] = useLoading([], true);

    useEffect(() => {
        setUsersFunc(() => axios => {
            return axios
                .get(`/users/`)
                .then(res => res.data)
        });
    }, [setUsersFunc]);

    return {users, loading, error}
};

export const useCreateUpdateUser = () => {
    const [{data: newUser, loading, error}, submitFunc] = useLoading([], true);
    const [user, addUser] = useState(null);

    useEffect(() => {
        if (!user) {
            return
        }

        submitFunc(() => axios => {
            if (user.id) {
                return axios
                    .patch(`/users/${user.id}`, user)
                    .then(res => res.data)
            } else {
                return axios
                    .post(`/users/`, user)
                    .then(res => res.data)
            }
        });

    }, [user, submitFunc]);

    return [{newUser, loading, error}, addUser]
};

export const defaultUser = {
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    address: "",
    location: {},
    company: null,
    technician_id: null,
    max_assigned_work: null,
    disabled: false,
};
