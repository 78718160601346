import MapItems from "./MapItems";

const markerColors = [
    '#1D6996',
    '#CC503E',
    '#0F8554',
    '#5F4690',
    '#38A6A5',
    '#73AF48',
    '#EDAD08',
    '#E17C05',
    '#94346E',
    '#6F4070',
    '#994E95',
]

const path = "M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"

export default function MapRoutingUsers(google, users, userColors, onClick) {
    function onCreate(user) {
        const {first_name, last_name, location: {lat, lng}} = user;

        const name = `${first_name} ${last_name}`
        let color = userColors[name]
        if (!color) {
            color = markerColors[Object.keys(userColors).length % markerColors.length]
            userColors[name] = color
        }

        const marker =  new google.maps.Marker({
            position: {lat, lng},
            map: google.map,
            icon: {
                path: path,
                fillColor: color,
                fillOpacity: 1.0,
                scale: 1.25,
                anchor: new google.maps.Point(12, 22),
                labelOrigin: new google.maps.Point(12, 10),
            },
        })

        marker.addListener('click', () => onClick(user, marker))

        return marker
    }

    function onRemove(user, marker) {
        marker.setMap(null)
    }

    function canShow(user) {
        return true
    }

    const [mapItems, computeMapItems] = MapItems(google, users, onCreate, onRemove, canShow)

    return [mapItems, userColors]
}
