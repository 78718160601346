import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {WorkProvider} from "../api/work";
import UserACL, {Role} from "../components/UserACL";
import HomeWorkView from "../components/HomeWorkView";
import MetricsView from "../components/MetricsView";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    paper: {
        display: 'flex',
        flexGrow: 1,
        minHeight: 160,
        height: '100%',
    },
}));

function Home() {
    const classes = useStyles();

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Container maxWidth={false} className={classes.root}>
            <Grid container spacing={isSmall ? 0 : 2}>
                {/*<Grid item xs={12}>*/}
                {/*    <Paper className={classes.root}>*/}
                {/*        <Typography variant="h4">*/}
                {/*            Welcome to the Roush Data App*/}
                {/*        </Typography>*/}
                {/*        <Typography>*/}
                {/*            Click the drawer icon to navigate*/}
                {/*        </Typography>*/}
                {/*    </Paper>*/}
                {/*</Grid>*/}
                {/*<UserACL role={Role.Contractor}>*/}
                <HomeWorkView/>
                {/*</UserACL>*/}
                <UserACL role={Role.Manager}>
                    <MetricsView/>
                </UserACL>
            </Grid>
        </Container>
    )
}

export default function (props) {
    return <WorkProvider><Home {...props}/></WorkProvider>
}