import React from "react";
import {useAuth} from "../api/auth";

export const Role = {
    Script: "Script",
    Admin: "Admin",
    Manager: "Manager",
    QualityControl: "Quality Control",
    Contractor: "Contractor",
};

const roles = [Role.Script, Role.Admin, Role.Manager, Role.QualityControl, Role.Contractor];

export default function UserACL({role, children, exact}) {
    const {user} = useAuth();

    if (!user) {
        return <></>;
    }

    const allowedRole = roles.indexOf(role);
    if (allowedRole === -1) {
        throw new Error(`Minimum role ${role} not found`);
    }

    const currentRole = roles.indexOf(user.role);
    if (currentRole === -1) {
        throw new Error(`User role ${user.role} not found`);
    }

    if (exact && currentRole === allowedRole) {
        return children;
    } else if (!exact && currentRole <= allowedRole) {
        return children;
    }

    return <></>;
}