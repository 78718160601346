import React from "react";
import UploadFile from "../util/uploadFile";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {AttachFile} from "@material-ui/icons";
import Attachment from "./Attachment";

export default function AddAttachments({id, accept, label, attached, setAttached, ...other}) {
    const fileInput = React.useRef();

    async function fileChanged({target: {files}}) {
        const newFiles = await Promise.all([...files].map(file => new UploadFile(file).resizeImage()));
        setAttached(attached.concat(newFiles));
        // Reset the file input DOM element to empty so we can avoid issues removing and readding files later
        fileInput.current.value = "";
    }

    function removeFile(idx) {
        setAttached(attached.filter((_, i) => i !== idx));
    }

    return (
        <>
            <input
                style={{display: 'none'}}
                id={id}
                type="file"
                multiple
                accept="image/*" //{accept}
                ref={fileInput}
                onChange={fileChanged}
            />
            {label && <label htmlFor={id}>
                <ListItem button {...other}>
                    <ListItemText primary={label}/>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" component="span">
                            <AttachFile/>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </label>}
            {attached.map((attach, i) =>
                <Attachment
                    key={i}
                    name={attach.name}
                    size={attach.size}
                    type={attach.type}
                    url={attach.url}
                    onDelete={() => removeFile(i)}
                />
            )}
        </>
    )
}