import React, {useEffect, useState} from "react";
import {useQueryWork, useQueryWork2} from "../api/work";
import {googleMapConfig} from "../Config";
import GoogleMapReact from "google-map-react";
import {makeStyles} from "@material-ui/core/styles";
import {Switch, Typography} from "@material-ui/core";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {WorkInfoWindow} from "../components/WorkInfoWindow";
import RoutingDialog from "../components/RoutingDialog";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MapWorkMarkers from "../components/map/MapWorkMarkers";
import MapWorkAreas from "../components/map/MapWorkAreas";
import MapInfoWindow from "../components/map/MapInfoWindow";
import MapFilters, {translateMapFilters} from "../components/map/MapFilters";
import MapDrawing from "../components/map/MapDrawing";
import DrawingManager from "../components/DrawingManager";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import BulkAssignDialog from "../components/BulkAssignDialog";
import List from "@material-ui/core/List";
import MapRoutingUsers from "../components/map/MapRoutingUsers";
import MapRoutingPaths from "../components/map/MapRoutingPaths";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {UserInfoWindow} from "../components/UserInfoWindow";

const useStyles = makeStyles(theme => ({
    filterHeader: {
        padding: theme.spacing(2),
    },
    filterContainer: {
        position: 'relative',
        height: '100%',
        overflowY: 'scroll'
    },
    routingTitle: {
        padding: 16
    },
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
    mapButton: {
        display: "block",
        marginTop: 10,
        marginLeft: 10,
    },
    white: {
        color: theme.palette.getContrastText("#FFFFFF"),
        backgroundColor: "#FFFFFF",
        '&:hover': {
            backgroundColor: "#EBEBEB",
        }
    },
    // TODO: https://github.com/mui-org/material-ui/issues/10076#issuecomment-361232810
    container: {
        height: 'calc(100vh - 48px)'
    },
    mapContainer: {
        minHeight: 320,
        height: '100%',
        position: 'relative',
    }
}));

const defaultQueryArgs = {limit: 5000, offset: 0, sort: [], filter: {}};
const defaultMapCenter = {lat: 40.0459, lng: -82.9828};

function mapOptions(maps) {
    return {
        mapTypeControl: true,
        gestureHandling: 'greedy'
    }
}

const initialWork = []
export default function Map() {
    const classes = useStyles();

    const [{results, loading, error}, queryWork, forceLoadData] = useQueryWork2();
    const [google, setGoogle] = useState(null);
    const [work, setWork] = useState(initialWork)
    const [routingDialogOpen, setRoutingDialogOpen] = useState(false)
    const [routing, setRouting] = useState({users: [], paths: {}})
    const [hideIneligible, setHideIneligible] = useState(false)

    const [infoWindowComponent, openWindowAtMarker] = MapInfoWindow(google, "infoWindowContent")
    const onMarkerClick = (work, marker) => openWindowAtMarker(marker, <WorkInfoWindow work={work}/>)

    const [userInfoWindow, openUserInfoWindow] = MapInfoWindow(google, "userInfoWindow")
    const onUserClick = (user, marker) => openUserInfoWindow(marker, <UserInfoWindow user={user} />)

    const [markers, userColors] = MapWorkMarkers(google, work, hideIneligible, onMarkerClick)
    const mapRoutingUsers = MapRoutingUsers(google, routing.users, userColors, onUserClick)
    const mapRoutingPaths = MapRoutingPaths(google, routing.users, routing.paths, userColors)
    const workAreas = MapWorkAreas(google, work, hideIneligible)

    const [isDrawing, setIsDrawing] = useState(false);
    const [setDrawingManager, onOverlayComplete, selectedMarkers] = MapDrawing(google, markers, isDrawing)

    function onCloseRoutingDialog(result) {
        setRoutingDialogOpen(false)
        if (result) {
            const {users, work, paths, skip_reasons} = result;
            skip_reasons.forEach((reason, i) => {
                if (reason !== "") {
                    work[i].skip_reason = reason
                }
            })
            setWork(work)
            setRouting({users, paths})
        } else {
            setWork([])
            setRouting({users: [], paths: {}})
            // Reload the map from the filters
            forceLoadData()
        }
    }

    useEffect(() => {
        // TODO: We should list the work missing addresses somewhere
        results && results.work && setWork(results.work.filter(w => w.address))
    }, [results])

    function onAssignClose(works) {
        setIsDrawing(false)

        // Refresh the map data when assigned users change
        works && forceLoadData()
    }

    const applyFilters = React.useCallback(filters => {
        queryWork({...defaultQueryArgs, filter: translateMapFilters(filters)});
    }, [queryWork]);

    if (error) {
        return <h1>An error has occurred: {error}</h1>
    }

    return (
        <Grid container className={classes.container}>
            {selectedMarkers.length > 0 && <BulkAssignDialog
                onClose={onAssignClose}
                workIds={selectedMarkers.map(({work}) => work.id)}
            />}
            <RoutingDialog
                open={routingDialogOpen}
                onClose={onCloseRoutingDialog}
            />
            {routing.users.length > 0 && <Grid item xs={12} md={2}>
                <AutoRoutingStats
                    work={work}
                    paths={routing.paths}
                    hideIneligible={hideIneligible}
                    setHideIneligible={setHideIneligible}
                />
            </Grid>}
            <Grid item xs={12} md={routing.users.length > 0 ? 8 : 10}>
                <div className={classes.mapContainer}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>
                    )}
                    {infoWindowComponent}
                    {userInfoWindow}
                    <GoogleMapReact
                        bootstrapURLKeys={googleMapConfig}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={setGoogle}
                        defaultCenter={defaultMapCenter}
                        defaultZoom={11}
                        options={mapOptions}
                    />
                    <DrawingManager
                        google={google}
                        onOverlayComplete={onOverlayComplete}
                        setDrawingManager={setDrawingManager}
                    >
                        <Button
                            onClick={() => setIsDrawing(!isDrawing)}
                            className={clsx(classes.mapButton, classes.white)}
                            variant="contained"
                        >
                            {isDrawing ? "Stop" : "Start"} bulk assign
                        </Button>
                        {routing.users.length > 0 ?
                            <Button
                                onClick={() => onCloseRoutingDialog()}
                                className={clsx(classes.mapButton, classes.white)}
                                variant="contained"
                            >
                                Reset Automatic Routing
                            </Button>
                            :
                            <Button
                                onClick={() => setRoutingDialogOpen(true)}
                                className={clsx(classes.mapButton, classes.white)}
                                variant="contained"
                            >
                                Automatic Routing
                            </Button>
                        }
                    </DrawingManager>
                </div>
            </Grid>
            <Grid item xs={12} md={2} className={classes.filterContainer}>
                <Typography variant="h5" className={classes.filterHeader}>Filters</Typography>
                <MapFilters onChange={applyFilters} userColors={userColors}/>
            </Grid>
        </Grid>
    )
}

function AutoRoutingStats({work, paths, hideIneligible, setHideIneligible}) {
    const sortedWork = work.sort((a, b) => moment(a.due).unix() - moment(b.due).unix())

    const ineligible = {}
    const eligible = sortedWork.filter(({skip_reason}) => {
        if (skip_reason) {
            ineligible[skip_reason] = (ineligible[skip_reason] || 0) + 1
            return false
        }
        return true
    })

    const notRouted = {}
    const routed = {}
    eligible.forEach(({due: dueStr, assigned_user}) => {
        const due = moment(dueStr).utc().format('l')
        if (assigned_user) {
            if (!routed[assigned_user]) {
                routed[assigned_user] = {}
            }
            routed[assigned_user][due] = (routed[assigned_user][due] || 0) + 1
        } else {
            notRouted[due] = (notRouted[due] || 0) + 1
        }
    })

    const sumValues = obj => Object.values(obj).reduce((accum, v) => accum + v, 0)

    return (
        <List>
            <ListItem button key="ineligible_toggle" onClick={() => setHideIneligible(!hideIneligible)}>
                <ListItemText secondary="Hides the ineligible (grey) pins from the map">
                    Hide ineligible work
                </ListItemText>
                <ListItemSecondaryAction>
                    <Switch checked={hideIneligible} onChange={() => setHideIneligible(!hideIneligible)} />
                </ListItemSecondaryAction>
            </ListItem>

            {sumValues(ineligible) > 0 && <>
                <ListItem key="ineligible">
                    <ListItemText secondary={<>{sumValues(ineligible)} work orders</>}>
                        Ineligible for routing
                    </ListItemText>
                </ListItem>
                {Object.keys(ineligible).sort().map(reason =>
                    <Typography key={reason} style={{paddingLeft: 32}}>
                        <strong>{reason}: </strong>{ineligible[reason]}
                    </Typography>
                )}
            </>}

            {sumValues(notRouted) && <>
                <ListItem key="not_routed">
                    <ListItemText secondary={<>{sumValues(notRouted)} work orders</>}>
                        Not routed
                    </ListItemText>
                </ListItem>
                {Object.keys(notRouted).map(due =>
                    <Typography key={`not_routed_${due}`} style={{paddingLeft: 32}}>
                        <strong>{due}: </strong>{notRouted[due]}
                    </Typography>
                )}
            </>}

            {Object.keys(routed).map(contractor => {
                const distance = paths[contractor].reduce((accum, p) => accum + p.distance, 0)
                return (<>
                        <ListItem key={contractor}>
                            <ListItemText secondary={<>
                                {sumValues(routed[contractor])} work orders
                                {` - Approx. ${Math.round(distance / 1609.34)} mi`}
                            </>}>
                                {contractor}
                            </ListItemText>
                        </ListItem>
                        {Object.keys(routed[contractor]).map(due =>
                            <Typography key={`${contractor}_${due}`} style={{paddingLeft: 32}}>
                                <strong>{due}: </strong>{routed[contractor][due]}
                            </Typography>
                        )}
                    </>)
                }
            )}
        </List>
    )
}