import React, {createContext, useContext, useState} from "react";
import {baseAPIUrl} from "../Config";
import * as rootAxios from "axios";

const AuthContext = createContext({});
const AuthActionsContext = createContext({});

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be called within AuthContext.Provider')
    }
    return context
};

export const useAuthActions = () => {
    const context = useContext(AuthActionsContext);
    if (context === undefined) {
        throw new Error('useAuthActions must be called within AuthActionsContext.Provider')
    }
    return context
};

export const AuthenticationProvider = props => {
    const axios = rootAxios.create({
        baseURL: baseAPIUrl,
        withCredentials: true
    })
    const [state, setState] = useState({user: undefined, loading: true, axios, error: undefined});

    function login(token) {
        setState(prev => ({...prev, loading: true, user: undefined, error: undefined}));
        // setState({loading: true, user: undefined, error: undefined});

        axios({method: 'post', url: "/users/login", headers: {token}}).then(res => {
            setState(prev => ({user: res.data, loading: false, axios: axios, error: false}));
        }).catch(err => {
            console.log(err);
            const {response: {data: {message} = {}} = {}} = err;
            const errMsg = (message || String(err));
            console.log("login error:", errMsg);
            setState({user: undefined, loading: false, axios: undefined, error: errMsg})
        })
    }

    function getCurrentUser(ignoreError) {
        setState(prev => ({...prev, loading: true, user: undefined, error: undefined}));

        axios.get('/users/current').then(res => {
            window.newrelic.setCustomAttribute("user", `${res?.data?.first_name} ${res?.data?.last_name}`)
            setState(prev => ({user: res.data, loading: false, axios: axios, error: false}));
        }).catch(err => {
            console.log(err);
            const {response: {data: {message} = {}} = {}} = err;
            const errMsg = (message || String(err));
            console.log("get current user error:", errMsg);
            if (!ignoreError) {
                // setState(prev => ({...prev, ...{error: errMsg}}));
                setState({user: undefined, loading: false, axios: undefined, error: errMsg})
            } else {
                // setState(prev => ({...prev, ...{loading: false}}));
                setState({user: undefined, loading: false, axios: undefined, error: undefined});
            }
        })
    }

    function logout() {
        setState(prev => ({...prev, loading: true, error: undefined}));
        axios.post(`/users/logout`).then(res => {
            setState(prev => ({user: undefined, loading: false, axios: axios, error: false}));
        }).catch(err => {
            console.log(err);
            const {response: {data: {message} = {}} = {}} = err;
            const errMsg = (message || String(err));
            console.log("logout error:", errMsg);
            setState({user: undefined, loading: false, axios: undefined, error: errMsg})
        })
    }

    // useEffect will be called just once when the AuthenticationProvider is created to attempt auto login
    React.useEffect(() => {
        getCurrentUser(true);
    }, []);

    return (
        <AuthContext.Provider value={state}>
            <AuthActionsContext.Provider value={{login, logout}}>
                {props.children}
            </AuthActionsContext.Provider>
        </AuthContext.Provider>
    );
};
