import GoogleMapReact from "google-map-react";
import React, {useState} from "react";
import {googleMapConfig} from "../Config";
import ReactDOM from "react-dom";
import Typography from "@material-ui/core/Typography";

// TODO: We can move some stuff from the InfoCard to this InfoWindow
function InfoWindow({formatted}) {
    return (
        <div>
            <Typography>{formatted}</Typography>
        </div>
    )
}

export default function MapSingle({workArea, points, openWindow, address, addressOverride, gestureHandling, defaultZoom = 16}) {
    const [google, setGoogle] = useState(null);
    const [infoWindowComponent, setInfoWindowComponent] = useState(null);
    const [marker, setMarker] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [line, setLine] = useState(null);
    const [polygon, setPolygon] = useState(null);
    const [infoWindowId] = useState("infoWindowContent_"+Date.now())

    React.useMemo(() => {
        if (!google) return;

        const singlePoint = !points || points.length === 1
        const {lat, lng, formatted} = address;

        const newMarker = singlePoint ? new google.maps.Marker({
            position: {lat, lng},
            map: google.map,
            title: formatted,
        }) : null;

        setMarker(oldMarker => {
            if (oldMarker) oldMarker.setMap(null)
            return newMarker
        })

        const newLine = singlePoint ? null : new google.maps.Polyline({
            map: google.map,
            path: points,
            strokeColor: "#FF0000",
            strokeOpacity: 0.9,
            strokeWeight: 8,
            geodesic: true,
        });

        setLine(oldLine => {
            if (oldLine) oldLine.setMap(null)
            return newLine
        })

        const newInfoWindow = singlePoint ? new google.maps.InfoWindow({
            content: `<div id="${infoWindowId}"/>`,
        }) : null;

        setInfoWindow(oldInfoWindow => {
            if (oldInfoWindow) oldInfoWindow.setMap(null);
            return newInfoWindow
        })

        if (singlePoint) {
            newInfoWindow.addListener("closeclick", () => {
                newInfoWindow.setMap(null);
            });

            const openInfoWindow = () => {
                newInfoWindow.open(google.map, newMarker);
            };

            newMarker.addListener('click', () => {
                openInfoWindow();
            });

            // To get context passing in, we need to use ReactDOM.createPortal to create the component
            const listener = newInfoWindow.addListener("domready", function () {
                listener.remove();
                const el = document.getElementById(infoWindowId);
                if (!el) return; // Fix an edge case where the component is closing
                const component = <InfoWindow formatted={addressOverride || formatted}/>;
                setInfoWindowComponent(ReactDOM.createPortal(component, el));
            });

            openWindow && openInfoWindow();
        }

        const newPolygon = workArea ? new google.maps.Polygon({
            map: google.map,
            paths: google.maps.geometry.encoding.decodePath(workArea),
            strokeColor: '#0000FF',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            fillColor: '#0000FF',
            fillOpacity: 0.35
        }) : null

        setPolygon(oldPolygon => {
            if (oldPolygon) oldPolygon.setMap(null)
            return newPolygon
        })
    }, [google, points, address, workArea]);

    return (
        <>
            {infoWindowComponent}
            <GoogleMapReact
                bootstrapURLKeys={googleMapConfig}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={setGoogle}
                center={address}
                defaultZoom={defaultZoom}
                options={maps => ({
                    mapTypeId: 'hybrid',
                    mapTypeControl: true,
                    streetViewControl: true,
                    gestureHandling: gestureHandling,
                })}
            />
        </>
    );
}