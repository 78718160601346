import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {DatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import {useCreateWork} from "../api/work";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackbar from "../components/ErrorSnackbar";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    cardContent: {
        padding: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    wrapper: {
        marginLeft: 'auto',
        margin: theme.spacing(1),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const initialWork = {
    company: 'WOW',
    order_num: '',
    job_number: '',
    subaccount_number: '',
    bore: false,
    raw_address: '',
    entered: moment(),
    customer: {
        name: '',
        home_phone: '',
        account: '',
    },
    technician_comments: '',
};

const initialInputErrors = {
    raw_address: null,
    entered: null,
    order_num: null,
};

export default function CreateWork() {
    const classes = useStyles({});
    const history = useHistory();

    const [work, setWork] = React.useState(initialWork);

    const [inputErrors, setInputErrors] = React.useState(initialInputErrors);
    const [{created, loading, error}, createFunc] = useCreateWork();

    React.useEffect(() => {
        if (!created) return;
        history.push(`/work/${created.id}`)
    }, [created]);

    function validateInput() {
        const newInputErrors = {...initialInputErrors};
        let valid = true;
        if (!work.raw_address) {
            newInputErrors.raw_address = "Address is required"
            valid = false
        }
        if (!work.entered) {
            newInputErrors.entered = "Entered date is required"
            valid = false
        }
        if (!work.order_num) {
            newInputErrors.order_num = "Order number is required"
            valid = false
        }
        setInputErrors(newInputErrors)
        return valid
    }

    function onChange(newFields) {
        setWork(work => ({...work, ...newFields}))
        validateInput()
    }

    function handleSubmit() {
        if (!validateInput()) {
            return
        }
        createFunc({
            ...work,
            technician_comments: work.technician_comments === '' ? [] : [{message: work.technician_comments}]
        })
    }

    return (
        <Container maxWidth="sm" style={{paddingTop: 16}}>
            {error && <ErrorSnackbar message={error}/>}
            <Card raised={true}>
                <CardHeader
                    title="Create Work Order"
                    subheader="Fields marked with an asterisk are required"
                />
                <CardContent className={classes.cardContent}>
                    <FormControl style={{marginTop: 16}} fullWidth>
                        <FormLabel>Company</FormLabel>
                        <RadioGroup
                            row
                            value={work.company}
                            onChange={event => onChange({company: event.target.value})}
                        >
                            <FormControlLabel value="TW" control={<Radio color="primary"/>} label="TW"/>
                            <FormControlLabel value="WOW" control={<Radio color="primary"/>} label="WOW"/>
                            <FormControlLabel value="CLW" control={<Radio color="primary"/>} label="CLW"/>
                            <FormControlLabel value="ATT" control={<Radio color="primary"/>} label="ATT"/>
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel
                        style={{}}
                        control={
                            <Switch
                                checked={work.bore}
                                onChange={() => onChange({bore: !work.bore})}
                                color="primary"
                            />
                        }
                        label="Bore"
                    />
                    <TextField
                        label="Address"
                        fullWidth
                        margin="normal"
                        multiple
                        error={!!inputErrors.raw_address}
                        helperText={inputErrors.raw_address}
                        value={work.raw_address}
                        onChange={ev => onChange({raw_address: ev.target.value})}
                        required
                    />
                    <DatePicker
                        showTodayButton
                        required
                        margin="normal"
                        label="Entered date"
                        format="ll"
                        fullWidth
                        error={!!inputErrors.entered}
                        helperText={inputErrors.entered}
                        value={work.entered}
                        onChange={d => onChange({entered: d})}
                    />
                    <TextField
                        label="Order Number (usually 16 digits)"
                        fullWidth
                        margin="normal"
                        value={work.order_num}
                        onChange={ev => onChange({order_num: ev.target.value})}
                        error={!!inputErrors.order_num}
                        helperText={inputErrors.order_num}
                        required
                    />
                    <TextField
                        label="Job Number (Optional, usually 6 digits)"
                        fullWidth
                        margin="normal"
                        value={work.job_number}
                        onChange={ev => onChange({job_number: ev.target.value})}
                    />
                    <TextField
                        label="Subaccount Number (Optional, usually 16 digits)"
                        fullWidth
                        margin="normal"
                        value={work.subaccount_number}
                        onChange={ev => onChange({subaccount_number: ev.target.value})}
                    />
                    <TextField
                        label="Customer Name"
                        fullWidth
                        margin="normal"
                        value={work.customer.name}
                        onChange={ev => onChange({customer: {...work.customer, name: ev.target.value}})}
                    />
                    <TextField
                        type="tel"
                        label="Customer Phone"
                        fullWidth
                        margin="normal"
                        value={work.customer.home_phone}
                        onChange={ev => onChange({customer: {...work.customer, home_phone: ev.target.value}})}
                    />
                    <TextField
                        label="Customer Account"
                        fullWidth
                        margin="normal"
                        value={work.customer.account}
                        onChange={ev => onChange({customer: {...work.customer, account: ev.target.value}})}
                    />
                    <TextField
                        multiline
                        fullWidth
                        margin="normal"
                        value={work.technician_comments}
                        onChange={ev => onChange({technician_comments: ev.target.value})}
                        label="Technician comments"
                    />
                </CardContent>
                <CardActions>
                    <div className={classes.wrapper}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Submit
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </div>
                </CardActions>
            </Card>
        </Container>
    )
}
