import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from 'clsx';
import {Role} from "./UserACL";

const styles = theme => {
    return {
        [Role.Admin]: {
            backgroundColor: theme.palette.admin.main,
            color: theme.palette.getContrastText(theme.palette.admin.main),
        },
        [Role.Manager]: {
            backgroundColor: theme.palette.manager.main,
            color: theme.palette.getContrastText(theme.palette.manager.main),
        },
        [Role.QualityControl.replaceAll(" ", "")]: {
            backgroundColor: theme.palette.qualityControl.main,
            color: theme.palette.getContrastText(theme.palette.qualityControl.main),
        },
        [Role.Contractor]: {
            backgroundColor: theme.palette.contractor.main,
            color: theme.palette.getContrastText(theme.palette.contractor.main),
        },
    }
};

function UserChip({classes, className, onDelete, user}) {
    const {first_name, last_name, avatar, role} = user;
    return (
        <Chip
            className={clsx(
                classes[role.replaceAll(" ", "")],
                className
            )}
            avatar={<Avatar alt={`${first_name} ${last_name}`} src={avatar}/>}
            label={`${first_name} ${last_name}`}
            onDelete={onDelete && (() => onDelete(user))}
        />
    )
}

export default withStyles(styles, {name: 'UserChip'})(UserChip);