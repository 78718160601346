import Icon from "@material-ui/core/Icon";
import React from "react";
import getFontAwesomeIconFromMIME from "./fontAwesomeIcon";
import humanFileSize from "./humanFileSize";
import Resizer from 'react-image-file-resizer';

export default class UploadFile {
    constructor(file) {
        this.file = file;
        this.name = file.name;
        this.size = file.size;
        this.type = file.type;
        this.lastModifiedDate = file.lastModifiedDate;
        this.url = URL.createObjectURL(file);
    }

    resizeImage() {
        return new Promise(resolve => {
            if (this.type.indexOf("image/") !== 0 || this.type === "image/heif") {
                resolve();
                return
            }

            Resizer.imageFileResizer(
                this.file,
                1536,
                1536,
                'JPEG',
                70,
                0,
                resolve,
                'blob'
            );

        }).then(blob => {
            if (blob) {
                this.file = blob
                this.type = blob.type
                this.size = blob.size
                this.url = URL.createObjectURL(blob)
            }
            return this
        });
    }

    icon(classes) {
        // TODO: This doesn't work with .heic files even though the mimetype matches
        if (this.type.indexOf("image/") === 0 && this.type !== "image/heif") {
            return (
                <img src={this.url} alt={this.name} className={classes.image}/>
            )
        }

        if (this.type.indexOf("video/") === 0) {
            return (
                <video controls className={classes.video}>
                    <source src={this.url} type={this.type}/>
                </video>
            )
        }

        if (["text/plain", "application/pdf"].indexOf(this.type) > -1) {
            return (
                <object data={this.url} className={classes.object} type={this.type}>
                    {this.name}
                </object>
            )
        }

        return (
            <div className={classes.iconContainer}>
                <Icon className={`${classes.icon} fa ${getFontAwesomeIconFromMIME(this.type)}`}/>
            </div>
        )
    }

    toBase64() {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
            reader.onerror = reject;
            reader.readAsDataURL(this.file);
        });
    }

    get subtitle() {
        return `${humanFileSize(this.size, true)} - ${this.lastModifiedDate.toLocaleString()}`
    }

    get hasPreview() {
        // TODO: This doesn't work with .heic files even though the mimetype matches
        return (this.type.indexOf("image/") === 0 && this.type !== "image/heif") ||
            this.type.indexOf("video/") === 0 ||
            this.type === "text/plain" ||
            this.type === "application/pdf"

    }

}