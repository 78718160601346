import {Link, Typography} from "@material-ui/core";
import React, {useMemo} from "react";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {baseAPIUrl} from "../Config";
import Paper from "@material-ui/core/Paper";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {useTable} from "react-table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import MaUTable from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useGetReport} from "../api/work";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import {Link as RouterLink} from "react-router-dom";
import WorkMenu from "../components/WorkMenu";
import {useFlexLayout} from "react-table/src/plugin-hooks/useFlexLayout";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
    reportsContainer: {
        paddingTop: 16,
    },
    tableGridContainer: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRoot: {
        position: 'relative',
    },
    tableContainer: {
        height: 'calc(100vh - 48px - 56px)',
    },
    tableHead: {
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 2
    },
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
    loadingIndicator: {
        position: "absolute",
        top: 0
    },
    startDatePicker: {
        display: "block",
    },
    endDatePicker: {
        display: "block",
        marginTop: 16,
    },
}));

// Create a default prop getter
const defaultPropGetter = () => ({});

function Cell(column) {
    return props => {
        const {value = ''} = props;
        // console.log(props);
        switch (column) {
            case "ID":
                return <Link component={RouterLink} to={`/work/${value}`}>
                    <b>{value}</b>
                </Link>
            case "QC Attachments":
                return <>
                    {value !== "" && value.split("\n").map((link, i) => {
                        return <Link href={link} style={{display:"block"}}>Attachment {i+1}</Link>
                    })}
                </>
            case "Chargeback":
                return <Link href={`${baseAPIUrl}/chargeback/${value}`} style={{display:"block"}}>Chargeback</Link>
            case "Closeout errors":
                return <span style={{whiteSpace:"pre"}}>{value}</span>
        }

        // Workaround for the fact that some of these may look like timestamps and moment parsing gets confused
        if (/^\d+$/.test(value)) {
            return value
        }

        const t = moment(value, moment.ISO_8601, true)
        if (t.isValid()) {
            return t.format('lll')
        }
        return value
    }
}

function Table({url, getRowProps = defaultPropGetter}) {
    const classes = useStyles({});
    const [{data: rawData, loading, error}, setReq] = useGetReport();

    React.useEffect(() => setReq({url}), [url])

    const {columns, data} = React.useMemo(() => {
        const {columns = [], rows = []} = rawData || {};
        return {
            columns: columns.map(col => ({
                Header: col,
                accessor: col,
                Cell: Cell(col),
            })),
            data: rows,
        }
    }, [rawData])

    const hooks = hooks => {
        hooks.visibleColumns.push(columns => [{
            id: 'menu',
            width: 90,
            Header: () => null,
            Cell: ({row: {values: {ID: id}}}) => {
                return <WorkMenu id={id} onWorkChanged={() => setReq({url})}/>
            },
        }, ...columns])
    }

    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            // Filter: DefaultColumnFilter,
            width: 200,
        }),
        []
    );

    const instance = useTable({columns, data, defaultColumn}, hooks, useFlexLayout);
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = instance;

    return (
        <div className={classes.tableRoot}>
            {loading && (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={64} className={classes.loadingIndicator}/>
                </div>
            )}
            <TableContainer component={Paper} elevation={4} className={classes.tableContainer}>
                <MaUTable stickyHeader {...getTableProps()}>
                    <TableHead className={classes.tableHead}>
                        {headerGroups.map(headerGroup =>
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column =>
                                    <TableCell
                                        component="th"
                                        // Return an array of prop objects and react-table will merge them appropriately
                                        {...column.getHeaderProps([
                                            {
                                                className: column.className,
                                                style: column.style,
                                            },
                                            // getColumnProps(column),
                                            // getHeaderProps(column),
                                        ])}
                                    >
                                        <b>{column.render('Header')}</b>
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableHead>
                    {/*<TableBody className={classes.tableBody} {...getTableBodyProps()}>*/}
                    <TableBody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps(getRowProps(row))}>
                                    {row.cells.map(cell =>
                                        <TableCell
                                            {...cell.getCellProps([
                                                {
                                                    className: cell.column.className,
                                                    style: (cell.column.getCellStyle && cell.column.getCellStyle(cell)) || cell.column.style,
                                                },
                                                // getColumnProps(cell.column),
                                                // getCellProps(cell),
                                            ])}
                                        >
                                            {cell.render('Cell')}
                                        </TableCell>
                                    )}
                                </TableRow>
                            )
                        })}
                        {!!error && <TableRow>
                            <TableCell colSpan="100000">
                                <Typography color="error">Error loading report: {error}</Typography>
                            </TableCell>
                        </TableRow>}
                        {rows.length === 0 && !error && <TableRow>
                            <TableCell colSpan="100000">
                                <Typography>No results found for the specified date range</Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </MaUTable>
            </TableContainer>
        </div>
    )
}

const reports = [
    {
        id: "open",
        name: "Open Work",
        description: "Work that is open (DATE RANGE NOT USED)"
    },
    {
        id: "routing_request",
        name: "Routing Request",
        description: "Work assigned to technicians (DATE RANGE NOT USED)",
    },
    {
        id: "completions",
        name: "Completions",
        description: "Work completed within the date range",
    },
    {
        id: "recreated",
        name: "Recreated",
        description: "Work sent to us after we closed it (false closeout) DATE RANGE NOT USED"
    },
    {
        id: "cancellations",
        name: "Cancellations",
        description: "Work cancelled within the date range",
    },
    {
        id: "skipped",
        name: "Skipped",
        description: "Work skipped within the date range",
    },
    {
        id: "trip_charges",
        name: "Trip Charges",
        description: "Work completed with only a trip charge",
    },
    {
        id: "on_hold",
        name: "On Hold",
        description: "Work on hold within the date range",
    },
    {
        id: "urgent",
        name: "Urgent (Z5)",
        description: "Open urgent work needing assigning (DATE RANGE NOT USED)",
    },
    {
        id: "billing",
        name: "Billing",
        description: "Completed work for billing purposes"
    },
    {
        id: "quality_control",
        name: "Quality Control",
        description: "Work that has been QC checked"
    },
    {
        id: "recreated_completions",
        name: "Recreated Completions",
        description: "Work that is open and needs to be closed out"
    },
    {
        id: "recreated_cancellations",
        name: "Recreated Cancellations",
        description: "Work that is open and needs to be canceled"
    },
    {
        id: "permits",
        name: "Permits",
        description: "Permits paid and current permit state (uses work created date for date range)"
    },
    {
        id: "duplicates",
        name: "Duplicates",
        description: "Duplicate jobs (within 90 days of each other) by subaccount number"
    },
    {
        id: "investigations",
        name: "Investigations",
        description: "Work investigated by techs because of duplicates"
    },
    {
        id: "automation_tasks",
        name: "Automation tasks",
        description: "Results of automation tasks (closeouts, emails, etc)"
    },
    {
        id: "missing_closeout",
        name: "Missing Closeout",
        description: "Work that is missing a successful closeout"
    }
]

export default function Reports({history, location: {search}}) {
    const p = new URLSearchParams(search)

    const classes = useStyles();
    const [params, setParams] = React.useState({
        selected: p.get("id") || reports[0].id,
        company: p.get("company") || "TW",
        start: p.get("start") ? moment.unix(parseInt(p.get("start"))) : moment().startOf('day'),
        end: p.get("end") ? moment.unix(parseInt(p.get("end"))) : moment().endOf('day'),
    })

    function onChange(changed) {
        const newParams = {...params, ...changed}
        setParams(newParams)

        const newSearch = new URLSearchParams();
        newSearch.set("id", newParams.selected);
        newSearch.set("start", String(newParams.start.unix()));
        newSearch.set("end", String(newParams.end.unix()));
        newSearch.set("company", newParams.company)
        history.push({
            ...history.location,
            search: newSearch.toString(),
        });
    }

    function reportUrl(id) {
        return `${baseAPIUrl}/reports/${params.company}/${id}/${params.start.unix()}/${params.end.unix()}`;
    }

    return (
        <Container className={classes.reportsContainer} maxWidth={false}>
            <Typography variant="h5" gutterBottom>Reports</Typography>
            <Grid container spacing={2}>
                <Grid item lg={2}>
                    <Paper elevation={4}>
                        <Box p={2}>
                            <Box p={1}>
                                <DatePicker
                                    showTodayButton
                                    label="Start date"
                                    format="ll"
                                    value={params.start}
                                    onChange={d => onChange({start: d.startOf('day')})}
                                    className={classes.startDatePicker}
                                />
                                <DatePicker
                                    showTodayButton
                                    label="End date (inclusive)"
                                    format="ll"
                                    value={params.end}
                                    onChange={d => onChange({end: d.endOf('day')})}
                                    className={classes.endDatePicker}
                                />
                                <FormControl style={{marginTop: 16}}>
                                    <FormLabel>Company</FormLabel>
                                    <RadioGroup
                                        row
                                        value={params.company}
                                        onChange={event => onChange({company: event.target.value})}
                                    >
                                        <FormControlLabel value="TW" control={<Radio color="primary"/>} label="TW"/>
                                        <FormControlLabel value="WOW" control={<Radio color="primary"/>} label="WOW"/>
                                        <FormControlLabel value="ATT" control={<Radio color="primary"/>} label="ATT"/>
                                        <FormControlLabel value="CLW" control={<Radio color="primary"/>} label="CLW"/>
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <List>
                                {reports.map(({id, name, description}) =>
                                    <ListItem
                                        button
                                        key={id}
                                        selected={params.selected === id}
                                        onClick={() => onChange({selected: id})}
                                    >
                                        <ListItemText primary={name} secondary={description}/>
                                        <ListItemSecondaryAction>
                                            <IconButton component="a" href={reportUrl(id)} target="_blank" edge="end">
                                                <GetAppIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item lg={10} className={classes.tableGridContainer}>
                    <Table
                        url={reportUrl(params.selected)}
                        getRowProps={getRowProps}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

function getRowProps(row) {
    return {
        style: {
            background: row.index % 2 === 0 ? 'rgba(0,0,0,.075)' : 'white',
        },
    }
}