import {useNewtinGeocodeWork, useSetAddress} from "../../api/work";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import ErrorSnackbar from "../ErrorSnackbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AddressComponents from "./AddressComponents";
import OUPSSuggestions from "./OUPSSuggestions";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

export default function OUPSLookupDialog({work, onClose, allowOUPSSubmit}) {
    const classes = useStyles({})
    const [{data: {suggestions, address}, loading: geocodeLoading, error: geocodeError}, setQuery] = useNewtinGeocodeWork();
    const [{data: updatedWork, loading: addressLoading, error: addressError}, setAddress] = useSetAddress();
    const [selected, setSelected] = React.useState();
    const [submitOUPS, setSubmitOUPS] = React.useState(false);
    const [components, setComponents] = React.useState(work.address || {number: '', street: '', city: '', lot: ''});

    const [loading, setLoading] = React.useState(geocodeLoading || addressLoading);
    const [error, setError] = React.useState(geocodeError || addressError);

    React.useEffect(() => {
        setLoading(geocodeLoading || addressLoading);
        setError(geocodeError || addressError);
    }, [geocodeLoading, geocodeError, addressLoading, addressError, setLoading, setError])

    React.useEffect(() => {
        work.address && setQuery({work_id: work.id, company: work.company, ...work.address})
    }, [work, setQuery, setComponents, setSelected])

    React.useEffect(() => {
        updatedWork && onClose(updatedWork, submitOUPS)
    }, [updatedWork, onClose])

    function submit(doOUPS) {
        setSubmitOUPS(doOUPS);
        const {number, lot} = components;
        const {
            county: {county, place},
            street: {place: city, shape},
            suggestion: {name: street},
            center: point,
            center: {lat, lng},
        } = selected;
        // TODO: result.county.place sometimes has the city and township separated by an arrow.
        // TODO: See: "LEWIS CENTER -> ORANGE TWP"
        // TODO: In this instance, result.street.place is "ORANGE TWP" and "LEWIS CENTER" is likely the correct city
        const lotStr = lot ? ` ${lot}`: ""
        const address = {
            number,
            street,
            city,
            lot,
            county,
            state: "Ohio",
            lat,
            lng,
            point,
            township: place,
            formatted: `${number} ${street}${lotStr} ${city}, Ohio`,
            street_shape: shape,
        }

        setAddress({id: work.id, address})
    }

    React.useEffect(() => {
        suggestions?.length > 0 && setSelected(suggestions[0])
    }, [suggestions, setSelected])

    return (
        <Dialog open={true} onClose={() => onClose()} fullWidth maxWidth={selected ? "lg" : "md"}>
            {error && <ErrorSnackbar message={error}/>}
            <DialogTitle>OUPS Address Lookup</DialogTitle>
            <DialogContent>
                <div style={{position: 'relative'/*, minHeight: 80*/}}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>
                    )}
                    <Typography gutterBottom variant="h6">
                        {work?.raw_address}
                    </Typography>
                    <DialogContentText>
                        Enter the street number, street, and city/township of the work order.
                        Click Lookup to query OUPS for address suggestions.
                        Then select the most appropriate suggestion for the address and click Save.
                    </DialogContentText>
                    <AddressComponents
                        components={components}
                        setComponents={setComponents}
                        lookup={() => {
                            setSelected(undefined)
                            setQuery({work_id: work.id, company: work.company, ...components})
                        }}
                    />
                    {suggestions && !loading && <>
                        <Divider style={{marginBottom: 16, marginTop: 16}}/>
                        <OUPSSuggestions address={address} suggestions={suggestions} selected={selected} setSelected={setSelected}/>
                    </>}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Dismiss</Button>
                <Button
                    disabled={!selected}
                    color="primary"
                    onClick={() => submit(false)}
                >
                    Save Address
                </Button>
                {allowOUPSSubmit && <Button
                    disabled={!selected}
                    color="primary"
                    onClick={() => submit(true)}
                >
                    Save Address and OUPS
                </Button>}
            </DialogActions>
        </Dialog>
    )
}