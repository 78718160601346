import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from '@material-ui/icons/Add';
import BillingItem from "./BillingItem";

export const types = {
    tripCharge: "Trip Charge",
    sidewalkBore: "Sidewalk Bore",
    drivewayBore: "Driveway Bore",
    buryWire: "Bury Wire",
    cableRG6: "RG6 Cable",
    cableRG6RP: "RG6 RP Cable",
    cableRG11: "RG11 Cable",
    cableRG11RP: "RG11 RP Cable",
    cableFlex500: "Flex 500 Cable",
    cableFiber: "Fiber-optic Cable",
    pickupCable: "Cable Pickup",
    groundBlock: "Ground Block",
}

// TODO: Might be better if we split this up by company instead of the maps for requiresAny and requiresAll
const availableBilling = [
    {
        id: 1,
        name: types.buryWire,
        description: "Total footage of wire buried",
        unit: "ft",
        requiresAny: {
            "WOW": [types.cableRG6, types.cableRG11],
            "CLW": [types.cableRG6, types.cableRG11],
            "TW": [types.cableRG6, types.cableRG11, types.cableFlex500, types.cableFiber],
        },
    },
    {
        id: 2,
        name: types.drivewayBore,
        description: "Total footage of wire bored under a driveway",
        unit: "ft",
        requiresAny: {
            "WOW": [types.cableRG6, types.cableRG11],
            "CLW": [types.cableRG6, types.cableRG11],
            "TW": [types.cableRG6, types.cableRG11, types.cableFlex500, types.cableFiber],
        },
        requiresAll: {
            "WOW": [types.buryWire],
            "CLW": [types.buryWire],
            "TW": [types.buryWire]
        },
    },
    {
        id: 3,
        name: types.sidewalkBore,
        description: "Total footage of wire bored under a sidewalk",
        unit: "ft",
        requiresAny: {
            "WOW": [types.cableRG6, types.cableRG11],
            "CLW": [types.cableRG6, types.cableRG11],
            "TW": [types.cableRG6, types.cableRG11, types.cableFlex500, types.cableFiber],
        },
        requiresAll: {
            "WOW": [types.buryWire],
            "CLW": [types.buryWire],
            "TW": [types.buryWire]
        },
    },
    {
        id: 4,
        name: types.tripCharge,
        description: "A trip to a job site when work can't be completed",
        incompatibleWith: "*",
    },
    {
        id: 5,
        name: types.cableRG6,
        description: "Select only one cable type",
        company: "TW",
        requiresAll: {
            "TW": [types.buryWire]
        },
        incompatibleWith: [types.cableRG11, types.cableFlex500, types.cableFiber],
    },
    {
        id: 5,
        name: types.cableRG6,
        description: "Number of RG6 drops",
        unit: "drops",
        company: "WOW",
        requiresAll: {
            "WOW": [types.buryWire],
        },
        incompatibleWith: [types.cableRG11, types.cableRG11RP],
        maxQuantity: 2,
    },
    {
        id: 5,
        name: types.cableRG6,
        description: "Number of RG6 drops",
        unit: "drops",
        company: "CLW",
        requiresAll: {
            "CLW": [types.buryWire],
        },
        incompatibleWith: [types.cableRG11, types.cableRG11RP],
        maxQuantity: 2,
    },
    {
        id: 6,
        name: types.cableRG11,
        description: "Select only one cable type",
        company: "TW",
        requiresAll: {
            "TW": [types.buryWire]
        },
        incompatibleWith: [types.cableRG6, types.cableFlex500, types.cableFiber],
    },
    {
        id: 6,
        name: types.cableRG11,
        description: "Number of RG11 drops",
        unit: "drops",
        company: "WOW",
        requiresAll: {
            "WOW": [types.buryWire],
        },
        incompatibleWith: [types.cableRG6, types.cableRG6RP],
        maxQuantity: 2,
    },
    {
        id: 6,
        name: types.cableRG11,
        description: "Number of RG11 drops",
        unit: "drops",
        company: "CLW",
        requiresAll: {
            "CLW": [types.buryWire],
        },
        incompatibleWith: [types.cableRG6, types.cableRG6RP],
        maxQuantity: 2,
    },
    {
        id: 7,
        name: types.cableFlex500,
        description: "Select only one cable type",
        company: "TW",
        requiresAll: {
            "TW": [types.buryWire]
        },
        incompatibleWith: [types.cableRG6, types.cableRG11, types.cableFiber],
    },
    {
        id: 8,
        name: types.cableRG6RP,
        description: "Number of RG6 replacement drops",
        unit: "drops",
        company: "WOW",
        requiresAll: {
            "WOW": [types.cableRG6, types.buryWire],
        },
        incompatibleWith: [types.cableRG11, types.cableRG11RP],
        maxQuantity: 2,
        validate: (rg6rp, toBill) => {
            const rg6 = toBill.find(({name}) => name === types.cableRG6)
            if (rg6 && rg6rp.quantity > rg6.quantity) {
                return `Cannot have more drops than ${rg6.name}`
            }
        },
    },
    {
        id: 8,
        name: types.cableRG6RP,
        description: "Number of RG6 replacement drops",
        unit: "drops",
        company: "CLW",
        requiresAll: {
            "CLW": [types.cableRG6, types.buryWire],
        },
        incompatibleWith: [types.cableRG11, types.cableRG11RP],
        maxQuantity: 2,
        validate: (rg6rp, toBill) => {
            const rg6 = toBill.find(({name}) => name === types.cableRG6)
            if (rg6 && rg6rp.quantity > rg6.quantity) {
                return `Cannot have more drops than ${rg6.name}`
            }
        },
    },
    {
        id: 9,
        name: types.cableRG11RP,
        description: "Number of RG11 replacement drops",
        unit: "drops",
        company: "WOW",
        requiresAll: {
            "WOW": [types.cableRG11, types.buryWire],
        },
        incompatibleWith: [types.cableRG6, types.cableRG6RP],
        maxQuantity: 2,
        validate: (rg11rp, toBill) => {
            const rg11 = toBill.find(({name}) => name === types.cableRG11)
            if (rg11 && rg11rp.quantity > rg11.quantity) {
                return `Cannot have more drops than ${rg11.name}`
            }
        },
    },
    {
        id: 9,
        name: types.cableRG11RP,
        description: "Number of RG11 replacement drops",
        unit: "drops",
        company: "CLW",
        requiresAll: {
            "CLW": [types.cableRG11, types.buryWire],
        },
        incompatibleWith: [types.cableRG6, types.cableRG6RP],
        maxQuantity: 2,
        validate: (rg11rp, toBill) => {
            const rg11 = toBill.find(({name}) => name === types.cableRG11)
            if (rg11 && rg11rp.quantity > rg11.quantity) {
                return `Cannot have more drops than ${rg11.name}`
            }
        },
    },
    // {
    //     id: 10,
    //     name: types.pickupCable,
    //     description: "Picked up a disconnected cable",
    //     company: "WOW",
    //     incompatibleWith: "*",
    // },
    {
        id: 11,
        name: types.groundBlock,
        description: "Set a ground block",
        company: "WOW",
        requiresAny: {
            "WOW": [types.cableRG6, types.cableRG11],
        },
        requiresAll: {
            "WOW": [types.buryWire],
        },
    },
    {
        id: 11,
        name: types.groundBlock,
        description: "Set a ground block",
        company: "CLW",
        requiresAny: {
            "CLW": [types.cableRG6, types.cableRG11],
        },
        requiresAll: {
            "CLW": [types.buryWire],
        },
    },
    {
        id: 12,
        name: types.cableFiber,
        description: "Select only one cable type",
        company: "TW",
        requiresAll: {
            "TW": [types.buryWire]
        },
        incompatibleWith: [types.cableRG6, types.cableRG11, types.cableFlex500],
    },
]

const useStyles = makeStyles(theme => ({
    nested: {
        // paddingLeft: theme.spacing(4),
        wordWrap: 'break-word',
    },
}))

// TODO: Fetch the billable items from the database
export default function CompleteBilling({toBill, setToBill, company}) {
    const classes = useStyles({});
    const [menuAnchor, setMenuAnchor] = React.useState();

    function billingItemAdded(item) {
        const newItem = {...item, quantity: item.unit ? 0 : 1};
        setToBill(toBill => [...toBill, newItem])
        setMenuAnchor(undefined);
    }

    function onDelete(idx) {
        setToBill(toBill => toBill.filter((_, i) => i !== idx))
    }

    function setItem(item, idx) {
        const newToBill = toBill.slice();
        newToBill[idx] = item;
        setToBill(newToBill);
    }

    const canBill = availableBilling.filter(b => {
        // Filter out items which have already been added
        if (toBill.find(a => b.id === a.id)) {
            return false
        }

        // Filter out items which have a company and don't belong to this company
        if (b.company && b.company !== company) {
            return false
        }

        return true
    })

    return (
        <>
            <ListItem button onClick={ev => setMenuAnchor(ev.currentTarget)}>
                <ListItemText primary="Billable work"/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={ev => setMenuAnchor(ev.currentTarget)}>
                        <AddIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            {canBill.length > 0 && <Menu
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor(undefined)}
            >
                {canBill.map((item, i) =>
                    <MenuItem key={i} onClick={() => billingItemAdded(item)}>
                        <ListItemText
                            primary={item.name}
                            secondary={item.description}
                        />
                    </MenuItem>
                )}
            </Menu>
            }
            {toBill.map((item, idx) =>
                <BillingItem
                    key={idx}
                    item={item}
                    onDelete={() => onDelete(idx)}
                    setItem={item => setItem(item, idx)}
                />
            )}
        </>
    )
}