import {Dialog, DialogContent, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import {useRoutingSettings, useUpdateRoutingSetting} from "../api/routingSettings";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import RoutingSettingsConfig from "../components/RoutingSettingsConfig";
import Switch from "@material-ui/core/Switch";

export default function RoutingSettings() {

    const {settings, loading, error} = useRoutingSettings()
    const [open, setOpen] = useState(false)

    const [{updated, loading: updateLoading, error: updateError}, submitFunc] = useUpdateRoutingSetting();
    const [setting, setSetting] = useState({})

    const [listSettings, setListSettings] = useState(settings)

    useEffect(() => {
        setListSettings(settings)
    }, [settings, setListSettings]);

    useEffect(() => {
        if (!updated) return

        setListSettings(settings => {
            const newList = settings.slice();
            const idx = newList.findIndex(({id}) => id === updated.id)
            newList[idx] = updated;
            return newList
        })

    }, [updated, setListSettings])

    function onDialogClose(newSetting) {
        setOpen(false)

        if (!newSetting) {
            setSetting({})
        } else {
            submitFunc(newSetting)
        }
    }

    function editSetting(setting) {
        setSetting(setting)
        setOpen(true)
    }

    if (loading) {
        return <h2>Loading...</h2>
    }

    if (error) {
        const {response: {data: {message} = {}} = {}} = error;
        return <h1>An error has occurred: {message || String(error)}</h1>
    }

    return (
        <Container maxWidth="sm" style={{paddingTop: 16}}>
            {open && <RoutingSettingsDialog
                onClose={onDialogClose}
                open={open}
                setting={setting}
                setSetting={setSetting}
            />}
            <Paper elevation={4}>
                <List subheader={<ListSubheader>Automatic Routing Settings - click to modify</ListSubheader>}>
                    {listSettings.map((setting, i) => (
                        <RoutingSettingListItem
                            key={i}
                            divider={i < listSettings.length - 1}
                            button
                            onClick={() => editSetting(setting)}
                            setting={setting}
                        />
                    ))}
                </List>
            </Paper>
        </Container>
    )
}

function RoutingSettingListItem({setting, ...other}) {
    const {company, enabled, maxTravelDistance, solveTime, urgentMultiplier, costCoefficient, requireGoodOups} = setting

    return (
        <ListItem{...other}>
            <ListItemText
                primary={company}
                secondary={
                    <>
                        {enabled ? "Enabled" : "Disabled"}<br/><br/>
                        {maxTravelDistance} miles max travel distance<br/>
                        {urgentMultiplier}x urgent multiplier<br/>
                        {costCoefficient} cost coefficient<br/>
                        {solveTime} sec solve time<br/>
                        {requireGoodOups && <>Requires good OUPS<br/></>}
                    </>
                }
            />
        </ListItem>
    )
}

function RoutingSettingsDialog({open, onClose, setting, setSetting}) {
    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>{setting.company} Automatic Routing</DialogTitle>
            <DialogContent>
                <Typography gutterBottom>Automatic routing enabled</Typography>
                <Switch
                    checked={setting.enabled}
                    onChange={() => setSetting({...setting, enabled: !setting.enabled})}
                    color="primary"
                />
                <Typography variant="caption" color="textSecondary">
                    Whether or not the automatic routing will run nightly for this company.
                </Typography>
                <RoutingSettingsConfig settings={setting} setSettings={setSetting}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Dismiss</Button>
                <Button onClick={() => onClose(setting)} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}
