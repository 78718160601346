import React, {useEffect} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useCommentWork} from "../api/work";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackbar from "../components/ErrorSnackbar";
import AddAttachments from "./AddAttachments";
import Comment from "./Comment";

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.6)',
    },
}));

export default function Comments({work: {id: workId, ticket_comments}, onComment}) {
    const classes = useStyles();

    const [userComment, setUserComment] = React.useState('');
    const [userAttachments, setUserAttachments] = React.useState([]);
    const [openComments, setOpenComments] = React.useState([]);

    const [{work: newWork, loading, error}, apiSubmitComment] = useCommentWork();

    useEffect(() => {
        if (newWork) {
            onComment(newWork)
            resetInput();
        }
    }, [newWork, setUserComment, setUserAttachments]);

    const resetInput = event => {
        setUserComment('');
        setUserAttachments([]);
    };

    const submitComment = async event => {
        const newComment = {
            workId,
            message: userComment,
            attachments: await Promise.all(userAttachments.map(async a => ({
                filename: a.name,
                // caption: "",
                data: await a.toBase64(),
            }))),
        };

        apiSubmitComment(newComment);
    };

    const toggleExpandComment = id => {
        const idx = openComments.indexOf(id);
        let newOpenComments = openComments.slice();
        if (idx > -1) {
            newOpenComments.splice(idx, 1);
        } else {
            newOpenComments.push(id);
        }
        setOpenComments(newOpenComments);
    };

    return (
        <>
            {error && <ErrorSnackbar message={error}/>}
            <List disablePadding>
                <div style={{position: 'relative'}}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={64}/>
                        </div>
                    )}
                    <ListItem disableGutters key="add_comment">
                        <TextField
                            variant="outlined"
                            multiline
                            fullWidth
                            margin="dense"
                            value={userComment}
                            onChange={ev => setUserComment(ev.target.value)}
                            label="Leave a comment"
                        />
                    </ListItem>
                    {userComment && <>
                        <AddAttachments
                            id="contained-button-file"
                            accept="image/*;capture=camera"
                            label="Attach files to your comment"
                            attached={userAttachments}
                            setAttached={setUserAttachments}
                        />
                        <ListItem disableGutters>
                            <div style={{flexGrow: 1}}/>
                            <Button onClick={resetInput}>Discard</Button>
                            <Button onClick={submitComment} color="primary" disabled={!userComment}>Submit</Button>
                        </ListItem>
                    </>}
                </div>
                {ticket_comments.map((comment, i) =>
                    <Comment
                        divider={i > 0}
                        disableGutters={true}
                        key={comment.id}
                        comment={comment}
                        isExpanded={openComments.indexOf(comment.id) > -1}
                        onToggleExpand={() => toggleExpandComment(comment.id)}
                    />
                )}
            </List>
        </>
    );
}